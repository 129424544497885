<!--
 * @Author: ygq
-->
<!-- 账号交易 -->
<template>
  <div id="app">
    <div class="masker" v-if="this.shopnum===1">
            <div class="maskpay">
                <img src="../../assets/chacha.png" class="chacha" @click="shop(0)" alt="">
                <span class="alreadysucc">您的订单已提交成功，请尽快完成支付</span>
                <span class="masktime">{{payTimeDuration}}分钟内未完成付款，订单自动取消</span>
                <img v-if="QrCode" :src="QrCode" alt="">
                <span class="pleace">请使用支付宝扫码支付</span>
                <span class="maskprice">￥{{orderPrice}}</span>
                <div class="orderNumber" v-for="item in ordernumber" :key="item.type">
                    <span class="ordernu">{{item.title}}</span>
                    <span class="ordernumbers">{{item.num}}</span>
                </div>
            </div>
        </div>

    <div class="yanzhengma" v-if="yanzm === 1">
      <img :src="tup" alt="" class="tupianee">
      <input type="text" v-model="yyy" name="" id="" placeholder="请输入验证码">
      <button @click="yanz">确定</button>
    </div>
    <div class="yanzhengma" v-if="yanzm === 2">
      <img :src="tup" alt="" class="tupianee">
      <input type="text" v-model="yyy" name="" id="" placeholder="请输入验证码">
      <button @click="yanz2">确定</button>
    </div>
    <index-heat></index-heat>
    <div class="h_mid">

      <div class="webindex" @click="index()">
        <span>网站首页</span>
      </div>
      <div class="navlist">
        <li @click="all()">全部游戏</li>
        <li @click="goBidding()">竞价中心</li>
        <li @click="account()">我要卖号</li>
        <li @click="protect()">担保中介</li>
        <li @click="TradingInformation()">交易信息</li>
        <li @click="TransactionSecurity()">交易保障</li>
        <li @click="agree()">关于我们</li>
      </div>
    </div>
    <div class="maxbox">
      <div class="fill herotopfill"></div>
      <div class="heroAlliance">
        <div class="heroalliance">
          <navi-gation class="heronav"></navi-gation>
          <div class="heroimg">
            <img :src="iconPath" alt="" />
          </div>
          <div class="herolis">
            <span>{{ gameName }}</span>
            <button @click="newChoose">重新选择</button>
          </div>
        </div>
        <div class="heroalliancesa">
          <p>账号鉴定方式 <span>鉴定须知</span></p>
          <div class="double">
            <span class="firstnumprice">*</span>
            <span>售卖方式：</span>
            <button @click="orderTypeFun(1)" :class="{ bkbt: productOrderType === 1 }">
              免费鉴定
            </button>
            <button @click="orderTypeFun(2)" :class="{ bkbt: productOrderType === 2 }">
              专业鉴定
            </button>
          </div>
          <!-- aaaaaaaaaaaa -->
          <div v-if="isSync === 0">
            <div class="numprice">
              <span class="firstnumprice">*</span>
              <span>商品名称:</span>
              <el-input v-model.trim="title" placeholder="请输入内容"></el-input>
            </div>
            <div class="numprice">
              <span class="firstnumprice">*</span>
              <span>商品描述:</span>
              <el-input type="textarea" placeholder="请输入内容" v-model="description" maxlength="200" show-word-limit>
              </el-input>
            </div>
            <!-- 商品主图 -->
            <div class="numprice mainphoto" style="height: auto">
              <span class="firstnumprice">*</span>
              <span>商品主图:</span>
              <el-upload accept="image/*" :before-upload="beforeuploadfun"
                :action="serverConfig.baseURL + '/api/localStorage/pictures'" list-type="picture-card"
                :on-preview="handlePictureCardPreview" :on-success="handlerSuscess" :headers="headers" :limit="onelimit"
                :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
            <!-- 详情图集 -->
            <div class="numprice">
              <span class="firstnumprice">*</span>
              <span>详情图集:</span>
              <el-upload accept="image/*" :before-upload="beforeuploadfun"
                :action="serverConfig.baseURL + '/api/localStorage/pictures'" list-type="picture-card"
                :on-preview="handlePictureCardPreview" :headers="headers" :on-success="handlerSuscesstwo"
                :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
            <!-- 号内截图 -->
            <div class="numprice">
              <span class="firstnumprice">*</span>
              <span>号内截图:</span>
              <el-upload accept="image/*" :before-upload="beforeuploadfun"
                :action="serverConfig.baseURL + '/api/localStorage/pictures'" list-type="picture-card"
                :on-preview="handlePictureCardPreview" :headers="headers" :on-success="handlerSuscessthree"
                :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
        </div>
        <div class="heroalliancesa" v-for="(item, index) in productOrderType==2?gamePropertyList:gamePropertyList.slice(1,2)" :key="index">
          <div>
            <!-- 控件类型。0：类目，1：文本输入，2：单选，3：多选 -->
            <p>
              {{ item.propertyName
              }}<span>仅用于客服登录账号审核使用，不对外公开</span>
            </p>
            <!-- 一级数据 -->
            <div class="numprice" v-for="(item1, index) in item.children" :key="index">
              <span v-if="item1.isRequired" class="firstnumprice">*</span>
              <span>{{ item1.label }}</span>
              <span class="elinput">
                <el-input v-if="item1.controlType == 1" placeholder="" v-model="item1.childrenValue"></el-input>
                <!-- :multiple="item.isMultiChoose == 1 ? true : false" -->
                <el-select v-if="item1.controlType == 5" v-model="item1.childrenValue"
                  :multiple="item1.isMultiChoose == 1 ? true : false" placeholder="请选择">
                  <el-option v-for="(item2,index2) in item1.children" :key="index2" :label="item2.label" :value="item2.id">
                  </el-option>
                </el-select>
                <el-cascader v-model="item1.childrenValue"
                  :props="{ multiple: item1.isMultiChoose == 1 ? true : false }" placeholder="请选择"
                  v-if="item1.controlType == 6" :options="item1.children" clearable></el-cascader>
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="heroalliancesa hei">
          <p>
            卖家联系信息
            <span>用于账号出售后客服联系使用，平台承诺严格保密不对外展示</span>
          </p>
          <div class="numprice heroqq">
            <span class="firstnumprice">*</span>
            <span>QQ：</span>
            <el-input v-model="qq" placeholder="请输入内容"></el-input>
          </div>
          <div class="numprice herowechat">
            <span class="firstnumprice">*</span>
            <span>微信：</span>
            <el-input v-model="wechat" placeholder="请输入内容"></el-input>
          </div>
          <div class="numprice">
            <span class="firstnumprice">*</span>
            <span>联系电话：</span>
            <el-input v-model="telephone" placeholder="请输入内容"></el-input>
          </div>          
        </div> -->
        <button class="nextfoot" @click="addCommodity">
          提交
        </button>
      </div>

      <div class="fill lastlast"></div>
    </div>
    <index-tail class="indextaillll"></index-tail>
    <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
  </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue';
import IndexTail from '../IndexTail.vue';
import NaviGation from '../NaviGation.vue';
import { areaService, gameProperty, code, codeCheck,friend,configListService,configDetailService } from '@/request/api';
import serverConfig from '../../request/config.js';
import { orderDetail } from '@/request/api'
import {customerServiceQrCode,configByKeyService} from '@/request/api'

export default {
  name: 'GameList',
  components: {
    IndexHeat,
    IndexTail,
    NaviGation,
  },
  methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
    xiugaixiu(){
      this.qinqinhao=2
      this.modifyFriend = 1;
    },
    async configList(){      
      await configListService().then((res) =>{
        if(res){
          for(let info of res){
            if(info.configKey=='SELL_RATES'){
              this.sellRates = info.configValue;
            }
            else if(info.configKey=='BUY_RATES'){
              this.buyRates = info.configValue;
            }
          }
        }
      })
    },
    async friendfun(){
      await friend().then((res) =>{
        console.log(res)
        // this.qinyouyou1 =res.alipayImg1
        // this.qinyouyou2 = res.alipayImg2
        // this.qinhaohao1= res.friendPhone1
        // this.qinhaohao2 = res.friendPhone2

        // this.qyh1= res.alipayImg1
        // this.qyh2 = res.alipayImg2

        // friends: [{
        //   // alipayImgId: '',//	支付宝截图id
        //   friendName: this.friendName1,//	亲友名称
        //   friendPhone: Number(this.qyh1),//亲友手机号
        //   relationship: this.relationValue1,//与亲友关系
        //   smsCode: this.yzm1,//手机验证码
        // },{
        //   // alipayImgId: this.mainImgIdss,//	支付宝截图id
        //   friendName: this.friendName2,//	亲友名称
        //   friendPhone: Number(this.qyh2),//亲友手机号
        //   relationship: this.relationValue2,//与亲友关系
        //   smsCode: this.yzm2,//手机验证码
        // }],
        // orderImgIds: this.mainImgIdss,//网购订单截图
        if(res){
          this.orderImgsHuiXian = []
          this.mainImgIdss = []
          this.friendName1 = res.friends[0].friendName
          this.friendName2 = res.friends[1].friendName
          this.qyh1 = res.friends[0].friendPhone
          this.qyh2 = res.friends[1].friendPhone
          this.relationValue1 = res.friends[0].relationship
          this.relationValue2 = res.friends[1].relationship

          for(let i = 0 ; i < res.orderImgs.length ; i++){
            this.orderImgsHuiXian.push(res.orderImgs[i].imgPath)
            this.mainImgIdss.push(res.orderImgs[i].imgId)
            this.fileList.push({name: res.orderImgs[i].imgId, url: res.orderImgs[i].imgPath})
          }
        }
        
        if(res.friends.length != 0){
          this.qinqinhao = 1
        }else{
          this.qinqinhao = 2
        }        
      })
    },
    async huoqu() {
      let phone = this.qyh1.replace(/\s*/g, "")
      if (phone === '') return this.$message.error('请输入手机号')
      await code().then((res) => {
        this.uuid = res.uuid
        this.tup = res.img
        this.yanzm = 1
      })
    },
    async huoqu2() {
      let phone = this.qyh2.replace(/\s*/g, "")
      if (phone === '') return this.$message.error('请输入手机号')
      await code().then((res) => {
        console.log(phone)
        this.uuid = res.uuid
        this.tup = res.img
        this.yanzm = 2
      })
    },
    async yanz() {
      let phone = this.qyh1.replace(/\s*/g, "")
      let phones = { phone, uuid: this.uuid }
      let codenum = { code: this.yyy, uuid: this.uuid }
      await codeCheck(codenum).then((res) => {
        console.log(res)
        if (res === false) {
          this.$message.error('验证码错误')
          setTimeout(() => {
            location.reload()
          }, 2000);
        } else {
          this.$message.success('验证成功！')
          this.yanzm = 0
          console.log(phones)
          setTimeout(() => {
            this.$axios({
              method: 'post',
              url: serverConfig.baseURL + '/web/userAccount/smsCode',
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
              },
              data: phones
            })
          }, 2000);
          // 短信倒计时 ↓
          let btn = this.$refs.qinyouhao1
          var time, timer
          if (btn.getAttribute("flag") == "false" || btn.getAttribute("flag") == false) {
            return false;
          }
          btn.setAttribute("flag", false);
          time = 60;
          clearInterval(timer); //清除计时器
          btn.innerText = time + "s";
          btn.classList.add("getcode-disabled")
          timer = setInterval(function () {
            time--;
            btn.innerText = time + "s";
            if (time <= 0) {
              //重置获取验证码按钮状态，变为可点击，即可再次获取
              clearInterval(timer); //清除计时器
              btn.classList.remove("getcode-disabled");
              btn.innerText = "获取验证码";
              btn.setAttribute("flag", true);
            }
          }, 1000);
          // ↑
        }
      })
    },
    async yanz2() {
      let phone = this.qyh2.replace(/\s*/g, "")
      let phones = { phone, uuid: this.uuid }
      let codenum = { code: this.yyy, uuid: this.uuid }
      await codeCheck(codenum).then((res) => {
        console.log(res)
        if (res === false) {
          this.$message.error('验证码错误')
          setTimeout(() => {
            location.reload()
          }, 2000);
        } else {
          this.$message.success('验证成功！')
          this.yanzm = 0
          console.log(phones)
          setTimeout(() => {
            this.$axios({
              method: 'post',
              url: serverConfig.baseURL + '/web/userAccount/smsCode',
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
              },
              data: phones
            })
          }, 2000);
          // 短信倒计时 ↓
          let btn = this.$refs.getnums
          var time, timer
          if (btn.getAttribute("flag") == "false" || btn.getAttribute("flag") == false) {
            return false;
          }
          btn.setAttribute("flag", false);
          time = 60;
          clearInterval(timer); //清除计时器
          btn.innerText = time + "s";
          btn.classList.add("getcode-disabled")
          timer = setInterval(function () {
            time--;
            btn.innerText = time + "s";
            if (time <= 0) {
              //重置获取验证码按钮状态，变为可点击，即可再次获取
              clearInterval(timer); //清除计时器
              btn.classList.remove("getcode-disabled");
              btn.innerText = "获取验证码";
              btn.setAttribute("flag", true);
            }
          }, 1000);
          // ↑
        }
      })
    },
    shop(type){
      if(type==1){
      }
      else{
        this.shopnum=type
      }
    },  
         
    loopWaitOrderFinish(){
            if(this.shopnum==0){
                return;
            }
            orderDetail({orderId:this.orderId}).then((res) => {
                console.log(res)
                if(res.orderStauts==1){// 待支付
                    setTimeout(()=>{
                        this.loopWaitOrderFinish();
                    },1000)
                }
                else{
                    this.$message.success('支付完成！');
                    setTimeout(() => {
                        this.shopnum = 0;
                        this.$router.push('/AccountTransactions');
                    }, 2000)
                }
            })
        },
    huoqus() {
      // 短信倒计时 ↓
      let btn = this.$refs.getnums
      var time, timer
      if (btn.getAttribute("flag") == "false" || btn.getAttribute("flag") == false) {
        return false;
      }
      btn.setAttribute("flag", false);
      time = 60;
      clearInterval(timer); //清除计时器
      btn.innerText = time + "s";
      btn.classList.add("getcode-disabled")
      timer = setInterval(function () {
        time--;
        btn.innerText = time + "s";
        if (time <= 0) {
          //重置获取验证码按钮状态，变为可点击，即可再次获取
          clearInterval(timer); //清除计时器
          btn.classList.remove("getcode-disabled");
          btn.innerText = "获取验证码";
          btn.setAttribute("flag", true);
        }
      }, 1000);
      // ↑
    },
    szs() {
      this.price = parseFloat(this.price).toFixed(2)
    },
    beforeuploadfun(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.error('图片只能是JPG/PNG格式！')
      }
    },
    newChoose() {
      this.$router.push('/AccountTransactions');
    },
    agree() {
      this.$router.push('/AgreeOur');
    },
    protect() {
      this.$router.push('/AgencyMortgage');
    },
    TransactionSecurity() {
      this.$router.push('/TransactionSecurity');
    },
    TradingInformation() {
      this.$router.push('/TradingInformation');
    },
    goBidding() {
      this.$router.push('/Bidding')
    },    
    account(){
            this.$router.push('/AccountTransactions')
        },
    all() {
      this.$router.push('/GameList');
    },
    doublefun(type) {
      this.doublenum = type;
      this.isIndemnity = type;
    },
    orderTypeFun(type) {
      this.productOrderType = type;
    },
    choose(type, itemid) {
      this.bigarea = 1
      this.active = itemid;
      // 大区的ID（label）
      this.labelID.propertyId = this.gameArea[0].id;
      if (this.gameArea[0].children[0].hasChildren) {
        this.ifchildren = 1;
        this.littlearea = 1
        this.gameAreaTwo = this.gameArea[0].children[type].children[0];
      }
      else {
        this.labelID.value = itemid.toString();
        this.littlearea = 0
      }
    },
    chooses(type) {
      this.xiaoqu = 1
      this.actives = type;
      this.labelID.value = type.toString();
    },
    index() {
      this.$router.push('/index');
    },
    // 接收上个页面的数据
    async areaServicefun() {

      this.isSync = Number(this.$route.query.isSync)
      console.log(typeof (this.isSync))
      // 游戏名字
      this.gameName = this.$route.query.gameName;
      // 游戏头像
      this.iconPath = this.$route.query.iconPath;
      // 调接口的id
      this.gameId = this.$route.query.gameId;
      let gameID = { gameId: this.gameId };
      await areaService(gameID).then((res) => {
        this.gameArea = res;
      });
    },
    // 查询游戏属性
    async gameProperty() {
      this.gameId = this.$route.query.gameId;
      let gameID = { gameId: this.gameId };
      await gameProperty(gameID).then((res) => {
        this.gamePropertyList = this.information(res);

      });
    },
    information(tree) {
      function getTree(tree) {
        tree.forEach((item) => {
          if (item.controlType == 1) {
            item.childrenValue = '';
          } else if (item.children && item.children.length > 0) {
            if (item.filedName) {
              item.childrenValue = '';
            }
            getTree(item.children);
          }
        });
      }
      getTree(tree);
      return tree;
    },
    addInformation(tree, propertyId, value) {
      let arr = [];
      function getTree(tree) {
        tree.forEach((item, index) => {
          if (item.childrenValue) {
            if (item.childrenValue.length !== 0) {
              if (item.controlType == 6) {
                console.log(index, '我是index');
                let newArr = item.childrenValue;
                newArr.forEach((e, i) => {
                  newArr[i] = e[e.length - 1];
                });
                console.log(newArr, '我是newArr');

                arr.push({
                  propertyId: item.id,
                  value:
                    newArr.length > 1 ? newArr.join(',') : newArr.toString(),
                });
              } else {
                arr.push({
                  propertyId: item.id,
                  value: item.childrenValue.toString(),
                });
              }
            }
          }
          if (item.children && item.children.length > 0) {
            getTree(item.children);
          }
        });
      }
      getTree(tree);
      return arr;
    },
    async addCommodity() {
      // if (this.price < 1) return this.$message.error('价格不能小于0');
      if(this.isSync == 0){
        // if (this.title == '' || this.title == null || this.title == undefined  ) return this.$message.error('商品名称不能为空');
        // if (this.description == '' || this.description == null || this.description == undefined  ) return this.$message.error('商品描述不能为空');
        // if (!this.mainImgId) return this.$message.error('请上传商品主图');
        // if (!this.detailImageIds.length) return this.$message.error('请上传详情图集');
        // if (!this.accountImageIds.length) return this.$message.error('请上传号内截图');
      }
      // if (this.qq == '' || this.qq == null || this.qq == undefined  ) return this.$message.error('QQ号不能为空');
      // if (this.wechat == '' || this.wechat == null || this.wechat == undefined  ) return this.$message.error('微信号不能为空');
      // if (this.telephone == '' || this.telephone == null || this.telephone == undefined  ) return this.$message.error('联系电话号不能为空');
            
      // if (this.statusAfterAudit == '' || this.statusAfterAudit == null || this.statusAfterAudit == undefined  ) return this.$message.error('请选择审核后状态');
      // for(let i = 0 ; i < this.gamePropertyList.length; i++){
      //   for(let j = 0 ; j < this.gamePropertyList[i].children.length ; j++){
      //     if(this.gamePropertyList[i].children[j].isRequired == 1 && !this.gamePropertyList[i].children[j].childrenValue){
      //       if (this.gamePropertyList[i].children[j].childrenValue == '' || this.gamePropertyList[i].children[j].childrenValue == null || this.gamePropertyList[i].children[j].childrenValue == undefined  ) return this.$message.error(`${this.gamePropertyList[i].children[j].label}不能为空`);
      //     }
      //   }
      // }

      
      // if(this.isIndemnity  == 1){
      //   if (!this.friendName1) return this.$message.error('请输入亲友1姓名');
      //   if (!this.friendName2) return this.$message.error('请输入亲友2姓名');
      //   if (this.qyh1 == '' || this.qyh1 == null || this.qyh1 == undefined  ) return this.$message.error('亲友1联系电话不能为空');
      //   if (this.qyh2 == '' || this.qyh2 == null || this.qyh2 == undefined  ) return this.$message.error('亲友2联系电话不能为空');
      //   if(this.qinhaohao1 == 1){
      //     if (this.yzm1 == '' || this.yzm1 == null || this.yzm1 == undefined  ) return this.$message.error('亲友1验证码不能为空');
      //   }
      //   if(this.qinhaohao1 == 1){
      //     if (this.yzm2 == '' || this.yzm2 == null || this.yzm2 == undefined  ) return this.$message.error('亲友2验证码不能为空');
      //   }

      //   if(this.mainImgIdss.length < 2){
      //     this.$message.error('请上传 2-10 张网购订单截图');
      //     return
      //   }
      // }
      
      let sx = this.addInformation(this.gamePropertyList).concat(
        this.gameProperties1
      );
      let fabu = {
        accountImageIds: this.accountImageIds,
        // alipayImg1:this.mainImgIds,
        // alipayImg2:this.mainImgIdss,
        description: this.description,
        detailImageIds: this.detailImageIds,
        friends: [{
          // alipayImgId: '',//	支付宝截图id
          friendName: this.friendName1,//	亲友名称
          friendPhone: Number(this.qyh1),//亲友手机号
          relationship: this.relationValue1,//与亲友关系
          smsCode: this.yzm1,//手机验证码
        },{
          // alipayImgId: this.mainImgIdss,//	支付宝截图id
          friendName: this.friendName2,//	亲友名称
          friendPhone: Number(this.qyh2),//亲友手机号
          relationship: this.relationValue2,//与亲友关系
          smsCode: this.yzm2,//手机验证码
        }],
        orderImgIds: this.mainImgIdss,//网购订单截图
        // friendPhone1: Number(this.qyh1),
        // friendPhone2: Number(this.qyh2),
        gameId: this.gameId,
        gameProperties: sx,
        isIndemnity: this.isIndemnity,
        mainImgId: this.mainImgId,
        price: Number(this.price),
        productDetails: this.productDetails,
        qq: this.qq,
        // smsCode1:this.yzm1,
        // smsCode2:this.yzm2,
        statusAfterAudit: this.statusAfterAudit,
        telephone: Number(this.telephone),
        title: this.title,
        wechat: this.wechat,
        modifyFriend: this.modifyFriend,
        productOrderType: this.productOrderType
      };

      // ---------------------------
      // ----------------
      // --------------
      // await productGoods(fabu).then((res) => {
      // })
      this.$axios({
        method: 'post',
        url: serverConfig.baseURL + '/web/estimateProduct',
        data: fabu,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: localStorage.getItem('username'),
        },
      })
        .then((response) => {
          this.$message.success('鉴定提交成功！');
          
          if(this.productOrderType==2){
            let result = response.data;
            this.QrCode = result.qrCode;
                    this.orderId = result.orderId;
                    let orderInfo = [{title:'订单编号：',num:result.orderNo,type:1},
                            {title:'下单时间：',num:result.date,type:2},
                            {title:'实际付款：',num:result.price,type:3},
                            {title:'手续费用：',num:result.servicePrice,type:4}]
                    this.ordernumber = orderInfo;
                    this.orderPrice = result.price;
                    this.shopnum = 1
                    setTimeout(()=>{
                        this.loopWaitOrderFinish();
                    },1000)
          }
          else{
            setTimeout(() => {
              this.$router.push('/AccountTransactions');
            }, 2000)
          }
        })
        .catch((error) => {
          // this.$message.error(error.);
          this.$message.error('提交失败' + error.response.data.message);
        });
      // --------------
      // ------------------
      // --------------------------
    },
    onSuccessfun(res) {
      this.$message.success('文件上传成功！');
    },
    handlerSuscess(res) {
      this.mainImgId = res.id;
    },
    handlerSuscessss(res) {
      this.mainImgIds = res.id;
      console.log(res.id)
    },
    handlerSuscesss(res) {
      this.mainImgIdss.push(res.id);
      console.log(res.id)
    },
    handleRemoveOrder: function(e,fileList){
      this.mainImgIdss = []
      for(let i = 0 ; i < fileList.length ; i++){
        this.mainImgIdss.push(fileList[i].response.id)
      }
      console.log(this.mainImgIdss)
    },
    handlerSuscesstwo(res) {
      this.detailImageIds.push(res.id);
    },
    handlerSuscessthree(res) {
      this.accountImageIds.push(res.id);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.$message.success('文件已添加至上传列表！');
    },
    handleRemove(file) {
      this.$message.success('文件已移除！');
    },
    getToken() {
      this.headers.Authorization = localStorage.getItem('username');
    },
    changefun(type) {
    },
    xianshicao(index) {
    },
    chooseafter() {
      this.statusAfterAudit = this.value;
    },
  },
  data() {
    return {
      payTimeDuration: 20,
      orderPrice: 100.00,
      orderId: -1,
      QrCode:'',
      ordernumber:[
        {title:'订单编号：',num:'',type:1},
        {title:'下单时间：',num:'',type:2},
        {title:'实际付款：',num:'￥',type:3},
        {title:'手续费用：',num:'￥',type:4}
      ],
      shopnum:0,
      sellRates: 10,
      buyRates: 10,
      qinyouyou1:'',
      qinyouyou2:'',
      qinhaohao1:'',
      qinhaohao2:'',
      qinqinhao:2,
      yyy: '',
      tup: '',
      yanzm: 0,
      modifyFriend: 0,
      qyh1: '',
      qyh2: '',
      yzm1: '',
      yzm2: '',
      isSync: 0,
      xiaoqu: 0,
      littlearea: 0,
      bigarea: 0,
      serverConfig: '',
      doublenum: 1,
      productOrderType: 1,
      active: 0,
      actives: 0,
      // 号内截图ID 1
      accountImageIds: [],
      // 卖点描述 2
      description: '',
      // 商品详情图集ID 3
      detailImageIds: [],
      // 游戏ID 4
      gameId: '',
      // 游戏属性 gameProperties 5
      // 是否包赔 6
      isIndemnity: 1,
      // 商品主图ID 7
      mainImgId: 0,
      mainImgIds: 0,
      mainImgIdss: [],
      // 商品价格 8
      price: 0,
      // 商品详情 false 9
      productDetails: '',
      // QQ false 10
      qq: '',
      // 上架后状态  11
      statusAfterAudit: 0,
      // 卖家联系电话 12
      telephone: '',

      // 商品标题 13
      title: '',
      // 微信 14
      wechat: '',
      // 游戏名字
      gameName: '',
      // 游戏头像
      iconPath: '',
      // 调接口的id

      // 游戏区服 一级数据
      gameArea: [],
      // 游戏区服 二级数据
      gameAreaTwo: [],
      // 判断是否有 children
      ifchildren: 0,
      gamePropertyList: [],
      options: '',
      optiones: [
        {
          value: 4,
          label: '上架',
        },
        {
          value: 3,
          label: '未上架',
        },
      ],

      value: [{}],
      gameProperties: [
        {
          propertyId: 0,
          value: '',
        },
      ],
      gameProperties1: [],
      input: '',
      inputs: '',
      inputes: '',
      textarea: '',
      dialogImageUrl: '',
      dialogVisible: false,
      headers: {
        Authorization: '',
      },
      onelimit: 1,
      // 游戏大区ID(label的ID) 和  服务器ID
      labelID: {
        propertyId: '', // 游戏大区ID(label的ID)
        value: '', // 服务器ID
      },
      input1: '',
      input2: '',
      input3: '',
      optionsRela: [{
          value: 1,
          label: '父母'
        },{
          value: 2,
          label: '兄弟'
        },{
          value: 3,
          label: '姊妹'
        },{
          value: 4,
          label: '朋友'
        }],
        relationValue1: 1,
        relationValue2: 1,
        friendName1: '',//亲友名称 1
        friendName2: '',//亲友名称 2
        orderImgsHuiXian: [],//两个月内订单截图
        fileList: [],
        qrDialogVisible: false,
        serviceQrCode: '',
    };
  },
  mounted(){    
    configByKeyService({configKey:'ORDER_PAY_MINUTES'}).then((res) =>{
      console.log('ORDER_PAY_MINUTES', res)
      this.payTimeDuration = res.configValue;
    })
  },
  beforeMount() {
    this.serverConfig = serverConfig;
    this.gameProperty();
    this.areaServicefun();
    this.getToken();
    this.friendfun()
    this.configList();
  },
};
</script>

<style lang="less" scoped>
.xiugailawo{
  cursor: pointer;
  width: 43px;
  height: 25px;
  background-color: #0076FF;
  border: none;
  border-radius: 8px;
  color: #fff;
  
  // transform: translate(-80PX,136px);
}

.lastlast{
  height: 300px;
}
.yanzhengma {
  position: fixed;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-182px,-156px);
  width: 365px;
  height: 312px;
  z-index: 9999;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid ;
}

.yanzhengma img {
  position: absolute;
  left: 18%;
  top: 63px;
  width: 200px;
  z-index: 99;
  height: 110px;
}

.yanzhengma input {
  margin: 200px auto;
  width: 100px;
  height: 40px;
  font-size: 15px;
  text-align: center;
}

.yanzhengma button {
  transform: translateY(-175px);
  width: 63px;
  height: 25px;
  margin: 0 auto;
  border: none;
  background-color: #0076FF;
  color: #ffffff;
  padding: 10px 0;
  line-height: 7px;
}

.qinyouhaoxiugai {
  display: flex;
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .aoxiugai {
    display: flex;
    flex-direction: column;
    margin-right: 110px;

    .dianhua {
      opacity: 0.54;
      font-size: 15px;
      color: #333333;
    }

    .zhifu {
      display: flex;
      margin-top: 30px;

      img {

        width: 165px;
        height: 112px;
      }
    }
  }
}

.huoquyanzhengma {
  text-align: center;
  position: absolute;
  width: 100px;
  cursor: pointer;
  color: #0076FF !important;
  font-size: 15px !important;
  right: -17px;
  top: 30px;
}

.qinyouhao {
  ::v-deep .el-input__inner {
    width: 250px;
    padding-right: 103px;
  }
}

.hei {
  height: auto !important;
}

.backgrbtn {
  background-color: #0076FF !important;
  color: #ffffff !important;
}

.herowechat {
  transform: translateX(0px);
}

.beforechecked {
  width: 100%;
}

.miaoshu {
  width: 50%;
}

.heroqq {
  transform: translateX(0px);
}

.herotopfill {
  padding-bottom: 10px !important;
}

.elinput {
  display: flex;
}

.maxbox {
  .fill {
    padding-bottom: 140px;
  }
}

.heroAlliance {
  width: 1200px;
  margin: 0 auto;

  .heroalliance {
    position: relative;
    display: flex;
    height: 144px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;

    .heroimg {
      margin-left: 20px;
      margin-top: 20px;
      width: 100px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .herolis {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      span {
        font-size: 30px;
        color: #333;
        opacity: 0.86;
        font-weight: bold;
        margin-top: 20px;
      }

      button {
        cursor: pointer;
        margin-top: 20px;
        width: 120px;
        height: 40px;
        border: 1px solid #0076FF;
        border-radius: 8px;
        color: #0076FF;
        background-color: #ffffff;
      }
    }
  }

  .heroalliances {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;

    p {
      height: 33px;
      opacity: 0.86;
      font-size: 25px;
      margin-left: 20px;
      margin-top: 14px;
      font-weight: 700;
      text-align: left;
      color: #333333;
      border-bottom: 2px solid #d0d0d0;
    }

    .regional {
      display: flex;
      flex-wrap: wrap;

      button {
        cursor: pointer;
        width: 108px;
        height: 48px;
        background: #eeeeee;
        border-radius: 8px;
        border: none;
        margin-left: 25px;
        margin-top: 21px;
      }
    }
  }

  .heroalliancesa {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;

    p {
      height: 33px;
      opacity: 0.86;
      font-size: 25px;
      margin-left: 20px;
      margin-top: 14px;
      font-weight: 700;
      text-align: left;
      color: #333333;
      border-bottom: 2px solid #d0d0d0;

      span {
        font-size: 15px;
        color: #919191;
        margin-left: 30px;
        font-weight: normal;
      }
    }

    .numprice {
      position: relative;
      margin-left: 20px;
      margin-top: 10px;
      width: 20%;

      .firstnumprice {
        color: red;
      }

      span:last-child {
        font-size: 15px;
      }

      input {
        width: 443px;
        height: 40px;
      }
    }

    .mainphoto {
      height: 173px;
      overflow: hidden;
      
    }

    .serverzero {
      color: #fb0d0d;
      margin-left: 115px;
    }

    .double {
      margin-left: 20px;
      margin-top: 30px;

      span:first-child {
        color: red;
      }

      span:last-child {
        font-size: 15px;
        margin-right: 8px;
      }

      button {
        width: 59px;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        margin-right: 15px;
        border: none;
      }

      .bkbt {
        background: #0076FF;
        color: #ffffff;
      }
    }
  }
}

.nextfoot {
  cursor: pointer;
  width: 327px;
  height: 60px;
  background: #0076FF;
  border-radius: 8px;
  border: none;
  margin-left: 450px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

.nav {
  display: flex;
  margin: 20px 0 0 10px;
}

.nav li {
  cursor: pointer;
  list-style: none;
  color: #919191;
  font-size: 20px;
  width: 100px;
  height: 30px;
  line-height: 30px;
}

.navline {
  display: flex;
}

.navline li {
  list-style: none;
  width: 50px;
  height: 4px;
  margin-left: 5px;
  border-radius: 20px;
  background-color: #0076FF;
}

.navline li:nth-child(2) {
  margin-left: 53px;
}

.navline li:nth-child(3) {
  margin-left: 48px;
}

.navline li:nth-child(4) {
  margin-left: 50px;
  width: 88px;
}

.allgame {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  transform: translateX(5px);
}

.alonegame {
  width: 180px;
  height: 180px;
  background: #ffffff;
  border: 0.5px solid #707070;
  border-radius: 8px;
  margin-right: 16px;
  margin-top: 10px;
}

.gamepto {
  width: 80px;
  height: 80px;
  margin: 30px auto;
}

.gamepto img {
  width: 100%;
  height: 100%;
}

.alonegame span {
  display: block;
  text-align: center;
  margin: -10px auto;
}

.heronav {
  position: absolute;
  top: 0px;
  right: -200px !important;
  margin-bottom: 600px !important;
}

.rowDivs{
  display: flex;
  flex-direction: row;
  .numprice{
    display: flex;
    align-items: center;
    width: 20%!important;
    .namesss{
      // width: 50px;
      // display: inline-block;
      // border: 1px solid red;
    }
    .el-input{
      width: 110px;
    }
    .el-select{
      width: 110px!important;
    }
  }
  .qinyouPhone{
    width: 30%!important;
    .el-input{
      width: 220px;
    }
    
    .qinyouInput{
      position: relative;
      .huoquyanzhengma{
        position: absolute;
        right: -20px;
        top: 10px;
      }
    }
  }
}
.uploadView{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .mainphoto{
    display: flex;
    flex-direction: row;
    width: 100%!important;
    flex-wrap: nowrap;
    .aliPay{
      margin-right: 10px;
      width: 120px!important;
    }
    .shopOrder{
      width: 200px!important;
    }
  }
  .tips{
    width: 98%;
    font-size: 12px;
    color: #F60707;
    margin: 0 auto 20px;
  }
}
.orderImgs{
  width: 95%;
  margin: 10px auto;
  >div{
    margin-right: 15px;
  }
}

.masker{
    width: 100%;
    height: 110%;
    transform: translateY(-10%);
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9;
    .maskinput{
        position: relative;
        margin: 10% auto;
        width: 488px;
        height: 292px;
        background: #ffffff;
        border: 0.5px solid rgba(112,112,112,0.54);
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16); 
        display: flex;
        flex-direction: column;
        align-items: center;
        .chacha{
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 10px;
            width: 12px;
            height: 12px;
        }
    }
    .maskpay{
        position: relative;
        margin: 10% auto;
        width: 750px;
        height: 578px;
        background: #ffffff;
        border: 0.5px solid rgba(112,112,112,0.54);
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16); 
        display: flex;
        flex-direction: column;
        align-items: center;
        .chacha{
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 10px;
            width: 12px;
            height: 12px;
        }
        .alreadysucc{
            font-weight: bold;
            margin: 30px 0;
            font-size: 16px;
        }
        .masktime{
            color: #FF1D1D;
            font-size: 12px;
            margin-bottom: 30px;
        }
        img{
            width: 198px;
            height: 192px;
        }
        .pleace{
            font-size: 12px;
            margin-top: 10px;
        }
        .maskprice{
            color: #FF0000;
            font-size: 20px;
            font-weight: bold;
            margin: 10px 0;
        }
        .orderNumber{
            font-size: 12px;
            width: 200px;
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
