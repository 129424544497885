<template>
    <div id="app">
        <div class="masker" v-if="this.shopnum===3">
            <div class="maskinput"  style="margin-top:20% !important">                
                <img src="../../assets/chacha.png" class="chacha" @click="shop(0)" alt="">
                <p style="text-align: left;width: 80%;margin-top: 20px;padding-bottom: 20px;border-bottom: solid 1px #ddd;">竞价出价</p>                
                <el-input style="width:275px;margin-top:40px" type="number" v-model="newCurPrice" placeholder="请输入价格"></el-input>
                <el-button type="primary" style="width:231px;margin-top:50px" @click="handleBidding()">确认出价</el-button>
            </div>
        </div>
        <div class="masker" v-if="this.shopnum === 1">
            <div class="maskpay">
                <img src="../../assets/chacha.png" class="chacha" @click="shop(0)" alt="">
                <span class="alreadysucc">您的订单已提交成功，请尽快完成支付</span>
                <img v-if="QrCode" :src="QrCode" alt="">
                <span class="pleace">请使用支付宝扫码支付</span>
                <span class="maskprice">￥{{orderNum.price}}</span>
                <div class="orderNumber">
                    <span class="ordernu">订单号</span>
                    <span class="ordernumbers">{{ orderNum.orderNo }}</span>
                </div>
                <div class="orderNumber">
                    <span class="ordernu">创建时间</span>
                    <span class="ordernumbers">{{ orderNum.createTime }}</span>
                </div>
            </div>
        </div>
        
        <div class="masker" v-if="this.shopnum === 5">
            <div class="maskpay">
                <img src="../../assets/chacha.png" class="chacha" @click="shop(0)" alt="">
                <span class="alreadysucc">您的订单已提交成功，请尽快完成支付</span>
                <img v-if="remaining.qrCode" :src="remaining.qrCode" alt="">
                <span class="pleace">请使用支付宝扫码支付</span>
                <span class="maskprice">￥{{remaining.price}}</span>
                <div class="orderNumber">
                    <span class="ordernu">订单号</span>
                    <span class="ordernumbers">{{ remaining.orderNo }}</span>
                </div>
                <div class="orderNumber">
                    <span class="ordernu">创建时间</span>
                    <span class="ordernumbers">{{ remaining.createTime }}</span>
                </div>
            </div>
        </div>
        <!-- 头部 -->
        <div class="PersonalCenter">
            <index-heat class="personww"></index-heat>
            <div class="h_mid">
                <div class="h_midmax h_midmaxes">
                    <div @click="index()">
                        <span>网站首页</span>
                    </div>
                    <div class="navlist">
                        <li @click="all()">全部游戏</li>
                        <li @click="bidding()">竞价中心</li>
                        <li @click="account()">我要卖号</li>
                        <li @click="protect()">担保中介</li>
                        <li @click="TradingInformation()">交易信息</li>
                        <li @click="TransactionSecurity()">交易保障</li>
                        <li @click="agree">关于我们</li>
                    </div>
                </div>
            </div>
            <div class="company">
                <i class="fill"></i>
                <div class="minbox">
                    <div class="agreeour">
                        <div class="our">
                            <div class="agreew" @click="jump(0)">
                                <i>
                                    <img src="../../assets/gerenzhongxin.png" alt="">
                                </i>
                                <span>
                                    <!-- <router-link to="index" class="agreeme">个人中心</router-link> -->
                                    <div class="agreeme">个人中心</div>
                                </span>
                            </div>
                            <div class="companylist">
                                <div class="numtrading" @click="jump(1)" :class="{ backgro: activeType === 1 }">
                                    <i>
                                        <img src="../../assets/路径 59.png" alt="" v-if="activeType === 1">
                                        <img src="../../assets/zhanghaojiaoyi.png" alt="" v-else>
                                        <img src="" alt="">
                                    </i>
                                    <span :class="{ fontcolor: activeType === 1 }">账号交易</span>
                                    <i class="arrow">
                                        <img src="../../assets/xia.png" alt="" v-if="activeType === 1">
                                        <img src="../../assets/arrow2.png" alt="" v-else>
                                    </i>
                                </div>
                                <div class="numtradchild" v-if="activeType === 1" style="height: 70px !important;">
                                    <!-- <li :class="{ checked: litype === 5 }" @click="jumpt(5)"><span>待付款</span></li> -->
                                    <!-- <li :class="{ checked: litype === 1 }" @click="orderfuns(1, 1, 1)"><span>待发货</span>
                                    </li>
                                    <li :class="{ checked: litype === 2 }" @click="orderfuns(1, 2, 2)"><span>待收货</span>
                                    </li>
                                    <li :class="{ checked: litype === 3 }" @click="orderfuns(1, 3, 3)"><span>交易完成</span>
                                    </li> -->
                                    <li :class="{ checked: litype === 11 }" @click="jumpt(11)"><span>我的鉴定</span></li>
                                    <li :class="{ checked: litype === 12 }" @click="jumpt(12)"><span>我发布的竞价</span></li>
                                    <!-- <li :class="{ checked: litype === 13 }" @click="jumpt(13)"><span>我参与的竞价</span></li> -->
                                    <li :class="{ checked: litype === 4 }" @click="jumpt(4)"><span>我发布的商品</span></li>
                                    <!-- <li :class="{ checked: litype === 15 }" @click="jumpt(15)"><span>我购买的商品</span></li> -->
                                    <li :class="{ checked: litype === 1 }" @click="orderfuns(1,0,1)"><span>我的订单</span></li>
                                </div>
                                <div class="numtrading" @click="jump(2)" :class="{ backgro: activeType === 2 }">
                                    <i>
                                        <img src="../../assets/zichanzhongxin2.png" alt="" v-if="activeType === 2">
                                        <img src="../../assets/zicanzhongxin.png" alt="" v-else>
                                    </i>
                                    <span :class="{ fontcolor: activeType === 2 }">资产中心</span>
                                    <i class="arrow">
                                        <img src="../../assets/xia.png" alt="" v-if="activeType === 2">
                                        <img src="../../assets/arrow2.png" alt="" v-else>
                                    </i>
                                </div>
                                <!-- 资产中心acc -->
                                <div class="numtradchild" v-if="activeType === 2">
                                    <li :class="{ checked: litype === 1 }" @click="jumpt(1)"><span>我的余额</span></li>
                                    <li :class="{ checked: litype === 2 }" @click="jumpt(2)"><span>提现记录</span></li>
                                </div>
                                <div class="numtrading" @click="jump(3)" :class="{ backgro: activeType === 3 }">
                                    <i>
                                        <img src="../../assets/gerenxinxi.png" alt="" v-if="activeType === 3">
                                        <img src="../../assets/gerenxinxi2.png" alt="" v-else>
                                    </i>
                                    <span :class="{ fontcolor: activeType === 3 }">个人信息</span>
                                    <i class="arrow">
                                        <img src="../../assets/xia.png" alt="" v-if="activeType === 3">
                                        <img src="../../assets/arrow2.png" alt="" v-else>
                                    </i>
                                </div>
                                <div class="numtradchild" v-if="activeType === 3">
                                    <li :class="{ checked: litype === 1 }" @click="jumpt(1)"><span>基本信息</span></li>
                                    <li :class="{ checked: litype === 2 }" @click="jumpt(2)"><span>实名认证</span></li>
                                    <li :class="{ checked: litype === 3 }" @click="jumpt(3)"><span>修改密码</span></li>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="introduce" :class="{ bac: activeType === 1 && litype === 5 }">
                        <div class="send"
                            v-if="activeType === 1 && (litype === 0 || litype === 1 || litype === 2 || litype === 3)">
                            <span @click="orderfuns(1, 0, 1)"
                                :class="{ fontcolort: litypes === '', backgro: activeType === 1 && litypes === 1 }">待付款</span>
                            <span @click="orderfuns(1, 5, 1)"
                                :class="{ fontcolort: activeType === 1 && litypes === 5, backgro: activeType === 1 && litypes === 6 }">竞价领先</span>
                            <span @click="orderfuns(1, 6, 1)"
                                :class="{ fontcolort: activeType === 1 && litypes === 6, backgro: activeType === 1 && litypes === 7 }">竞价被超</span>
                            <!-- <span @click="orderfuns(1, 7, 1)"
                                :class="{ fontcolort: activeType === 1 && litypes === 7, backgro: activeType === 1 && litypes === 8 }">竞价成功</span> -->
                            <span @click="orderfuns(1, 1, 1)"
                                :class="{ fontcolort: litypes === '', backgro: activeType === 1 && litypes === 2 || activeType === 1 && litypes === '' }">待发货</span>
                            <span @click="orderfuns(1, 2, 1)"
                                :class="{ fontcolort: activeType === 1 && litypes === 2, backgro: activeType === 1 && litypes === 3 }">待收货</span>
                            <span @click="orderfuns(1, 3, 1)"
                                :class="{ fontcolort: activeType === 1 && litypes === 3, backgro: activeType === 1 && litypes === 4 }">交易完成</span>
                        </div>
                        <div class="send" v-if="activeType === 1 && litype === 11">
                            <span @click="currentEstimatePagefun('', '')"
                                :class="{ backgro: this.estimatePageSize.productStatus === '' }" class="fontcolort">全部</span>
                            <span @click="currentEstimatePagefun(1, 2)"
                                :class="{ backgro: this.estimatePageSize.productStatus === 2 }">待鉴定</span>
                            <span @click="currentEstimatePagefun(1, 3)"
                                :class="{ backgro: this.estimatePageSize.productStatus === 3 }">已鉴定</span>
                        </div>
                        <div class="send" v-if="activeType === 1 && litype === 12">
                            <span @click="currentBiddingPagefun('', '')"
                                :class="{ backgro: this.biddingPageSize.productStatus === '' }" class="fontcolort">全部</span>
                            <span @click="currentBiddingPagefun(1, 1)"
                                :class="{ backgro: this.biddingPageSize.productStatus === 1 }">待审核</span>
                            <span @click="currentBiddingPagefun(1, 2)"
                                :class="{ backgro: this.biddingPageSize.productStatus === 2 }">审核失败</span>
                            <span @click="currentBiddingPagefun(1, 3)"
                                :class="{ backgro: this.biddingPageSize.productStatus === 3 }">待上架</span>
                            <span @click="currentBiddingPagefun(1, 4)"
                                :class="{ backgro: this.biddingPageSize.productStatus === 4 }">售卖中</span>
                            <span @click="currentBiddingPagefun(1, 5)"
                                :class="{ backgro: this.biddingPageSize.productStatus === 5 }">交易中</span>
                            <span @click="currentBiddingPagefun(1, 6)"
                                :class="{ backgro: this.biddingPageSize.productStatus === 6 }">已下架</span>
                            <span @click="currentBiddingPagefun(1, 7)"
                                :class="{ backgro: this.biddingPageSize.productStatus === 7 }">已出售</span>
                        </div>
                        
                        <div class="send" v-if="activeType === 1 && litype === 4">
                            <span @click="currentPagefun('', '')"
                                :class="{ backgro: this.pageSize.productStatus === '' }" class="fontcolort">全部</span>
                            <span @click="currentPagefun(1, 1)"
                                :class="{ backgro: this.pageSize.productStatus === 1 }">待审核</span>
                            <span @click="currentPagefun(1, 2)"
                                :class="{ backgro: this.pageSize.productStatus === 2 }">审核失败</span>
                            <span @click="currentPagefun(1, 3)"
                                :class="{ backgro: this.pageSize.productStatus === 3 }">待上架</span>
                            <span @click="currentPagefun(1, 4)"
                                :class="{ backgro: this.pageSize.productStatus === 4 }">售卖中</span>
                            <span @click="currentPagefun(1, 5)"
                                :class="{ backgro: this.pageSize.productStatus === 5 }">交易中</span>
                            <span @click="currentPagefun(1, 6)"
                                :class="{ backgro: this.pageSize.productStatus === 6 }">已下架</span>
                            <span @click="currentPagefun(1, 7)"
                                :class="{ backgro: this.pageSize.productStatus === 7 }">已出售</span>
                        </div>
                        <div class="deal"
                            v-if="activeType === 1 && (litype === 0 || litype === 1 || litype === 2 || litype === 3)">
                            <span class="dealright orderinfomations">订单信息</span>
                            <span>单价</span>
                            <span>数量</span>
                            <span>类型</span>
                            <span>操作</span>
                        </div>
                        <!-- 订单详情 123qwe---------------------------------- -->
                        <div v-if="litype === 5">
                            <div class="CheckSchedule">
                                <div class="checktop">
                                    <span>{{ statusnum }}详情</span>
                                    <a class="lianxikefu" @click="showCustomerServiceQrCode">
                                    <button class="contentpeop">联系客服</button>
                                    </a>
                                </div>
                                <div class="serialNumber">
                                    <span>编号：</span>
                                    <span>{{ orderNum.orderNo }}</span>
                                </div>
                                <div class="ToAudit">
                                    <div class="auditleft">
                                        <span>商品:{{ statusnum }} </span>
                                        <span>{{ statustoke }}</span>
                                    </div>
                                    <div class="auditright">
                                        <!-- <div>
                                            <img src="../../assets/maomilogo4.png" alt="">
                                        </div> -->
                                        <button v-if="orderStauts==0" class="goPay" @click="shop(1)">去付款</button>
                                        <button v-else-if="orderNum.orderStauts==7" class="goPay" @click="shop(3)">再次出价</button>
                                        <button v-else-if="orderNum.orderStauts==6&&orderNum.productDetail.productStatus==8" class="goPay" @click="shop(5)">付尾款</button>
                                        <span v-else>{{ orderStauts==4?'交易取消':statusnum }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="backback"></div>
                            <!-- 价格与服务信息 -->
                            <div class="priceserve">
                                <div class="price">
                                    <span>价格与服务信息</span>
                                </div>
                                <div class="goodsmax">
                                    <div class="goodsprice">
                                        <span class="goodprice">商品售价：</span>
                                        <div>
                                            <span>￥{{ orderNum.goodsPrice }}</span>
                                        </div>
                                    </div>
                                    <div v-if="orderNum.orderType==2" class="goodsprice">
                                        <span class="goodprice">保证金：</span>
                                        <div>
                                            <span>￥{{ orderNum.price }}</span>
                                        </div>
                                    </div>
                                    <div class="goodsnum">
                                        <span>商品编码:</span>
                                        <span>{{ orderNum.orderNo }}</span>
                                    </div>
                                    <!-- <div class="compensation">
                                        <span>是否包赔:</span>
                                        <span>{{ ifpay[orderNum.insurancePrice] }}</span>
                                    </div> -->
                                </div>
                            </div>
                            <div class="backback"></div>
                            <!-- 账号信息 -->
                            <div class="priceserve">
                                <div class="price">
                                    <span>账号信息</span>
                                </div>
                                <div class="classnuminfo">
                                    <img :src="orderpicture" alt="" style="cursor: pointer;" @click="openGoodDetail()">
                                    <div class="godmaxright">
                                        <span class="goodsmaxones">{{ ordertitle }}</span>
                                        <span class="goodsmaxtwos">{{ ordercontent }}</span>
                                        <span class="goodsmaxthrees">{{ ordertime }}</span>
                                        <div class="areaprice">
                                            <span class="areapriceone">{{ ordergameName }}丨{{ ordergameService }}</span>
                                            <span class="areapricetwo">￥{{ ordercurprice }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="backback"></div>
                            <!-- 交易详情 -->
                            <div class="priceserve">
                                <div class="price">
                                    <span>交易详情</span>
                                </div>
                                <div class="goodsmax">
                                    <div class="compensation">
                                        <span>订单编号:</span>
                                        <span>{{ orderNum.orderNo }}</span>
                                    </div>
                                    <div class="compensation">
                                        <span>下单时间:</span>
                                        <span>{{ orderNum.createTime }}</span>
                                    </div>
                                    <div class="compensation">
                                        <span>支付方式:</span>
                                        <span>{{ zffs[orderNum.paymentMethod] }}</span>
                                    </div>
                                    <div class="compensation">
                                        <span>交易流水:</span>
                                        <span>{{ orderNum.orderNo }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="ann">
                                <button  @click="aaa(orderNum.orderId)" v-if="orderStauts === 2">确认收货</button>
                            </div>
                        </div>
                        <!-- ------------------------------------ -->
                        <!-- 商品详情 123qwer---------------------------------- -->
                        <div v-if="litype === 6">
                            <div class="CheckSchedule">
                                <div class="checktop">
                                    <span>商品详情</span>
                                    <a class="lianxikefu" @click="showCustomerServiceQrCode">
                                    <button class="contentpeop">联系客服</button>
                                    </a>
                                </div>
                                <div class="serialNumber">
                                    <span>编号：</span>
                                    <span>{{ goodsNum.productNo }}</span>
                                </div>
                                <div class="ToAudit">
                                    <div class="auditleft">
                                        <span>{{ statusnum }}</span>
                                        <span>{{ statustoke }}</span>
                                    </div>
                                    <div class="auditright">
                                        <!-- <div>
                                            <img src="../../assets/maomilogo4.png" alt="">
                                        </div> -->
                                        <span>{{ statusnum }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="backback"></div>
                            <!-- 价格与服务信息 -->
                            <div class="priceserve">
                                <div class="price">
                                    <span>价格与服务信息</span>
                                </div>
                                <div class="goodsmax">
                                    <div class="goodsprice">
                                        <span class="goodprice">商品售价：</span>
                                        <div>
                                            <span>￥{{ goodsNum.price }}</span>
                                            <span>当前商品交易服务费{{ goodsNum.servicePrice }}元，预计实得收益{{
                                                    goodsNum.price - goodsNum.servicePrice
                                            }}元</span>
                                        </div>
                                    </div>
                                    <div class="goodsnum">
                                        <span>商品编码:</span>
                                        <span>{{ goodsNum.productNo }}</span>
                                    </div>
                                    <div class="compensation">
                                        <span>是否包赔:</span>
                                        <span>{{ ifpay[goodsNum.isIndemnity] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="backback"></div>
                            <!-- 账号信息 -->
                            <div class="priceserve">
                                <div class="price">
                                    <span>账号详情</span>
                                </div>
                                <div class="goodsmax">
                                    <div class="compensation">
                                        <span>商品名称:</span>
                                        <span>[{{ goodsNum.productNo }}]</span>
                                    </div>
                                    <div class="compensation">
                                        <span>商品描述:</span>
                                        <span>[{{ goodsNum.description }}]</span>
                                    </div>
                                    <div class="compensation">
                                        <span>商品主图:</span>
                                        <span>
                                            <img :src="goodsNum.mainImgPath" alt="">
                                        </span>
                                    </div>
                                    <div class="compensation" v-for="(item, index) in fornum" :key="index">
                                        <span>{{ item.propertyName }}:</span>
                                        <span>{{ item.propertyValue }}</span>
                                    </div>
                                </div>
                            </div>


                            <div class="backback"></div>
                            <!-- 交易详情 -->
                            <div class="priceserve">
                                <div class="price">
                                    <span>联系卖家</span>
                                </div>
                                <div class="goodsmax">
                                    <div class="compensation">
                                        <span>QQ:</span>
                                        <span>{{ goodsNum.qq }}</span>
                                    </div>
                                    <div class="compensation">
                                        <span>微信:</span>
                                        <span>{{ goodsNum.wechat }}</span>
                                    </div>
                                    <div class="compensation">
                                        <span>联系电话:</span>
                                        <span>{{ goodsNum.telephone }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="ann">
                                <button @click="shop(3)" v-if="orderStauts === 2">确认收货</button>
                            </div> -->
                        </div>
                        <!-- ------------------------------------ -->
                        <div class="myBalance" v-show="activeType === 2 && litype === 1 && money === 1 || money === 2">
                            <div class="myBalancebac">
                                <img src="../../assets/wodeyuebeijing.png" alt="">
                                <span class="myBalan">我的余额</span>
                                <div class="thisBalance">
                                    <span>当前账号余额</span>
                                    <span>￥{{this.zhanghuye.total}}</span>
                                </div>
                                <div class="sumBalance">
                                    <span>累计收益</span>
                                    <span>￥{{this.zhanghuye.incomeTotal}}</span>
                                </div>
                                <div class="sumline"></div>
                                <div class="sumWithdrawal">
                                    <span>累计提现</span>
                                    <span>￥{{this.zhanghuye.withdrawTotal}}</span>
                                </div>
                                <button @click="srzc(3)">提现</button>
                            </div>
                            <div class="IncomeSpending">
                                <div class="incomeSpending">
                                    <span @click="incomeExpenditurefun(1)"
                                        :class="{ blackBold: activeType === 2 && litype === 1 && money === 1 }">收入</span>
                                    <span @click="incomeExpenditurefun(2)"
                                        :class="{ blackBold: activeType === 2 && litype === 1 && money === 2 }">支出</span>
                                </div>
                                <div class="Spendingline">
                                    <i v-show="activeType === 2 && litype === 1 && money === 1"></i>
                                    <i v-show="activeType === 2 && litype === 1 && money === 2"></i>
                                </div>
                            </div>
                            <div v-show="activeType === 2 && litype === 1">
                                <div class="OrderType">
                                    <li>订单类型</li>
                                    <li>交易账号</li>
                                    <li>交易时间</li>
                                    <li>交易所得</li>
                                    <li>账号余额</li>
                                </div>
                                <div class="orderfola">
                                    <div class="OrderDetails" v-for="(item,index) in income.content" :key="index">
                                    <li>{{srzcs[item.type]}}</li>
                                    <li>{{item.transactionAccount}}</li>
                                    <li>{{item.createTime}}</li>
                                    <li>{{item.amount}}</li>
                                    <li>{{item.accountBalance}}</li>
                                </div>
                                </div>
                            </div>
                            <!-- <div v-show="activeType === 2 && litype === 1 && money === 2">
                                <div class="OrderType">
                                    <li>订单类型</li>
                                    <li>交易账号</li>
                                    <li>交易时间</li>
                                    <li>交易所得</li>
                                    <li>账号余额</li>
                                </div>
                                <div class="OrderDetails">
                                    <li>账号出售</li>
                                    <li>LGO0087526</li>
                                    <li>2022-06-22 16:30</li>
                                    <li>888.00</li>
                                    <li>888.00</li>
                                </div>
                            </div> -->
                        </div>
                        <!-- 基本信息 -->
                        <div class="priceserve jibenxinxi" v-show="activeType === 3 && litype === 1">
                            <div class="price jibenxinxitwo">
                                <span>基本信息</span>
                            </div>
                            <div class="goodsmax">
                                <div class="goodsnum heatphoto">
                                    <span>头像:</span>
                                    <span>
                                        <!-- <img :src="this.getHeat" alt=""> -->
                                        <el-col :lg="6">
                                            <el-upload :action='serverConfig.baseURL + "/api/localStorage/pictures"'
                                                :before-upload="beforeuploadfun"
                                                accept="image/*"
                                                list-type="picture-card" class="fan" :on-success="handlerSuscessess"
                                                :headers="headers" :on-preview="handlePictureCardPreview"
                                                :on-remove="handleRemove">
                                                <i class="el-icon-plus"></i>
                                            </el-upload>
                                        </el-col>
                                    </span>
                                </div>
                                <div class="compensation">
                                    <span>手机号码:</span>
                                    <span>
                                        <input type="text" :value="this.getPhone" class="inputwo" readonly>
                                    </span>
                                </div>
                                <div class="compensation">
                                    <span>账户昵称:</span>
                                    <span>
                                        <input type="text" v-model="userName" class="inputhree">
                                    </span>
                                </div>
                                <button class="yes" @click="editorfuns">提交信息</button>
                            </div>
                        </div>
                        <!-- 实名认证 -->
                        <div class="priceserve xiugaimima" v-show="activeType === 3 && litype === 2">
                            <div class="price">
                                <span>实名认证</span>
                            </div>
                            <div class="goodsmax">

                                <div class="compensation">
                                    <span>真实姓名:</span>
                                    <span>
                                        <input type="text" v-model="RealName.actualName" placeholder="请输入姓名"
                                            class="inputwo">
                                    </span>
                                </div>
                                <div class="compensation">
                                    <span>身份证号:</span>
                                    <span>
                                        <input type="text" v-model="RealName.idCard" placeholder="请输入身份证号码"
                                            class="inputhree">
                                    </span>
                                </div>
                                <p class="uploadcard">上传身份证照片（仅支持PNG或者JPG格式，图片小于5M）</p>
                                <div class="sfzcard">
                                    <!-- 上传正面 -->
                                    <el-col :lg="6">
                                        <el-upload :action='serverConfig.baseURL + "/api/localStorage/pictures"'
                                        accept="image/*"
                                            list-type="picture-card" :on-success="handlerSuscess" :headers="headers"
                                            :before-upload="beforeuploadfun"
                                            :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <div class="el-upload__tip">身份证正面</div>
                                    </el-col>
                                    <!-- 上传身份证反面 -->
                                    <el-col :lg="6">
                                        <el-upload :action='serverConfig.baseURL + "/api/localStorage/pictures"'
                                            :before-upload="beforeuploadfun"
                                            
                                            accept="image/*"
                                            list-type="picture-card" class="fan" :on-success="handlerSuscesses"
                                            :headers="headers" :on-preview="handlePictureCardPreview"
                                            :on-remove="handleRemove">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <div class="el-upload__tip fans">身份证反面</div>
                                    </el-col>
                                </div>
                                <!-- <div class="maologo floatright">
                                    <img src="../../assets/猫咪logo2.png" alt="">
                                </div> -->

                                <button class="yes" @click="idCertificationfun">提交信息</button>
                            </div>
                        </div>
                        <!-- 修改密码 -->
                        <div class="priceserve xiugaimima" v-show="activeType === 3 && litype === 3">
                            <div class="price">
                                <span>修改密码</span>
                            </div>
                            <div class="goodsmax">

                                <div class="compensation">
                                    <span>输入新密码:</span>
                                    <span>
                                        <input type="password" v-model="inputPass" placeholder="请输入新密码" class="inputwo">
                                    </span>
                                </div>
                                <div class="compensation">
                                    <span>确认新密码:</span>
                                    <span>
                                        <input type="password" v-model="NewPassword.newPass" placeholder="请再次输入"
                                            class="inputhree">
                                    </span>
                                </div>
                                <!-- <div class="maologo rightbutton">
                                    <img src="../../assets/猫咪logo2.png" alt="">
                                </div> -->

                                <button class="yes" @click="updatePassfun">确认修改</button>
                            </div>
                        </div>
                        <!-- 提现记录 -->
                        <div class="priceserve xiugaimima" v-show="activeType === 2 && litype === 2">
                            <div class="price">
                                <span>提现记录</span>
                            </div>
                            <div class="Withdrawal">
                                <li :class="{tixianjil:tttt===undefined}" @click="withdrawfun()">全部</li>
                                <li :class="{tixianjil:tttt===1}" @click="withdrawfun(1)">待转账</li>
                                <li :class="{tixianjil:tttt===2}" @click="withdrawfun(2)">确认转账</li>
                                <li :class="{tixianjil:tttt===3}" @click="withdrawfun(3)">提现完成</li>
                            </div>
                            <div class="withdrawalType">
                                <li>提现类型</li>
                                <li>提现时间</li>
                                <li>提现金额</li>
                                <li>状态</li>
                                <li>操作</li>
                            </div>
                            <div class="withdrawalTypelist" v-for="(item,index) in tixiansum.content" :key="index">
                                <li>支付宝提现</li>
                                <li>{{item.applicationTime}}</li>
                                <li>{{item.amount}}</li>
                                <li>{{daisum[item.drawStatus]}}</li>
                                <li v-show="item.drawStatus!==2"><a class="lijizixun" @click="showCustomerServiceQrCode">{{daidai[item.drawStatus]}}</a></li>
                                <li class="queque" @click="quer(item.drawId)"  v-show="item.drawStatus===2"><button>确认到账</button></li>
                            </div>
                        </div>
                        <!-- 提现 -->
                        <div class="priceserve" v-show="activeType === 2 && litype === 1 && money === 3">
                            <div class="price">
                                <span>提现</span>
                            </div>
                            <div class="goodsmax">
                                <div class="goodsnum">
                                    <span>账户余额:</span>
                                    <span>￥{{this.zhanghuye.total}}</span>
                                </div>
                                <div class="compensation">
                                    <span>提现金额:</span>
                                    <span>
                                        <input type="text" v-model="jine" value="100" class="input">
                                    </span>
                                </div>
                                <div class="compensation">
                                    <span class="zhifb">支付宝:</span>
                                    <span>
                                        <input type="text" v-model="kah" placeholder="请输入支付宝账号" class="inputwo">
                                    </span>
                                </div>
                                <div class="compensation">
                                    <span>登录密码:</span>
                                    <span>
                                        <input type="text" v-model="mim" placeholder="请输入登录密码" class="inputhree">
                                    </span>
                                </div>
                                <button class="yes" @click="yesfun()">确认提现</button>
                                <!-- <div class="maologo">
                                    <img src="../../assets/maomilogo3.png" alt="">
                                </div> -->
                            </div>
                        </div>

                        <!-- 我的鉴定 -->
                        <el-table v-show="activeType === 1 && litype === 11" :data="AllEstimateOrders.content" style="width: 100%">
                            <el-table-column prop="productNo" label="商品编号" width="140" />
                            <el-table-column prop="gameName" label="游戏名称" width="140" />
                            <el-table-column prop="productOrderType" label="鉴定方式" width="110">
                                <template #default="scope">
                                    {{ scope.row.productOrderType==2?"专业鉴定":"免费鉴定" }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="price" label="评估价格" width="110" />
                            <el-table-column prop="createTime" label="发布时间" width="180" />
                            <el-table-column label="操作" min-width="220">
                            <template #default="scope">
                                <el-button link type="primary" size="mini" @click="succ(scope.row, 'estimate')">
                                    查看详情
                                </el-button>
                            </template>
                            </el-table-column>
                        </el-table>   
                        <el-pagination v-show="activeType === 1 && litype === 11" background
                            @current-change="currentEstimatePagefun" layout="prev, pager, next" :total="estimateSumPage">
                        </el-pagination>

                        <!-- 我的发布的竞价 -->
                        <el-table v-show="activeType === 1 && litype === 12" :data="AllBiddingOrders.content" style="width: 100%">
                            <el-table-column prop="productNo" label="商品编号" width="140" />
                            <el-table-column prop="gameName" label="游戏名称" width="140" />
                            <el-table-column prop="price" label="竞拍价格" width="110" />
                            <el-table-column prop="curPrice" label="最高出价" width="110" />
                            <el-table-column prop="createTime" label="发布时间" width="180" />
                            <el-table-column label="操作" min-width="220">
                            <template #default="scope">
                                <el-button link type="primary" size="mini" @click="succ(scope.row, 'bidding')">
                                    查看
                                </el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId, 'bidding')" v-show="scope.row.productStatus===3">上架商品</el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId, 'bidding')" v-show="scope.row.productStatus===6">重新上架</el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId, 'bidding')" v-show="scope.row.productStatus===4">下架商品</el-button>
                            </template>
                            </el-table-column>
                        </el-table>   
                        <el-pagination v-show="activeType === 1 && litype === 12" background
                            @current-change="currentBiddingPagefun" layout="prev, pager, next" :total="biddingSumPage">
                        </el-pagination>

                        <!-- 我的发布的商品 -->
                        <el-table v-show="activeType === 1 && litype === 4" :data="AllOrders.content" style="width: 100%">
                            <el-table-column prop="productNo" label="商品编号" width="180" />
                            <el-table-column prop="gameName" label="游戏名称" width="140" />
                            <el-table-column prop="price" label="价格" width="140" />
                            <el-table-column prop="createTime" label="发布时间" width="200" />
                            <el-table-column label="操作" min-width="240">
                            <template #default="scope">
                                <el-button link type="primary" size="mini" @click="succ(scope.row)">
                                    查看
                                </el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId)" v-show="scope.row.productStatus===3">上架商品</el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId)" v-show="scope.row.productStatus===6">重新上架</el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId)" v-show="scope.row.productStatus===4">下架商品</el-button>
                            </template>
                            </el-table-column>
                        </el-table>                        
                        <el-pagination v-show="activeType === 1 && litype === 4" background
                            @current-change="currentPagefun" layout="prev, pager, next" :total="sumPage">
                        </el-pagination>

                        <!-- 我的购买的商品 -->
                        <el-table v-show="activeType === 1 && litype === 15" :data="AllBuyOrders.content" style="width: 100%">
                            <el-table-column prop="productNo" label="商品编号" width="180" />
                            <el-table-column prop="gameName" label="游戏名称" width="140" />
                            <el-table-column prop="price" label="价格" width="140" />
                            <el-table-column prop="createTime" label="发布时间" width="200" />
                            <el-table-column label="操作" min-width="240">
                            <template #default="scope">
                                <el-button link type="primary" size="mini" @click="succ(scope.row)">
                                    查看
                                </el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId)" v-show="scope.row.productStatus===3">上架商品</el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId)" v-show="scope.row.productStatus===6">重新上架</el-button>
                                <el-button link type="primary" size="mini" @click="wodeshangp(scope.row.productId)" v-show="scope.row.productStatus===4">下架商品</el-button>
                            </template>
                            </el-table-column>
                        </el-table>                        
                        <el-pagination v-show="activeType === 1 && litype === 15" background
                            @current-change="currentPagefun" layout="prev, pager, next" :total="sumPage">
                        </el-pagination>

                        <div class="info" v-for="item in infolist" :key="item.id"
                            v-show="activeType === 1 && (litype === 0 || litype === 1 || litype === 2 || litype === 3)">
                            <span class="inforight">{{ item.orderNo }}</span>
                            <span>{{ item.orderType==2?(item.goodsPrice+' (保证金:'+item.price+')'):item.price }}</span>
                            <span>{{ item.num }}</span>
                            <span>{{ item.orderType==1?'商品':(item.orderType==2?'竞价':'鉴定') }}</span>
                            
                            <!-- <span>{{ item.actualPrice }}</span> -->
                            <span class="look" @click="detail(item.orderId, item.orderStauts)">查看进度</span>
                            <a class="lianxikefu" @click="showCustomerServiceQrCode">
                            <span class="contact">联系客服</span>
                            </a>
                        </div>
                        <el-pagination v-show="activeType === 1 && litype === 1" background @current-change="orderfuns"
                            layout="prev, pager, next" :total="sumPages">
                        </el-pagination>
                        <div class="alonecenter" v-if="activeType === 0">

                            <div class="alonetop">
                                <div class="aloneleft">
                                    <div class="lefttop">
                                        <img :src="avatarPathaaa" alt="">
                                        <div class="wan">
                                            <span class="wanone">{{ this.getName }}</span>
                                            <span class="wantwo">{{ txtx===1?'已实名':'未实名' }}</span>
                                            <button class="edito" @click="editorfun()">编辑资料</button>
                                        </div>

                                    </div>
                                    <div class="leftbot">账号ID：{{ this.getId }}</div>
                                </div>
                                <div class="aloneright">
                                    <span class="mybag">我的钱包</span>
                                    <div class="usemoney">
                                        <span class="moneyone">可用余额</span>
                                        <span class="moneytwo">￥{{this.zhanghuye.total}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="alonebut">
                                <div class="buttop">我要卖号</div>
                                <div class="butall">
                                    <!-- dedede -->
                                    <div class="buttopone">
                                        <img src="../../assets/daifahuo.svg" alt="">
                                        <div class="everyone">
                                            <span>待发货</span>
                                            <span class="onelast">{{dhf}}</span>
                                        </div>
                                        <button @click="orderfuns(1, 0, 1)">查看</button>
                                    </div>
                                    <div class="buttopone">
                                        <img src="../../assets/daifahuo.svg" alt="">
                                        <div class="everyone">
                                            <span>待收货</span>
                                            <span class="onelast">{{dhs}}</span>
                                        </div>
                                        <button @click="orderfuns(1, 1, 1)">查看</button>
                                    </div>
                                    <div class="buttopone">
                                        <img src="../../assets/daifahuo.svg" alt="">
                                        <div class="everyone">
                                            <span>已完成</span>
                                            <span class="onelast">{{jywcl}}</span>
                                        </div>
                                        <button @click="orderfuns(1, 2, 1)">查看</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import { editor, updatePass, encrypt, orderDetail, idCertification, withdraw, order, info, goodsDetail, biddingGoodsDetail, estimateGoodsDetail, user, biddingUser, estimateUser, incomeExpenditure,accountBalance } from '@/request/api'
import serverConfig from '../../request/config.js'
import {customerServiceQrCode} from '@/request/api'

export default {
    name: 'PersonalCenter',

    components: {
        IndexHeat

    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        async serviceQqfun(){
        // await serviceQq().then((res) =>{
        //     this.qqNum = res.serviceQq
        // })
    },
        quer(drawId){
            let ids = {id:drawId}
            this.$axios({
                method: 'put',
                url: serverConfig.baseURL + '/web/userAccount/withdraw',
                data: ids,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: localStorage.getItem('username'),
                },
            })
                .then((response) => {
                    this.$message.success('已确认!')
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                })
                .catch((error) => {
                    this.$message.error('确认失败' + error);
                });
        },
        beforeuploadfun(file){
            const isJPG = file.type ==='image/jpeg' || file.type === 'image/png'
            if(!isJPG){
                this.$message.error('图片只能是JPG/PNG格式！')
            }
        },
        async accountBalancefun(){
            await accountBalance().then((res) =>{
                this.zhanghuye = res
            })
        },
        async withdrawfun(type){
            this.tttt=type
            let num = {drawStatus:this.tttt}
            await withdraw(num).then((res) =>{
                this.tixiansum = res
            })
        },
        yesfun(){
            if(this.zhanghuye.total<Number(this.jine)) return this.$message.error('提现金额不能大于余额')
            if(Number(this.jine)<0) return this.$message.error('提现金额不能小于0')
            let num = {
                          "alipayAccount":this.kah,
                          "amount":Number(this.jine),
                          "password":encrypt(this.mim)
                       }
            this.$axios({
                method: 'post',
                url: serverConfig.baseURL + '/web/userAccount/withdraw',
                data: num,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: localStorage.getItem('username'),
                },
            })
                .then((response) => {
                    this.$message.success('提现成功!')
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                })
                .catch((error) => {
                    this.$message.error('提现失败,'+error.response.data.message);
                    
                });
        },
        aaa(typ){
            this.$axios({
                method: 'post',
                url: serverConfig.baseURL + '/web/order/confirm',
                data: {id:typ},
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: localStorage.getItem('username'),
                },
            })
                .then((response) => {
                    this.$message.success('收货成功!')
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                })
                .catch((error) => {
                    this.$message.error('提交失败' + error);
                });
        },
        handlerSuscess(res) {
            this.RealName.idCardFrontFileId = Number(res.id)
        },
        handlerSuscesses(res) {
            this.RealName.idCardBackFileId = Number(res.id)
        },
        handlerSuscessess(res) {
            this.fixname.avatarId = Number(res.id)
            // this.RealName.idCardBackFileId = Number(res.id)
        },
        handleRemove(file, fileList) {
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        agree() {
            this.$router.push('/AgreeOur')
        },
        details() {
            this.$router.push('./TwentySeven')
        },
        protect() {
            this.$router.push('/AgencyMortgage')
        },
        TransactionSecurity() {
            this.$router.push('/TransactionSecurity')
        },
        TradingInformation() {
            this.$router.push('/TradingInformation')
        },
        account() {
            this.$router.push('/AccountTransactions')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        all() {
            this.$router.push('/GameList')
        },
        find(type) {
            this.activeType = type
        },
        jump(type) {
            this.activeType = type
        },
        async jumpt(type) {
            console.log('pageSize',this.pageSize)
            this.litype = type
            await accountBalance().then((res) =>{
                this.zhanghuye = res
            })
        },

        srzc(money) {
            this.money = money
        },
        index() {
            this.$router.push('/index')
        },
        shop(type) {
            if(type==3){
                this.newCurPrice = this.orderNum.productDetail.curPrice;
            }
            else if(type==5){
                this.handleRemainingBidding()
            }
            console.log('shop',type)
            this.shopnum = type
        },

        async editorfun() {
            this.activeType = 3
            this.litype = 1

        },
        // 修改名字
        async editorfuns() {
            this.fixname.nickName = this.userName
            await editor(this.fixname).then((res) => {
                if (!res) return this.$message.error('修改失败')
                if(res.message){
                    // this.$message.error('修改失败')
                    // this.$message.success('注册成功')
                    return
                }
                this.$store.commit(
                    'LoginValue',
                    {
                        name: this.fixname.nickName,//玩家名字
                        HeadPortrait: this.$store.state.HeadPortrait,//玩家头像
                        accountId: this.$store.state.accountId,//玩家ID
                        realName: this.$store.state.realName,//实名状态
                        phone: this.$store.state.phone//手机号
                    }
                )
                localStorage.setItem('name', this.fixname.nickName)//玩家名字
                
                // localStorage.setItem('avatarPath',this.fixname.)//玩家头像
                this.$message.success('修改成功!')
                setTimeout(() => {
                    location.reload()
                }, 2000);
                
                // this.$router.push('/PersonalCenter')
            })
        },
        // 修改密码
        async updatePassfun() {
            if (!this.inputPass) return this.$message.warning('请输入密码')
            if (!this.NewPassword.newPass) return this.$message.warning('请确认密码')
            if (this.inputPass.length < 6 || this.inputPass.length > 16) return this.$message.warning('密码长度必须在6-12位之间')
            if (this.inputPass !== this.NewPassword.newPass) {
                this.inputPass = ''
                this.NewPassword.newPass = ''
                return this.$message.warning('两次密码不相同，请重新输入')
            }
            // 密码加密
            this.NewPassword.newPass = encrypt(this.NewPassword.newPass)
            await updatePass(this.NewPassword).then((res) => {
                if (res) {
                    if(!res.message){
                        this.inputPass = ''
                        this.NewPassword.newPass = ''
                        this.$message.success('修改密码成功')
                    }
                }
            })
        },

        // 实名认证
        async idCertificationfun() {
            let name= /^([\u4e00-\u9fa5]{1,20}|[a-zA-Z\.\s]{1,20})$/
            let card = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
            let obj = JSON.parse(JSON.stringify(this.RealName))
            if (this.RealName.actualName < 1) return this.$message.warning('必须输入姓名')
            if (!name.test(this.RealName.actualName)) return this.$message.warning('姓名格式不正确')
            if (!card.test(this.RealName.idCard)) return this.$message.warning('身份证不正确！')
            obj.idCard = encrypt(obj.idCard)

            if(!obj.idCardFrontFileId){
                this.$message.warning('请上传身份证正面')
                return
            }
            if(!obj.idCardBackFileId){
                this.$message.warning('请上传身份证反面')
                return
            }
            await idCertification(obj).then((res) => {
                if (res) this.$message.success('提交信息成功！')
                setTimeout(() => {
                    location.reload()
                }, 2000);
            })
        },

        getToken() {
            this.headers.Authorization = localStorage.getItem('username')
        },
        // 查询所有商品
        async userfun() {
            await user().then((res) => {
                this.pageSize.createBy = res.content[0].createBy 
                this.AllOrders = res
                // 总页数
                this.sumPage = Math.ceil(res.totalElements / 5) * 10
            })
        },
        async biddingUserFun(){
            await biddingUser().then((res) => {
                this.biddingPageSize.createBy = res.content[0].createBy 
                this.AllBiddingOrders = res
                // 总页数
                this.biddingSumPage = Math.ceil(res.totalElements / 5) * 10
            })
        },
        // 页码发生改变时查询商品
        async currentPagefun(page, state) {
            if(page==undefined) page=1;
            if(state==undefined) state='';
            this.pageSize.productStatus = state
            this.pageSize.page = page - 1
            this.pageSize.sort = 'createTime,desc'
            await user(this.pageSize).then((res) => {
                this.AllOrders = res
            })
        },
        async currentBiddingPagefun(page, state){
            if(page==undefined) page=1;
            if(state==undefined) state='';
            this.biddingPageSize.productStatus = state
            this.biddingPageSize.page = page - 1
            this.biddingPageSize.sort = 'createTime,desc'
            await biddingUser(this.biddingPageSize).then((res) => {
                this.AllBiddingOrders = res
            })
        },
        async currentEstimatePagefun(page, state){
            if(page==undefined) page=1;
            if(state==undefined) state='';
            this.estimatePageSize.productStatus = state
            this.estimatePageSize.page = page - 1
            this.estimatePageSize.sort = 'createTime,desc'
            await estimateUser(this.estimatePageSize).then((res) => {
                this.AllEstimateOrders = res
                // 总页数
                this.estimateSumPage = Math.ceil(res.totalElements / 5) * 10
            })
        },
        // 查询所有订单
        async productfuns() {
            await order().then((res) => {
                // 总页数
                this.sumPages = Math.ceil(res.totalElements / 5) * 10
            })
        },
        async dfh(){
            let orderDatas = {
                page: 0,
                orderStauts: 2,
                size: 5,
            }
            await order(orderDatas).then((res) => {
                this.dhf = res.totalElements
            })
        },
        async dsh(){
            let orderDatas = {
                page: 0,
                orderStauts: 3,
                size: 5,
            }
            await order(orderDatas).then((res) => {
                this.dhs = res.totalElements
            })
        },
        async jywc(){
            let orderDatas = {
                page: 0,
                orderStauts: 4,
                size: 5,
            }
            await order(orderDatas).then((res) => {
                this.jywcl = res.totalElements
            })
        },
        // 账号交易
        async orderfun(page, state, litype) {
            this.litype = litype
            this.orderData.orderStauts = state
            this.litypes = state
            this.orderData.page = page - 1
            await order(this.orderData).then((res) => {
                this.sumPages = Math.ceil(res.totalElements / 5) * 10
                this.infolist = res.content
            })
        },
        async orderfuns(page, state, litype) {            
            if(state==undefined) state=this.litypes-1;
            if(litype==undefined) litype = this.litype;
            this.activeType = 1
            this.orderfun(page, state+1, litype)
        },
        // 订单详情 123qwe
        async detail(orderId, orderStauts) {
            this.litype = 5
            // statusnum
            let st = ['待付款', '待发货', '待收货', '交易完成', '交易取消','竞价领先','竞价被超','竞价成功未支付违约']
            let tok = ['宝贝还未付款', '您的宝贝已付款成功，请联系客服获取商品关键信息', '您的商品关键信息已由客服发放，请您确认无误后确认收货', '您的商品已经交易完成', '您的商品交易已经取消','','','']
            this.statustoke = tok[orderStauts-1]
            this.statusnum = st[orderStauts-1]
            this.orderStauts = orderStauts-1
            
            let orderid = { orderId }
            await orderDetail(orderid).then((res) => {
                this.orderNum = res
                // 商品主图
                this.orderpicture = res.productDetail.detailImagesPath[0]
                this.orderproductId = res.productDetail.productId
                this.orderType = res.orderType
                this.QrCode = res.qrCode
                // 标题
                this.ordertitle = res.productDetail.title
                // 内容
                this.ordercontent = res.productDetail.description
                // 时间
                this.ordertime = res.productDetail.createTime
                // 游戏
                this.ordergameName = res.productDetail.gameName
                // 区
                this.ordergameService = res.productDetail.gameService
                // 价格
                this.orderprice = res.productDetail.price
                if(this.orderType==2){
                    this.ordercurprice = res.productDetail.curPrice
                }
                else{
                    this.ordercurprice = res.productDetail.price
                }
                // 服务费
                this.serveprice = this.orderNum.actualPrice - this.orderprice
                    
                if(this.orderStauts==0){
                    // 待付款，但是超时20分钟
                    if(new Date().getTime() - new Date(res.createTime).getTime() > 20*60*1000){
                        this.orderStauts = 4;
                    }
                }
            })
        },
        // 商品详情 123qwer
        async succ(litype, orderType) {
            // productStatus 状态
            let type = { productId: litype.productId }
            this.litype = 6
            let fun;
            if(orderType=='bidding') fun = biddingGoodsDetail;
            else if(orderType=='estimate') fun = estimateGoodsDetail;
            else fun = goodsDetail;
            
            await fun(type).then((res) => {
                this.goodsNum = res
                let sm = ['', '待审核', '审核未通过', '待上架', '售卖中', '交易中', '已下架', '已出售']
                let sh = ['', '您的商品待审核', '很遗憾您的商品未通过平台审核，请您检查后重新尝试', '您的商品已成功通过平台审核，正在等待上架', '您的商品已成功上架，请耐心等待买主', '您的商品正在交易中，请耐心等待', '您的商品已下架，期待下次合作', '您的商品已成功上架，请耐心等待买主']
                this.statusnum = sm[this.goodsNum.productStatus]
                this.statustoke = sh[this.goodsNum.productStatus]
                this.fornum = this.goodsNum.gameProperties
            })
        },
        async infofun() {
            await info().then((res) => {
                this.txtx = res.user.isCertification
                if(!res.user.avatarPath){
					this.avatarPathaaa='https://picsum.photos/id/870/200/300?grayscale&blur=2'
				}else{
					this.avatarPathaaa=res.user.avatarPath
				}
            })
        },
        async wodeshangp(id, orderType) {
            this.zhuangtai = id
            let ids = { id }
            let queryRoute = '';
            if(orderType=='bidding') queryRoute = '/web/product/biddingProductStatus';
            else if(orderType=='estimate') queryRoute = '/web/product/estimateProductStatus';
            else queryRoute = '/web/product/productStatus';
            this.$axios({
                method: 'put',
                url: serverConfig.baseURL + queryRoute,
                data: ids,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: localStorage.getItem('username'),
                },
            })
                .then((response) => {
                    this.$message.success('状态修改成功!')
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                })
                .catch((error) => {
                    this.$message.error('提交失败' + error);
                });
        },
        async incomeExpenditurefun(type){
            
            if(!type){
                this.money = 1
                var types = 1
            }
            else{
                var types = type
                this.money = type
            }
            let num = {
                page:0,
                size:5,
                type:types
            }
            await incomeExpenditure(num).then((res) =>{
                this.income = res
            })
        },
        async getRealname() {
            await info().then((res) =>{
            })
        },
        openGoodDetail(){
            if(this.orderType==1){
                this.$router.push('./AccountDetails?flag=1&type=' + this.orderproductId)
            }
            else{
                this.$router.push('./BiddingDetails?flag=1&type=' + this.orderproductId)
            }
        },
        handleBidding(){
            this.$axios({
                method: 'post',
                url: serverConfig.baseURL + '/web/biddingProduct/bidding',
                data: {
                    id: this.orderNum.productDetail.productId,
                    price: this.newCurPrice
                },
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: localStorage.getItem('username'),
                },
            })
            .then((response) => {   
                let result = response.data;
                if(result.success==1){
                    this.$message.success('商品竞价成功！');
                    
                    if(result.qrCode==undefined){
                        this.shopnum = 0;
                    }
                    else{
                        // 等待支付
                        this.QrCode = result.qrCode;
                        this.orderId = result.orderId;
                        let orderInfo = [{title:'订单编号：',num:result.orderNo,type:1},
                                {title:'下单时间：',num:result.date,type:2},
                                {title:'保证金：',num:result.price,type:3}]
                        this.payPrice = result.price
                        this.ordernumber = orderInfo;
                        this.shopnum = 4
                        setTimeout(()=>{
                            this.loopWaitOrderFinish();
                        },1000)
                    }
                }
                else{
                    this.$message.error(result.message);
                }
            })
            .catch((error) => {
                // this.$message.error(error.);
                this.$message.error('提交失败' + error.response.data.message);
            });
        },
        loopWaitOrderFinish(){
            if(this.shopnum==0){
                return;
            }
            orderDetail({orderId:this.orderId}).then((res) => {
                console.log(res)
                if(res.orderStauts==1){// 待支付
                    setTimeout(()=>{
                        this.loopWaitOrderFinish();
                    },1000)
                }
                else{
                    this.$message.success('支付完成！');
                    setTimeout(() => {
                        this.shopnum = 0;
                    }, 2000)
                }
            })
        },        
        handleRemainingBidding(){
            this.$axios({
                method: 'post',
                url: serverConfig.baseURL + '/web/biddingProduct/remaining?productId='+this.orderNum.productDetail.productId,
                data: {
                },
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: localStorage.getItem('username'),
                },
            })
            .then((response) => {   
                let result = response.data;
                if(result.success==1){                    
                    if(result.qrCode==undefined){
                        this.shopnum = 0;
                    }
                    else{
                        this.remaining = {
                            qrCode: result.qrCode,
                            orderId: result.orderId,
                            orderNo: result.orderNo,
                            price: result.price,
                            createTime: result.date
                        }
                        
                        // // 等待支付
                        // this.QrCode = result.qrCode;
                        // this.orderId = result.orderId;
                        // let orderInfo = [{title:'订单编号：',num:result.orderNo,type:1},
                        //         {title:'下单时间：',num:result.date,type:2},
                        //         {title:'保证金：',num:result.price,type:3}]
                        // this.payPrice = result.price
                        // this.ordernumber = orderInfo;
                        console.log('this.remaining',this.remaining)
                        this.shopnum = 5
                        setTimeout(()=>{
                            this.loopWaitRemainingOrderFinish();
                        },1000)
                    }
                }
                else{
                    this.$message.error(result.message);
                }
            })
            .catch((error) => {
                // this.$message.error(error.);
                this.$message.error('提交失败' + error.response.data.message);
            });
        },
        loopWaitRemainingOrderFinish(){
            if(this.shopnum==0){
                return;
            }
            orderDetail({orderId:this.remaining.orderId}).then((res) => {
                console.log(res)
                if(res.remainingPayStatus==1){// 待支付
                    setTimeout(()=>{
                        this.loopWaitRemainingOrderFinish();
                    },1000)
                }
                else{
                    this.$message.success('支付完成！');
                    setTimeout(() => {
                        this.shopnum = 0;
                    }, 2000)
                }
            })
        },
    },
    data() {
        return {
            remaining: {},
            newCurPrice: '',
            ordercurprice: '',
            qqNum:'',
            zffs:['','支付宝','微信'],
            dhf:'',
            dhs:'',
            jywcl:'',
            zhanghuye:'',
            daisum:['','待转账','确认转账','提现完成'],
            daidai:['','联系客服','确认转账','联系客服'],
            tixiansum:'',
            tttt:undefined,
            income:'',
            jine:'',
            kah:'',
            mim:'',
            zhuangtai:'',
            touxiang: '',
            serverConfig: '',
            RealName: {
                "actualName": "",
                "idCard": "",
                "idCardBackFileId": 0,
                "idCardFrontFileId": 0
            },
            headers: {
                Authorization: ""
            },
            imageUrlFront: '',
            imageUrlFronts: '',
            deliverGoods: [
                { src: require('../../assets/daifahuo.svg'), title: '待发货', num: 0, type: 1 },
                { src: require('../../assets/daishouhuo.svg'), title: '待收货', num: 0, type: 2 },
                { src: require('../../assets/yiwancheng.svg'), title: '已完成', num: 0, type: 3 },
            ],
            shopnum: 0,
            show: 1,
            QrCode: '',
            list: [
                { title: '业务介绍', type: 1 },
                { title: '服务协议', type: 2 },
                { title: '隐私政策', type: 3 },
                { title: '投诉建议', type: 4 },
            ],
            infolist: [],
            infolistwo: [
                { title: 'LO222222', price: 500.00, num: 1, insurance: '无', RealPay: '500.00', operation: '查看进度', contact: '联系客服', type: 1 },
                { title: 'LO222222', price: 500.00, num: 1, insurance: '无', RealPay: '500.00', operation: '查看进度', contact: '联系客服', type: 2 },
                { title: 'LO222222', price: 500.00, num: 1, insurance: '无', RealPay: '500.00', operation: '查看进度', contact: '联系客服', type: 3 },
            ],
            infolisthree: [
                { title: 'LO333333', price: 500.00, num: 1, insurance: '无', RealPay: '500.00', operation: '查看进度', contact: '联系客服', type: 1 },
                { title: 'LO333333', price: 500.00, num: 1, insurance: '无', RealPay: '500.00', operation: '查看进度', contact: '联系客服', type: 2 },
                { title: 'LO333333', price: 500.00, num: 1, insurance: '无', RealPay: '500.00', operation: '查看进度', contact: '联系客服', type: 3 },
            ],

            activeType: 0,
            litype: 1,
            money: 1,
            // 修改名字
            fixname: {
                avatarId: '',
                nickName: "",
            },
            userName: '',
            // 输入新密码
            inputPass: '',
            // 确认新密码
            NewPassword: {
                newPass: '',
            },
            // 总页数
            sumPage: 0,
            sumPages: 0,
            // 分页查询
            pageSize: {
                page: 0,
                productStatus: '',
                createBy: '',
                size: 5,
                sort: 'createTime,desc'
            },
            // 总页数
            biddingSumPage: 0,
            biddingSumPages: 0,
            // 分页查询
            biddingPageSize: {
                page: 0,
                productStatus: '',
                createBy: '',
                size: 5,
                sort: 'createTime,desc'
            },
            // 总页数
            estimateSumPage: 0,
            estimateSumPages: 0,
            // 分页查询
            estimatePageSize: {
                page: 0,
                productStatus: '',
                createBy: '',
                size: 5,
                sort: 'createTime,desc'
            },
            // 我的商品-订单信息
            AllOrders: [],
            AllBuyOrders: [],
            AllBiddingOrders: [],
            AllEstimateOrders: [],
            txtx:0,
            // 商品状态
            goodsData: [
                { color: '', name: '' },
                { color: 'backcolor', name: '待审核' },
                { color: 'redcolor', name: '审核未通过' },
                { color: 'backcolor', name: '待上架' },
                { color: 'backcolor', name: '售卖中' },
                { color: 'backcolor', name: '交易中' },
                { color: 'backcolor', name: '已下架' },
                { color: 'greencolor', name: '已出售' },
            ],
            // 订单管理
            orderData: {
                page: 0,
                orderStauts: '',
                size: 5,
            },
            litypes: 1,
            // 订单详情的全部数据   123qwe
            orderNum: {},
            orderType: '',
            // 商品详情url
            orderproductId: '',
            // 商品主图
            orderpicture: '',
            // 标题
            ordertitle: '',
            avatarPathaaa:'',
            // 内容
            ordercontent: '',
            // 时间
            ordertime: '',
            // 游戏
            ordergameName: '',
            // 区
            ordergameService: '',
            // 价格
            orderprice: '',
            // 服务费
            serveprice: '',
            // 发货状态
            statusnum: '',
            // 发货句子
            statustoke: '',
            // 发货的状态码
            orderStauts: 0,
            // 按钮
            btnnum: '',
            // 商品详情  123qwer
            goodsNum: {},
            // 循环
            fornum: [],
            // 是否包赔
            ifpay: ['否', '是'],
            zhanghu:'',
            srzcs:['','收入','支出'],
      qrDialogVisible: false,
      serviceQrCode: '',
        }
    },
    computed: {
        getName() {
            return localStorage.getItem('name')
        },
        getHeat() {
            return localStorage.getItem('HeadPortrait')
        },
        getId() {
            return localStorage.getItem('accountId')
        },
        
        getPhone() {
            return localStorage.getItem('phone')
        },
    },
    mounted() {
        this.dfh()
        this.dsh()
        this.jywc()
        this.accountBalancefun()
        this.withdrawfun()
        this.zhanghu =localStorage.getItem('accountBalance')
        this.serverConfig = serverConfig
        this.userName = this.getName
        this.getToken()
        this.userfun()
        this.biddingUserFun()
        this.productfuns()
        this.currentPagefun()
        this.currentBiddingPagefun()
        this.currentEstimatePagefun()
        this.orderfun()
        this.infofun()
        this.incomeExpenditurefun()
        this.getRealname()
    }
}
</script>

<style lang="less" scoped>
.lianxikefu{
    text-decoration: none;
}
.queque{
    cursor: pointer;
        
        margin-left: 75px;
    text-align: center;
    button{
        width: 80px;
    padding: 2px 10px;
    background-color: #FFFFFF;
    border: 1px solid #a0a09c;
    border-radius: 8px;
    }
}
.backback {
    width: 110%;
    transform: translateX(-3%);
    // height: 30px;
    border-radius: 8px;
    background: #F6F6F6;
}

.masker {
    width: 100%;
    height: 110%;
    transform: translateY(-10%);
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9;



    .maskpay {
        position: relative;
        margin: 10% auto;
        width: 750px;
        height: 578px;
        background: #ffffff;
        border: 0.5px solid rgba(112, 112, 112, 0.54);
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
        display: flex;
        flex-direction: column;
        align-items: center;

        .chacha {
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 10px;
            width: 12px;
            height: 12px;
        }

        .alreadysucc {
            font-weight: bold;
            margin: 30px 0;
            font-size: 16px;
        }

        .masktime {
            color: #FF1D1D;
            font-size: 12px;
            margin-bottom: 30px;
        }

        img {
            width: 198px;
            height: 192px;
        }

        .pleace {
            font-size: 12px;
            margin-top: 10px;
        }

        .maskprice {
            color: #FF0000;
            font-size: 20px;
            font-weight: bold;
            margin: 10px 0;
        }

        .orderNumber {
            font-size: 12px;
            width: 200px;
            display: flex;
            justify-content: space-between;
        }
    }
}

.classnuminfo {
    display: flex;
    justify-content: space-between !important;

    img {
        width: 200px;
        height: 110px;
        border-radius: 8px;
        margin: 10px;
    }

    .godmaxright {
        display: flex;
        flex-direction: column;

        .goodsmaxones {
            font-size: 16px;
            margin-top: 10px;
            height: 24px;
            width: 650px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .goodsmaxtwos {
            font-size: 12px;
            margin-top: 10px;
            color: #949494;
        }

        .goodsmaxthrees {
            font-size: 12px;
            color: #949494;
        }

        .areaprice {
            display: flex;
            justify-content: space-between;

            .areapriceone {
                font-size: 12px;
            }

            .areapricetwo {
                font-size: 25px;
                font-weight: bold;
                color: #FB2706;
                transform: translateY(-20px);
                padding-right: 20px;
            }
        }
    }
}

.h_midmaxes {
    // width: 990px !important;
    div:nth-child(1){
        height: 35px;
    }
}

.h_mid {
    width: 100%;

    .h_midmax {
        display: flex;
        width: 1200px;
        margin: 0 auto;

        div:nth-child(1) {
            font-size: 20px;
            margin-right: 10px;
            transform: translateY(7px);
            cursor: pointer;
        }
    }
}

.blackBold {
    color: #333;
    font-weight: bold;
}

.bac {
    background-color: #F6F6F6 !important;
}

.backgro {
    background-color: #0076FF;
    color: #fff !important;
    font-weight: bold;
    font-size: 15px;
}

.fontcolor {
    color: #fff !important;
}

.floatright {
    img {
        float: right;
        width: 80px;
        height: 92px;
    }
}

.rightbutton {
    position: absolute;
    right: 0;
    bottom: 0;

    img {
        width: 80px;
        height: 92px;
    }
}

.uploadcard {
    font-size: 15px;
    color: #505050;
    margin-left: 40px;
    margin-top: 40px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
}

.sfzcard {
    display: flex;
    justify-content: space-between;
    width: 500px;
    height: 152px;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 40px;

    ::v-deep .el-upload {
        position: relative !important;
        width: 166px !important;
        height: 112px !important;
        border: 1px solid !important;
        border-radius: 8px !important;
    }

    ::v-deep .el-icon-plus:before {
        position: absolute !important;
        content: "\e6d9" !important;
        top: 45px !important;
        left: 76px !important;
        transform: translateX(-5px);
    }

    ::v-deep .el-upload__tip {
        transform: translateX(60px) !important;
    }

    ::v-deep .fan {
        transform: translateX(-50px);
    }

    ::v-deep .fans {
        transform: translateX(0px) !important;
    }

    img {
        width: 165px;
        height: 112px;
        border-radius: 8px;
    }
}

.heatphoto {
    display: flex;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;

    span:first-child {
        margin-left: 24px;
        margin-right: 10px;
    }

    span:last-child {
        width: 65px;
        height: 65px;
        overflow: hidden;
        border-radius: 50%;
        margin-left: 74px !important;

        ::v-deep .el-upload {
            border-radius: 50%;
            width: 65px;
            height: 65px;
            position: relative;

            ::v-deep .el-icon-plus:before {
                position: absolute !important;
                top: 18px !important;
                left: 19px !important;
            }
        }

        ::v-deep .el-upload-list__item {
            border-radius: 50%;
            width: 65px;
            height: 65px;
        }
    }

    img {
        border-radius: 50%;
        width: 70px;
        height: 70px;
    }
}

.PersonalCenter {
    .h_mid div:nth-child(1) {
        background-color: #fff;

        span {
            color: #333;
        }
    }

    .agreew span {
        cursor: pointer;
        transform: translateX(15px);
    }

    .companylist {
        display: flex;
        flex-direction: column;

        .numtrading {
            display: flex;
            width: 290px;
            height: 50px;

            i {
                margin-left: -12px;
            }

            span {
                color: #333333;
                font-size: 16px;
                transform: translate(-33px, -3px);
            }

            .arrow {
                transform: translateY(-3px);
            }
        }

        .numtrad {
            display: flex;
            width: 290px;
            height: 50px;

            i {
                margin-left: -12px;
            }

            span {
                color: #333;
                font-size: 16px;
                transform: translate(-33px, -3px);
            }

            .arrow {
                transform: translateY(-3px);
            }
        }
    }

    .numtradchild {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
        margin-top: 4px;

        li {
            list-style: none;
            width: 100%;
            height: 33px;

            span {
                margin-top: 4px;
                display: block;
                color: #919191;
                transform: translateX(10px);
                margin-left: 25px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            }
        }

        .checked {
            background: #B9ECFC;
            padding: 5px 0 5px 102px;
            transform: translateX(-53px);
            border-radius: 4px;

            span {
                color: #333333;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            }
        }
    }

    .companylist div {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 185px;
        height: 50px;
        padding-left: 105px;
        // border-radius: 8px;
    }

    .logofon {
        display: flex;
        margin: 10px 0 0 20px;
    }

    .logimgo {
        width: 50px;
        height: 60px;
    }

    .logimgt {
        width: 348px;
        height: 52px;
    }

    .logimgo img,
    .logimgt img {
        width: 100%;
        height: 100%;
    }

    .AboutUs {
        position: relative;
        margin: 10px 0 0 20px;
    }

    .navigation {
        position: absolute;
        top: 220px;
        right: 20px;
    }

    .abs {
        position: absolute;
        top: 39px;
        left: 214px;
    }

    .abss {
        position: absolute;
        bottom: 380px;
        left: 80px;
    }

    .server {
        display: flex;
    }

    .servers {
        display: flex;
        flex-direction: column;
        width: 270px;
        height: 350px;
        margin-right: 10px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.16);
    }

    .servers div {
        width: 84px;
        height: 88px;
        margin: 40px auto;
    }

    .servers span {
        display: block;
        font-size: 20px;
        font-weight: bold;
        margin: 20px auto;
    }

    .servers span:last-child {
        margin-top: 40px;
    }

    .servers button {
        width: 120px;
        height: 40px;
        background: #0076FF;
        border-radius: 8px;
        margin: 28px auto;
        border: none;
        color: #fff;
    }

    .servers div img {
        width: 100%;
        height: 100%;
    }

    .introduce {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 10px 20px;
        margin-left: 20px;

        :v-deep .el-pagination {
            margin: 0 auto;
        }

        .alonecenter {
            width: 106%;
            transform: translate(-20px, -10px);
            height: 53%;
            background-color: #F6F6F6;
            display: flex;
            flex-direction: column;

            .alonetop {
                width: 941px;
                display: flex;
                justify-content: space-between;

                .aloneleft,
                .aloneright {
                    width: 465px;
                    height: 160px;
                    background-color: #fff;
                    border-radius: 8px;
                }

                .aloneleft {
                    display: flex;
                    flex-direction: column;

                    .lefttop {
                        display: flex;
                        margin-left: 44px;
                        margin-top: 30px;

                        img {
                            border-radius: 50%;
                            width: 70px;
                            height: 70px;
                            margin-right: 41px;
                        }

                        .wan {
                            display: flex;
                            flex-direction: column;
                            margin-top: -20px;

                            .edito {
                                cursor: pointer;
                            }

                            .wanone {
                                font-size: 20px;
                                font-weight: bold;
                                margin-top: 10px;
                                font-family: Microsoft YaHei, Microsoft YaHei-Bold;
                            }

                            .wantwo {
                                font-size: 12px;
                                color: #919191;
                                margin: 3px 0;
                                margin: 10px 0;
                                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                            }

                            button {
                                width: 75px;
                                height: 30px;
                                background-color: #0076FF;
                                color: #fff;
                                font-size: 14px;
                                border: none;
                                border-radius: 8px;
                            }
                        }
                    }

                    .leftbot {
                        margin-top: 15px;
                        margin-left: 44px !important;
                        font-size: 12px;
                        margin-left: 10px;
                        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                    }
                }

                .aloneright {
                    display: flex;
                    flex-direction: column;

                    .mybag {
                        font-size: 20px;
                        font-weight: bold;
                        margin-left: 10px;
                        margin-top: 20px;
                        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
                    }

                    .usemoney {
                        display: flex;
                        flex-direction: column;
                        width: 96%;
                        margin-left: 2%;
                        margin-top: 10px;
                        height: 90px;
                        border-radius: 8px;
                        background-color: #F6F6F6;

                        .moneyone {
                            font-size: 14px;
                            margin: 10px 0 0 10px;
                            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                        }

                        .moneytwo {
                            font-size: 30px;
                            font-weight: bold;
                            color: #FB0606;
                            margin-left: 180px;
                        }
                    }
                }
            }

            .alonebut {
                width: 941px;
                height: 140px;
                background-color: #fff;
                margin-top: 20px;
                border-radius: 8px 8px 0 0;

                .buttop {
                    font-size: 20px;
                    font-weight: bold;
                    margin: 10px;
                    transform: translateY(-6px);
                    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
                }

                .butall {
                    display: flex;

                    .buttopone {
                        border-radius: 8px;
                        display: flex;
                        width: 300px;
                        height: 80px;
                        margin-left: 10px;
                        background-color: #F6F6F6;

                        img {
                            width: 43px;
                            height: 41px;
                            margin: 16px;
                        }

                        .everyone {
                            display: flex;
                            flex-direction: column;
                            font-size: 12px;
                            margin-top: 16px;

                            span {
                                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                            }

                            .onelast {
                                margin-top: 10px;
                            }
                        }

                        button {
                            margin: 20px 0px 0 87px;
                            width: 55px;
                            height: 30px;
                            border-radius: 8px;
                            color: #fff;
                            background-color: #0076FF;
                            border: none;
                        }

                    }
                }
            }
        }

        .page {
            position: absolute;
            bottom: 0;
        }

        .send {
            display: flex;

            span {
                cursor: pointer;
                width: 70px;
                height: 40px;
                color: #333;
                text-align: center;
                line-height: 40px;
                margin: 10px 20px;
                font-size: 15px;
            }
        }

        .deal {
            display: flex;
            background-color: #F8F8F8;
            height: 51px;
            width: 100%;
            line-height: 51px;

            span {
                margin-left: 40px;
            }
            
            span:nth-child(2) {
                width: 100px;
            }

            .dealright {
                margin-left: 20px;
                margin-right: 270px;
            }

            .clothing {
                margin-left: 147px;
            }

            .pricege {
                margin-left: 68px;
            }

            .state {
                margin-left: 36px;
            }

            .operation {
                margin-left: 92px;
            }
        }

        .info {
            display: flex;
            height: 51px;
            width: 100%;
            line-height: 51px;

            span {
                font-size: 15px;
            }

            span:nth-child(2) {
                width: 140px;
                text-align: center;
                margin-left: 40px;
            }

            span:nth-child(3) {
                width: 50px;
                text-align: center;
                transform: translateX(-10px);
                margin-left: 26px;
            }

            span:nth-child(4) {
                width: 50px;
                text-align: center;
                margin-left: 22px;
            }

            span:nth-child(5) {
                width: 100px;
                text-align: center;
            }

            span:nth-child(6) {
                width: 100px;
                text-align: center;
                transform: translateX(-4px);
            }

            .inforight {
                margin-left: 20px;
                width: 230px;
                margin-right: -15px;
            }

            .look {
                color: #0076FF;
                cursor: pointer;
            }

            .contact {
                color: #959595;
                cursor: pointer;
            }
        }

        .CheckSchedule {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            border-radius: 8px;
            background-color: #fff;
            border-radius: 8px;

            .checktop {
                display: flex;
                align-items: center;
                height: 20px;
                padding-bottom: 15px;
                justify-content: space-between;
                border-bottom: 1px solid #D0D0D0;
                width: 100%;
                transform: translateY(10px);

                span {
                    font-size: 20px;
                    font-weight: bold;
                    margin-left: 20px;
                    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
                }

                .contentpeop {
                    cursor: pointer;
                    margin: 10px 0;
                    width: 120px;
                    height: 35px;
                    border: 1px solid #FFD09D;
                    background-color: #fff;
                    color: #FFAB53;
                    margin-right: 10px;
                    font-size: 15px;
                }
            }

            .serialNumber {
                margin-top: 20px;
                margin-left: 20px;

                span {
                    color: #505050;
                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                }
            }

            .ToAudit {
                display: flex;
                justify-content: space-between;
                width: 872px;
                height: 110px;
                border: 1px solid #BDBDBD;
                margin-left: 20px;
                margin-top: 15px;
                border-radius: 8px;

                .auditleft {
                    display: flex;
                    flex-direction: column;
                    margin-top: 11px;
                    margin-left: 15px;

                    span {
                        margin-left: 15px;
                        font-family: Microsoft YaHei, Microsoft YaHei-Regular;

                    }
                }

                .auditleft span:first-child {
                    font-weight: bold;
                    font-size: 25px;
                    margin-bottom: 15px;
                }

                .auditleft span:last-child {
                    font-size: 14px;
                }

                .auditright {
                    display: flex;
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                }

                .auditright div {
                    width: 89px;
                    height: 107px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .auditright span {
                    line-height: 107px;
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }

        .priceserve {
            margin-top: 20px;
            background-color: #fff;
            border-radius: 8px;
            padding-bottom: 20px;

            .price {
                padding: 35px 0 20px 0;
                display: flex;
                border-bottom: 1px solid #DCDCDC;
                height: 0px;

                span {
                    transform: translateY(-20px);
                    margin-left: 20px;
                    font-weight: bold;
                    font-size: 20px;
                    color: #333;
                    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
                }
            }

            .Withdrawal {
                display: flex;
                margin-left: 20px;
                margin-top: 29px;
                margin-bottom: 25px;
                .tixianjil{
                    background-color: #0076FF;
                    text-align: center;
                    color: #fff;
                    font-weight: bold;
                }
                li {
                    list-style: none;
                    cursor: pointer;
                    color: #939393;
                    width: 70px;
                    height: 20px;
                    margin-right: 34px;
                    padding: 4px;
                }
            }
            .Withdrawal li:nth-child(1){
                margin-right: 10px !important;
            }
            .withdrawalType {
                display: flex;
                width: 100%;
                height: 51px;
                background-color: #f8f8f8;

                li {
                    list-style: none;
                    line-height: 51px;
                }

                li:nth-child(1) {
                    margin-left: 25px;
                }

                li:nth-child(2) {
                    margin-left: 155px;
                }

                li:nth-child(3) {
                    margin-left: 137px;
                }

                li:nth-child(4) {
                    margin-left: 100px;
                }

                li:nth-child(5) {
                    margin-left: 100px;
                }
            }

            .withdrawalTypelist {
                display: flex;
                width: 100%;
                height: 51px;

                li {
                    list-style: none;
                    line-height: 51px;
                    color: #919191;
                }

                li:nth-child(1) {
                    margin-left: 25px;
                }

                li:nth-child(2) {
                    margin-left: 90px;
                }

                li:nth-child(3) {
                    margin-left: 90px;
                    width: 80px;
                    
                }

                li:nth-child(4) {
                    margin-left: 65px;
                    width: 70px;
                    text-align: right;
                }

                li:nth-child(5) {
                    margin-left: 75px;
                    width: 70px;
                    text-align: center;
                }
                .lijizixun{
                    color: #919191;
                }
            }

            .goodsmax {
                display: flex;
                flex-direction: column;

                .yes {
                    cursor: pointer;
                    width: 120px;
                    height: 34px;
                    background: #0076FF;
                    border-radius: 8px;
                    border: none;
                    margin-left: 100px;
                    margin-top: 20px;
                    color: #fff;
                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                }

                .maologo {
                    float: right;
                }

                .goodsprice {
                    margin-top: 20px;
                    margin-left: 40px;
                    display: flex;

                    .goodprice {
                        font-size: 15px;
                        margin-top: 3px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;

                        span:first-child {
                            color: #FA2A2A;
                            font-weight: bold;
                            font-size: 20px;
                        }

                        span:last-child {
                            font-size: 18px;
                            color: #919191;
                            // margin-top: 10px;
                        }
                    }
                }

                .goodsnum {
                    margin-top: 20px;
                    margin-left: 40px;
                    font-size: 15px;

                    span {
                        font-size: 15px;
                    }

                    span:last-child {
                        margin-left: 10px;
                    }
                }

                .compensation {
                    margin-top: 20px;
                    margin-left: 40px;
                    .zhifb{
                        margin-right: 15px;
                    }
                    img {
                        width: 128px;
                        height: 88px;
                        border-radius: 8px;
                    }

                    .input {
                        border: 1px solid #BABABA;
                        width: 75px;
                        height: 21px;
                        text-align: center;
                    }

                    .inputwo,
                    .inputhree {
                        border: 1px solid #BABABA;
                        width: 162px;
                        height: 30px;
                        padding-left: 30px;
                    }

                    span {
                        font-size: 15px;
                        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                    }

                    span:last-child {
                        margin-left: 10px;
                    }
                }

                .mainphoto {
                    display: flex;
                    margin-left: 40px;
                    margin-top: 20px;

                    span {
                        img {
                            width: 160px;
                            height: 85px;
                            border-radius: 8px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        .ann {
            width: 100%;

            button {
                width: 327px;
                height: 60px;
                background: #0076FF;
                border-radius: 8px;
                color: #fff;
                border: none;
                margin-left: 35%;
                margin-top: 30px;
            }
        }

        .myBalance {
            display: flex;
            flex-direction: column;

            .myBalancebac {
                position: relative;
                width: 900px;
                height: 260px;

                .myBalan {
                    position: absolute;
                    top: 10px;
                    left: 15px;
                    font-size: 20px;
                    color: #fff;
                    font-weight: bold;
                }

                .thisBalance,
                .sumBalance,
                .sumWithdrawal {
                    display: flex;
                    flex-direction: column;

                    span {
                        position: absolute;
                    }

                    span:first-child {
                        font-size: 15px;
                        color: #fff;
                    }

                    span:last-child {
                        font-size: 25px;
                        color: #fff;
                        font-weight: bold;
                    }
                }

                .thisBalance {
                    span:first-child {
                        top: 41px;
                        left: 405px;
                    }

                    span:last-child {
                        top: 66px;
                        text-align: center;
                        width: 300px;
                        left: 284px;
                        font-size: 30px;
                    }
                }
                
                .sumBalance {
                    span:first-child {
                        top: 151px;
                        left: 338px;
                    }

                    span:last-child {
                        left: 146px;
    top: 173px;
    width: 260px;
    text-align: right;
                    }
                }

                .sumline {
                    position: absolute;
                    top: 151px;
                    left: 448px;
                    width: 3px;
                    height: 58px;
                    background-color: #ffffff;
                }

                .sumWithdrawal {
                    span:first-child {
                        top: 151px;
                        left: 502px;
                    }

                    span:last-child {
                        left: 512px;
                        top: 173px;
                        width: 360px;
                        text-align: left;
                    }
                }

                button {
                    width: 64px;
                    height: 30px;
                    background: #ffffff;
                    border-radius: 4px;
                    border: none;
                    position: absolute;
                    top: 106px;
                    left: 418px;
                }
            }

            .IncomeSpending {
                width: 100%;
                padding: 10px 0;
                display: flex;
                flex-direction: column;

                .incomeSpending {
                    display: flex;
                    font-size: 20px;
                    font-weight: bold;
                    color: #919191;
                    margin: 0 auto;
                    span{
                        cursor: pointer;
                    }
                    span:first-child {
                        margin-right: 33px;
                    }

                    span:last-child {
                        margin-left: 20px;
                    }
                }

                .Spendingline {
                    display: flex;
                    margin: 0 auto;

                    i {
                        width: 57px;
                        height: 6px;
                        background: #0076FF;
                    }

                    i:first-child {
                        margin-right: 92px;
                    }

                    i:last-child {
                        margin-left: 100px;
                    }
                }
            }

            .OrderType {
                display: flex;
                width: 900px;
                height: 51px;
                background: #f8f8f8;

                li {
                    list-style: none;
                    line-height: 51px;
                }

                li:nth-child(1) {
                    margin-left: 25px;
                }

                li:nth-child(2) {
                    margin-left: 185px;
                }

                li:nth-child(3) {
                    margin-left: 115px;
                }

                li:nth-child(4) {
                    margin-left: 90px;
                }

                li:nth-child(5) {
                    margin-left: 90px;
                }
            }
            .orderfola{
                display: flex;
                flex-direction: column;
                max-height: 200px;
                overflow-y: auto;
            }
            .OrderDetails {
                display: flex;
                width: 900px;
                height: 51px;

                li {
                    list-style: none;
                    line-height: 51px;
                }

                li:nth-child(1) {
                    margin-left: 25px;
                }

                li:nth-child(2) {
                        margin-left: 91px;
                        text-align: right;
                        width: 200px;
                }

                li:nth-child(3) {
                    width: 180px;
                    margin-left: 36px;
                    text-align: right;
                }

                li:nth-child(4) {
                        margin-left: 5px;
                    width: 100px;
                    text-align: right;
                }

                li:nth-child(5) {
                    margin-left: 50px;
    width: 100px;
    text-align: right;
                }

                .OrderDetails:last-child {
                    margin-bottom: 20px;
                }
            }
        }

        .audit {
            margin-top: 20px;
            padding: 20px 0;
            display: flex;
            border-radius: 8px;
            background-color: #fff;

            span {
                font-size: 20px;
                font-weight: bold;
            }

            span:first-child {
                margin-left: 40px;
                color: #919191;
            }

            span:last-child {
                color: #333333;
            }
        }
    }

    .goodsMax {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 120px;
        background: #ffffff;
        border: 0.3px solid #BEBEBE;
        border-top: unset;
        border-radius: 4px;
        margin-top: 20px;

        .maxtop {
            background-color: #F8F8F8;
            margin-left: 20px;

            span:nth-child(2) {
                margin-left: 20px;
            }


        }

        .maxbut {
            display: flex;
            align-items: center;
            position: relative;
            img {
                width: 120px;
                height: 80px;
                transform: translate(10px, 10px);
            }

            .butpto {
                margin-left: 10px;
            }

            // 100 85 40  70 40
            .butnum {
                display: flex;

                span:nth-child(1) {
                    margin-left: 20px;
                }

                span:nth-child(2) {
                    margin-left: 10px;
                }

                span:nth-child(3) {
                    margin-left: 20px;
                }

                span:nth-child(4) {
                    margin-left: 20px;
                }

                .blackcolor {
                    color: #000000;
                }

                .redcolor {
                    color: #F60404;
                }

                .greencolor {
                    color: #068D33;
                }
            }

            .doublebtn {
                position: absolute;
                right: 10px;
                top: 10px;
                display: flex;
                flex-direction: column;
                margin-left: 56px;

                button {
                    width: 115px;
                    height: 35px;
                    background: #0076FF;
                    border: none;
                    border-radius: 4px;
                    color: #fff;
                }
                button:nth-child(2) {
                    background: #ffffff;
                    color: #333;
                    border: 0.3px solid #333333;
                    margin-top: 10px;
                }
                button:last-child {
                    background: #ffffff;
                    color: #333;
                    border: 0.3px solid #333333;
                    margin-top: 10px;
                }
            }
        }
    }

    .orderinfomations {
        margin-right: 180px !important;
    }

    .jibenxinxi {
        margin-top: 5px !important;
    }

    .jibenxinxitwo {
        padding-bottom: 25px !important;

        span {
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        }
    }

    .xiugaimima {
        margin-top: 11px !important;

        .price {
            padding-bottom: 19px !important;
        }
    }
}

.goPay {
                    cursor: pointer;
                    width: 120px;
                    height: 35px;
                    border: 1px solid #FFD09D;
                    background-color: #fff;
                    color: #FFAB53;
                    font-size: 15px;
                }
</style>
