// @Author: ygq

// 引入路由
import VueRouter from 'vue-router'
// 引入组件
import IndexMax from '../components/IndexMax.vue'
import LoginPage from '../components/Login/LoginPage.vue'
import AgreeOur from '../components/agree/AgreeOur.vue'
import GameList from '../components/gamelist/GameList.vue'
import Bidding from '../components/Bidding/index.vue'
import BiddingGoods from '../components/details/BiddingGoods.vue'
import AgencyMortgage from '../components/AgencyMortgage/AgencyMortgage.vue'
import TransactionSecurity from '../components/TransactionSecurity/TransactionSecurity.vue'
import TradingInformation from '../components/TradingInformation/TradingInformation.vue'
import AccountTransactions from '../components/AccountTransactions/AccountTransactions.vue'
import TwentySeven from '../components/details/TwentySeven.vue'
import AccountDetails from '../components/details/AccountDetails.vue'
import BiddingDetails from '../components/details/BiddingDetails.vue'
import PersonalCenter from '../components/PersonalCenter/PersonalCenter.vue'
import HeroAlliance from '../components/HeroAlliance/HeroAlliance.vue'
import HeroEstimate from '../components/HeroAlliance/HeroEstimate.vue'


// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
    mode: 'history',
    routes:[
        {path:'/',redirect:'/index'},
        {path:'/login',component:LoginPage},
        {path:'/index',component:IndexMax},
        {path:'/AgreeOur',component:AgreeOur},
        {path:'/GameList',component:GameList},
        {path:'/Bidding',component:Bidding},
        {path:'/BiddingGoods',component:BiddingGoods},
        {path:'/AgencyMortgage',component:AgencyMortgage},
        {path:'/TransactionSecurity',component:TransactionSecurity},
        {path:'/TradingInformation',component:TradingInformation},
        {path:'/AccountTransactions',component:AccountTransactions},
        {path:'/TwentySeven',component:TwentySeven},
        {path:'/AccountDetails',component:AccountDetails},
        {path:'/BiddingDetails',component:BiddingDetails},        
        {path:'/PersonalCenter',component:PersonalCenter},
        {path:'/HeroAlliance',component:HeroAlliance},
        {path:'/HeroEstimate',component:HeroEstimate}        
    ]
})