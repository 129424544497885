<!--
 * @Author: ygq
-->
<template>
  <div id="app">
    <div class="navi">
      <div class="cat">
        <img
          src="../assets/gmlogo.png"
          alt=""
        />
      </div>
      <div>快捷导航</div>
      <div class="navlist">
        <div class="pic"><img src="../assets/路径 5.svg" alt="" /></div>
        <span>下载APP</span>
      </div>
      <div class="navlist" @click="contact(1)">
      <a class="lianxikefu"
          target="_blank"
          @click="serviceQqfun"
          >
        <div class="pic"><img src="../assets/路径 6.svg" alt="" class="lianxikefu" /></div>
        <span>联系客服</span>
        </a>
      </div>
      <div class="navlist" @click="TransactionSecurity()">
        <div class="pic"><img src="../assets/路径 7.svg" alt="" /></div>
        <span>售后案例</span>
      </div>
      <div class="navlist" @click="TradingInformation()">
        <div class="pic"><img src="../assets/组 2.svg" alt="" /></div>
        <span>业务介绍</span>
      </div>
      <div class="navlist" @click="account()">
        <div class="pic"><img src="../assets/路径 141.svg" alt="" /></div>
        <span>我要卖号</span>
      </div>
      <div class="navlist" @click="all()">
        <div class="pic"><img src="../assets/路径 140.svg" alt="" /></div>
        <span>全部游戏</span>
      </div>
      <div class="navlist" @click="agree()">
        <div class="pic"><img src="../assets/路径 15.svg" alt="" /></div>
        <span>关于我们</span>
      </div>
      <div class="navlist" ref="backtop" @click="backtop()">
        <div class="pic"><img src="../assets/路径 139.svg" alt="" /></div>
        <span>回到顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NaviGation',
  components: {},
  methods: {
    historyClick() {
      window.history.back(-1);
    },
    async serviceQqfun(){
      this.$parent.showCustomerServiceQrCode()
    },
    contact(num) {
      this.num = num;
    },
    backtop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    agree() {
      this.$router.push('/AgreeOur');
    },
    details() {
      this.$router.push('./TwentySeven');
    },
    protect() {
      this.$router.push('/AgencyMortgage');
    },
    TransactionSecurity() {
      this.$router.push('/TransactionSecurity');
    },
    TradingInformation() {
      this.$router.push('/TradingInformation');
    },
    account() {
      this.$router.push('/AccountTransactions');
    },
    bidding() {
      this.$router.push('/Bidding')
    },
    all() {
      this.$router.push('/GameList');
    },
  },
  mounted() {
  },
  data() {
    return {
      dialogVisible:true,
      num: 0,
      radio: 3,
      numnum:''
    };
  },
};
</script>
<style scoped>
.lianxikefu{
  color: #9797B2;
  text-decoration: none;
}

.lianxikefu{
  margin-left: 7px;
}

* {
  margin: 0;
  padding: 0;
}

.navi {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  right: 100px;
  display: flex;
  flex-direction: column;
  width: 85px;
  height: 682px;
  border-radius: 8px;
  margin-bottom: 100px !important;
}

.cat {
  width: 49px;
  height: 49px;
  margin: 7px 15px 0 21px;
}

.cat img {
  width: 100%;
  height: 100%;
}

.navi div:nth-child(2) {
  width: 85px;
  height: 32px;
  background: #0076FF;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 32px;
}

.navlist {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.pic {
  width: 27.89px;
  height: 27.89px;
}

.pic img {
  width: 100%;
  height: 100%;
}

.navlist span {
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
  color: #979797;
  margin-bottom: 10px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
}

/* @media screen and (max-width: 386px) {
  .navi {
    display: none;
  }
} */

</style>