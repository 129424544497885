<!--
 * @Author: ygq
-->
<template>
	<div id="app">
		<!-- 头部 -->
		<div class="heat">
			<div class="Logo">
				<img src="../assets/logo.svg" alt="">
			</div>
			<div class="inputsearch">

				<el-select v-model="value"  @change="itemvalue" filterable placeholder="选择游戏" class="choose">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
						class="chooses">
					</el-option>
				</el-select>
				<input type="text" v-model="contenttext" class="feedin">
				<button @click="sousuo">搜索</button>

			</div>

			<div v-show="exitnumber === 3" class="xiazai">
				<div class="downloadDiv">
					<div>下载APP</div>
					<div class="imgDown">
						<span>扫一扫下载app</span>
						<div id="qrcode" ref="qrcode">
							<!-- <img src="https://www.mmds8.com/api/file/%E5%9B%BE%E7%89%87/20220905166237785162856100.png" alt=""> -->
						</div>
					</div>
				</div>
				<button @click="login()">注册/登录</button>

			</div>

			<div class="loginafter" v-show="exitnumber === 2">
				<div @click="PersonalCenter()">
					<img :src="avatarPathaaa" alt="">
				</div>
				<p>
					<span class="name" @click="PersonalCenter()">{{ this.username }}</span>
					<span class="exit" @click="exit(3)">[退出]</span>
				</p>
			</div>

		</div>
	</div>
</template>

<script>
import { exitLogin,game,info } from '@/request/api'

export default {
	name: 'IndexHeat',
	components: {

	},
	methods: {
		async itemvalue(){
			this.gamePlatform = this.value
			this.photosrc = this.options[this.value-1].iconPath
			this.namen = this.options[this.value-1].label
        },
		PersonalCenter() {
			this.$router.push('./PersonalCenter')
		},
		async exit(type) {
			this.exitnumber = type
			this.$message.success('退出登录成功！')
			await exitLogin().then(() => {
				setTimeout(() => {
					this.$router.push('./login')
				}, 1000)
			})
			localStorage.removeItem('username')
			localStorage.removeItem('accountId')
			localStorage.removeItem('phone')
			localStorage.removeItem('realName')
			localStorage.removeItem('HeadPortrait')
			localStorage.removeItem('name')
		},
		login() {
			this.$router.push('./login')
		},
		// 获取 token 判断是否登录
		getToken() {
			if (localStorage.getItem('username')) {
				this.exitnumber = 2
				if (!this.userheat) {
					this.userheats = 'https://p1.ssl.qhimgs1.com/sdr/400__/t01b4efa4b32349258b.jpg'
				} else {
					this.userheats = this.userheat
				}
			}
		},
		async jumps() {
			await game().then((res) => {
				for (let i = 0; i < res.length; i++) {
					this.options.push({value: res[i].gameId,label: res[i].gameName,iconPath:res[i].iconPath})
				}
			})
		},
		async sousuo(){
			if(!this.gamePlatform) return this.$message.error('请选择游戏')
			let types ={
                gameName:this.namen,
                iconPath:this.photosrc,
                gameId:this.gamePlatform,
				title:this.contenttext
            }
			localStorage.setItem('TZgameName',types.gameName)
            localStorage.setItem('TZiconPath',types.iconPath)
            localStorage.setItem('TZgameId',types.gameId)
			localStorage.setItem('TZgameTitle',types.title)
			this.$router.push({
                path:'/TwentySeven',
                query:{types}
            })
		},
		async infofun() {
			if(localStorage.getItem('username')){
				await info().then((res) => {
				if(!res.user.avatarPath){
					this.avatarPathaaa='https://picsum.photos/id/870/200/300?grayscale&blur=2'
				}else{
					this.avatarPathaaa=res.user.avatarPath
				}
                
                // localStorage.setItem('HeadPortrait', res.user.avatarPath)
                
            })
			}
        },
	},
	computed: {
		username() {
			return localStorage.getItem('name')
		},
		userheat() {
			return localStorage.getItem('HeadPortrait')
			
		}
	},
	mounted() {
		this.getToken()
		this.jumps()
		this.infofun()
	},
	data() {
		return {
			// maomitoux:[require('maomitouxing.jpg')],
			avatarPathaaa:'',
			contenttext:'',
			gamePlatform:'',
			photosrc:'',
			namen:'',
			options: [],
			value: '',
			exitnumber: 3,
			userheats: '',
		}
	}
}
</script>

<style lang="less">
.heat {
	display: flex;
	width: 1200px;
	margin: 10px auto;
	justify-content: space-between;
	padding-bottom: 10px;
}

.choose {
	width: 100px;
}

.feedin {
	position: absolute;
	left: 100px;
	top: 0;
	width: 299px !important;
	outline: none;
}

.Logo {
	display: flex;
}

.Logo img {
	margin-top: 6px;
	width: 189px;
	height: 50px;
}

.Logo span {
	width: 194px;
	height: 39px;
	font-weight: 900;
	font-size: 27px;
	margin-top: 11px;
	margin-left: 10px;
}

.inputsearch {
	position: relative;
	display: flex;
	width: 483px;
	height: 40px;
	border-radius: 8px;
	border: 2px solid #0076FF;
	margin-top: 5px;
}

.inputsearch input {
	border: none;
	width: 483px;
	height: 39px;
}

.inputsearch button {
	cursor: pointer;
	position: absolute;
	right: 0;
	border: none;
	background-color: #0076FF;
	width: 84px;
	height: 43px;
	font-family: Microsoft YaHei, Microsoft YaHei-Regular;
	border-radius: 0 8px 8px 0;
	transform: translate(1px, -1px);
	color: #FCF8F8;
}

.xiazai {
	display: flex;
	margin-top: 7px;
	color: #fff;
	font-size: 11px;
	margin-left: -11px;
}

.xiazai button {
	border: none;
	width: 96px;
	height: 38px;
	text-align: center;
	line-height: 38px;
	border-radius: 8px;
	font-family: Microsoft YaHei, Microsoft YaHei-Bold;
}

.xiazai .downloadDiv {
	background-color: #0076FF;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	border: none;
	width: 96px;
	height: 38px;
	text-align: center;
	line-height: 38px;
	border-radius: 8px;
	font-family: Microsoft YaHei, Microsoft YaHei-Bold;
	position: relative;
}
.imgDown{
	display: none;
}
.xiazai .downloadDiv:hover .imgDown{
	display: block;
	position: absolute;
	z-index: 99;
	left: 70%;
	top: 0;
	transform: translate(-80px,25px);
	width: 178px;
	height: 213px;
	background: url('../assets/appbg.png') no-repeat;
	background-size: 100% 100%;
	font-size: 14px;
	color: #333333;
	text-align: center;
	font-weight: normal;
	span{
		display: inline-block;
		line-height: 80px;
		height: 60px;
	}
	#qrcode{
		width: 120px;
		height: 120px;
		margin: 0 auto;
		img{
			width: 100%;
			height: 100%;
		}
	}
}

.xiazai button:nth-child(2) {
	transform: translateX(15px);
	background-color: #eee;
	color: #333;
	font-size: 12px;
	font-weight: bold;

}

.loginafter {
	display: flex;
	align-items: center;

	div {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		width: 40px;
		height: 40px;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			cursor: pointer;
		}
	}

	p {
		display: flex;
		cursor: pointer;

		.name {
			color: #333;
			font-weight: bold;
			opacity: 0.8;
			font-size: 15px;
			transform: translateY(4px);
		}

		.exit {
			color: #919191;
			font-size: 15px;
			transform: translateY(4px);
		}
	}
}
</style>
