// @Author: ygq
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import store from './store'
import md5 from 'js-md5'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.prototype.$md5 = md5
Vue.prototype.$axios = axios
Vue.prototype.$store = store

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
