<!--
 * @Author: ygq
-->

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    }
}
</script>

<style>
body{
  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
.fill:first-child{
  padding-bottom: 10px !important;
}

.pot{
  margin-top: 30px;
}

.pot img{
  width: 135px !important;
  height: 45px !important;
}

.el-carousel img{
  width: 30px!important;
  height: 30px!important;
}
.el-carousel .elcorImages{
  width: 100%!important;
  height: 100%!important;
}
.navphoto .navparent img{
  width: 100%!important;
  height: 100%!important;
}
.el-step__head.is-finish{
    color: #FFD000!important;
    border-color: #FFD000!important;
}
.el-step__title.is-finish{
  color: #333333!important;
}
</style>
