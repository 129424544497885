// @Author: ygq

import axios from "axios"
import serverConfig from "./config"
import { Message } from 'element-ui'
// import Vue from 'vue'
// import App from './App.vue'
// import VueRouter from 'vue-router'
import router from '../router/router.js'

// 创建 axios 请求实例
const serviceAxios = axios.create({
    baseURL: serverConfig.baseURL, //请求地址
    timeout: 10000, //请求超时设置
    withCredentials: true //跨域请求是否需要携带 token
})

// 创建请求拦截
serviceAxios.interceptors.request.use(
    (config) => {
        // 如果开启 token 认证
        if (serverConfig.useTokenAuthorization) {
            // 请求头携带 token
            // config.headers["token"] = localStorage.getItem('username')
            config.headers.Authorization = localStorage.getItem('username')
        }
        // 设置请求头
        // 如果没有设置请求头
        if (!config.headers['content-type']) {

            if (config.method === 'post') {
                // post
                // console.log(9999999999)
                config.headers['content-type'] = "application/json;charset=UTF-8"
                // console.log(config.data)
                // 序列化,表单数据等
                // config.data = qs.stringify(config.data)
            }else if(config.method === 'put'){
                // console.log(23)
            }
             else {
                config.headers["content-type"] = "application/json"
            }
        } 
        // console.log('请求配置', config)
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// 创建响应拦截
serviceAxios.interceptors.response.use(
    // 成功
    (res) => {
        let data = res.data
        // 处理自己的业务逻辑，比如判断 token 是否过期等等
        // 代码块
        return data
    },
    // 失败
    (error) => {
        let message = ""
        if (error && error.response) {
            switch (error.response.status) {
                case 302:
                    // message = '接口重定向';
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '接口重定向';
                    }
                    break;
                case 400:
                    // message = "参数不正确！";
                    // message = error.response.data.message
                    message = error.response.data.message
                    // if(message){
                    //     message = error.response.data.message
                    // }else{
                    //     message = '参数不正确！';
                    // }
                    break;
                case 401:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '您未登录，或者登录已经超时，请先登录';
                    }
                    router.push('/login')
                    break;
                case 403:
                    // message = "您没有权限操作！";
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '您没有权限操作！';
                    }
                    break;
                case 404:
                    message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '请求超时！';
                    }
                    break;
                case 409:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '系统已存在相同数据！';
                    }
                    break;
                case 500:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '服务器内部错误！';
                    }
                    break;
                case 501:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '服务未实现！';
                    }
                    break;
                case 502:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '网关错误！';
                    }
                    break;
                case 503:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '服务不可用！';
                    }
                    break;
                case 504:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '服务暂时无法访问，请稍后再试！';
                    }
                    break;
                case 505:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = 'HTTP 版本不受支持！';
                    }
                    break;
                default:
                    if(message){
                        message = error.response.data.message
                    }else{
                        message = '异常问题,请联系管理员';
                    }
                    break;
            }
        }
        Message.closeAll();
        return Message.error(message)
    }
)

export default serviceAxios

