<!--
 * @Author: ygq
-->

<template>

  <div id="app">
    
    <index-heat class="quanbuyouxi"></index-heat>
    <div class="heihao" v-if="asd===9">
              <div class="heihao2">
                <span>警告</span>
                <img src="../assets/chachacha.png" alt="" @click="qued(0)">
              </div>
              <span class="dangqianwei">当前账号存在异常信息，可能为黑号，请谨慎交易！</span>
              <button @click="qued(0)">确定</button>
    </div>
    <div class="heihao" v-if="asd===99">
              <div class="heihao2">
                <span class="chenggonggg adssadsa">正常</span>
                <img src="../assets/chachacha.png" alt="" @click="qued(0)">
              </div>
              <span class="dangqianwei">当前账号正常，可放心交易！</span>
              <button @click="qued(0)">确定</button>
    </div>
    <div class="heihao heihaoNoGov" v-if="asd===1">
              <div class="heihao2">
                <span>警告</span>
                <img src="../assets/chachacha.png" alt="" @click="qued(0)">
              </div>
              <span class="dangqianwei">当前客服非答案网游官方客服，请谨慎交易！</span>
              <div class="btnDiv">
                <button @click="qued(0)">确定</button>
              </div>
              
    </div>
    <div class="heihao heihaopro" v-if="asd===2">
              <div class="heihao2">
                <span class="chenggonggg">成功</span>
                <img src="../assets/chachacha.png" alt="" @click="qued(0)">
              </div>
              <span class="dangqianwei">当前账号为答案网游官方客服账号</span>
              <!-- <img src="../assets/123123123.png" alt="" class="kefutupian"> -->
              <button @click="qued(0)">确定</button>
    </div>
    <hr class="bordertop">
    <div class="hmax hmaxindex">
      <div class="TradeType" v-show="sellnum === 1">
        <div class="tradeType" v-show="show === 1">
          <!-- 交易类型 -->
          <div class="Tradetype">
            <div class="typetop">
              <span>交易类型</span>
            </div>
            <div class="quecklysell">
              <div class="selltop">
                <span>
                  <img src="../assets/kuaisu.png" alt="">
                </span>
                <span>急速售卖</span>
              </div>
              <div class="sellbut">
                由官方专业人员评估回收，速度快
              </div>
            </div>
            <div class="quecklysell sellbut">
              <div class="selltop">
                <span>
                  <img src="../assets/kuaisu.png" alt="">
                </span>
                <span>平台寄售</span>
              </div>
              <div class="sellbut">
                上架至平台进行交易，价格自定
              </div>
            </div>
            <div class="buttonxtwo">
              <div class="sell">
                <span>
                  <img src="../assets/火苗.png" alt="">
                </span>
                <!-- asdasd -->
                <a class="lianxikefu"
          target="_blank"
          @click="showCustomerServiceQrCode"
          >
                <span>急速售卖</span>
                </a>
              </div>
              <div class="selltwo" @click="account()">
                平台寄售
              </div>
            </div>
          </div>

          <button class="buttonoff" @click="sell(0)">关闭</button>
        </div>
        <!-- 急速售卖 -->
        <div class="quecklySELL" v-show="show === 2">
          <div class="quecklySell">
            <div>
              <img src="../assets/jisushoumai.png" alt="">
            </div>
            <div>
              <li @click="jump(item.dictSort)"  :class="{blackbold:item.dictSort===active}" v-for="item in dictDetailCont" :key="item.dictSort">{{ item.label }}</li>
            </div>
            <div>
              <li  :class="{ backg: item.dictSort==active }" v-for="item in dictDetailCont" :key="item.dictSort" ></li>
            </div>
            <!-- 全部 -->
            <div class="sellAll" v-show="allnumsell === 1">
              <div class="onlyOne"  v-for="item in gamelist" :key="item.type" @click="hero(item)">
                <img :src="item.iconPath" alt="">
                <span>{{item.gameName}}</span>
              </div>
            </div>
          </div>
          <button @click="cutoff()">关闭</button>
        </div>
        <!-- 免费估号 -->
        <div class="quecklySELL" v-show="show === 99">
          <div class="quecklySell">
            <div style="height:60px">
              <img src="../assets/xuanzejiandingyouxi.png" height="40" alt="" style="margin-top:10px">
            </div>
            <div>
              <li @click="jump(item.dictSort)"  :class="{blackbold:item.dictSort===active}" v-for="item in dictDetailCont" :key="item.dictSort">{{ item.label }}</li>
            </div>
            <div>
              <li  :class="{ backg: item.dictSort==active }" v-for="item in dictDetailCont" :key="item.dictSort" ></li>
            </div>
            <!-- 全部 -->
            <div class="sellAll" v-show="allnumsell === 1">
              <div class="onlyOne"  v-for="item in gamelist" :key="item.type" @click="heroEstimate(item)">
                <img :src="item.iconPath" alt="">
                <span>{{item.gameName}}</span>
              </div>
            </div>
           
          </div>
          <button @click="cutoff()">关闭</button>
        </div>
      </div>

      <div class="h_mid">
        <div class="h_midmax">
          <div class="hotdoor">
            <img src="../assets/火苗.png" alt="">
            <span>热门游戏和分类</span>
          </div>
          <div class="navlist">            
            <li @click="all()">全部游戏</li>
            <li @click="bidding()">竞价中心</li>
            <li @click="account()">账号交易</li>
            <li @click="protect()">担保中介</li>
            <li @click="TradingInformation()">交易信息</li>
            <li @click="TransactionSecurity()">交易保障</li>
            <li @click="agree">关于我们</li>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间内容区域 -->
    <div class="content contentindex">
      <div class="cont_left leftindex">
        <div class="rent">
          <div class="numlist">
          <a class="lianxikefu"
          target="_blank"
          >
            <div class="num"  @click="sell(1, 99)">
              <div>
                <span>
                  <img src="../assets/免费估号.svg" alt="">
                </span>
                <!-- <span>账号价值 免费评估</span> -->
              </div>
              <img src="../assets/游戏2.5D   3.png" alt="">
            </div>
            </a>
            <div class="num" @click="sell(1, 1)">
              <div>
                <!-- <span>我要卖号</span>
                <span>急速售卖 方便快捷</span> -->
                <img src="../assets/免费卖号.svg" alt="">
              </div>
              <img src="../assets/游戏2.5D  2.png" alt="">
            </div>
            <div class="num" @click="all()">
              <div>
                <!-- <span>我要买号</span>
                <span>交易保障 终生包赔</span> -->
                <img src="../assets/免费买号.svg" alt="">
              </div>
              <img src="../assets/游戏2.5D.png" alt="">
            </div>
          </div>
          <div class="fillright"></div>
          <div class="navimg">
            <!-- 轮播图 -->
            <el-carousel class="navphoto">
              <el-carousel-item v-for="item in navpho" :key="item.uid" class="navparent">
                    <a v-if="item.jumpUrl" :href="item.jumpUrl" target="_blank"><img :src="item.url" alt=""></img></a>
                    <img v-else :src="item.url" alt=""></img>
                
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="virity">
            <navi-gation class="indexnav"></navi-gation>
            <!-- <div class="query">
              <span @click="findemos(1)" :class="{ blackbolded: fins === 1 }">验证客服</span>
              <span @click="findemos(2)" :class="{ blackbolded: fins === 2 }">查询黑号</span>
            </div>
            <div class="chaxunheihao" v-if="fins===1">
                <input type="text" v-model="kefuhaoma" name="" id="" placeholder="请输入客服号码">
                <button @click="verifyServicefun(1)">查询</button>
            </div>
            
            <div class="chaxunheihao" v-if="fins===2">
                <input name="" v-model="heihao" id="" placeholder="请输入黑号">
                <button @click="queds()">查询</button>
            </div> -->
            <div class="query">
              <span @click="findemo(1)" :class="{ blackbolded: fin === 1 }">公告</span>
              <span @click="findemo(2)" :class="{ blackbolded: fin === 2 }">找回案例</span>
            </div>
            <div class="public" v-show="fin === 1">
              <li  @click="TradingInformation()">平台交易流程</li>
              <li  @click="TradingInformation()">平台包赔流程</li>
              <li  @click="TradingInformation()">包赔服务说明</li>
              <li  @click="TradingInformation()">未成年禁止参与平台交易</li>
            </div>
            <div class="public" v-show="fin === 2">
              <li @click="TransactionSecurity()">找回案例示例</li>
            </div>
          </div>
        </div>

        <div class="union">
          <!-- @click="allmethods(item.fun)" -->
          <div class="union-l" v-for="item in union" @click="allmethods(item.fun)" :key="item.id">  
            <img :src="item.src" alt="">
            <span>{{ item.title }}</span>
          </div>
          <!-- god() -->
          <div class="every">
            <span class="cat">答案网游</span>
            <div class="everys" v-for="item in advantage" :key="item.id">
              <img :src="item.src" alt="">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>

        <div style="margin-top:10px">
          <img src="../assets/交易流程.webp" width="1100px" alt="">
        </div>

        <div class="goods" style="width: 1100px;">
          <div class="performance">
            <div class="lablist">
              <li><span>精品</span>账号</li>
              <li @click="jumpes(item.gameId,index)" :class="{ fontstyle: index === activeses }" v-for="(item,index) in this.gamelist"
                :key="index">{{ item.gameName }}</li>
            </div>
          </div>

          <div class="herolist">
            <div class="hero" @click="details(item.productId)" v-for="(item,index) in list" :key="index">
              <div class="photo">
                <img :src="item.mainImgPath" alt="">
              </div>
              <div class="font">
                <div class="font_o">
                  <li>{{ item.gameName }}</li>
                  <li>|</li>
                  <li>{{ item.gameService }}</li>
                </div>
                <div class="font_t">
                  <li>{{ item.description }}</li>
                </div>
                <div class="font_th">
                  <li>¥{{ item.price }}</li>
                  <li>{{ item.createTime }}</li>
                </div>
              </div>
            </div>
            <div class="hero" v-for="item in PeaceElite" :key="item.id"  @click="details()" v-show="activeType === 5">
              <div class="photo">
                <img src="../assets/asdfg.png" alt="">
              </div>
              <div class="font">
                <div class="font_o">
                  <li>{{ item.name }}</li>
                  <li>|</li>
                  <li>{{ item.area }}</li>
                </div>
                <div class="font_t">
                  <li>{{ item.describe }}</li>
                </div>
                <div class="font_th">
                  <li>{{ item.price }}</li>
                  <li>{{ item.time }}</li>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: center;padding: 20px 0;">
            <el-pagination @current-change="handleCurrentChange"
      :current-page.sync="currentPage1" background layout="prev, pager, next" :total="totalNum"></el-pagination>
          </div>

        </div>
      </div>
      <div class="cont_right">
        <div class="butdiv butdivleft">
          <img src="../assets/gmlogo.png" alt="">
          <div class="serve">
            <span><i>答案网游服务网</i></span>
            <span></span>
            <span>让账号交易享受法律保护</span>
          </div>
        </div>
        <div class="butdiv line"><img src="../assets/P}TXXO~71M[QRHJI0P31[UF.png" alt=""></div>
        <div class="butdiv">
          <div class="agreement">
            <li @click="indexfun">首页</li>
            <li>|</li>
            <li @click="fuwufang">服务协议</li>
            <li>|</li>
            <li @click="yewufun">业务介绍</li>
            <li>|</li>
            <li @click="zhaohuifun">找回赔偿</li>
            <li>|</li>
            <li>追回案例</li>
            <li>|</li>
            <li>法务介绍</li>
            <li>|</li>
            <li>公司价值</li>
          </div>
          <div class="record">
            <a href="http://beian.miit.gov.cn" class="beian"><li>蜀ICP备2024062281号-2</li></a>
          </div>
          <div class="copyright"><li>版权所有 <span>答案网游服务网</span> All Rights Reserved 抄袭必究</li></div>
          <div class="pot">
            <img class="potpro" src="../assets/shimingren.png" alt="">
            <img src="../assets/quanweiren.png" alt="">
          </div>
        </div>
      </div>
    </div>

        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
  </div>
</template>

<script>
import IndexHeat from './IndexHeat.vue'
import NaviGation from './NaviGation.vue'
import { dictDetail, game,homeCarousel,product,serviceQq,verifyService } from '@/request/api'
import serverConfig from '../request/config'
import {customerServiceQrCode} from '@/request/api'

export default {
  name: 'IndexMax',
  components: {
    IndexHeat,
    NaviGation
  },
  methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
    async verifyServicefun(type){
      if(!this.kefuhaoma){
        this.$message.error('请先输入客服号')
        return
      }
      let kf = {
        serviceNo:this.kefuhaoma
      }
      await verifyService(kf).then((res) =>{
        console.log(res)
        if(res === true){
          this.asd = 2
        }else{
          this.asd =1
        }
      })
    },
    qued(type){
      this.asd=type
      
    },
    queds(){
      if(!this.heihao){
        this.$message.error('请先输入黑号')
        return
      }
      let pg = 'getLiarData'
      let businessId = '11542'
      let account_number = this.heihao
      let keys = '79i1f06cu782r19pem7yxhm2531is2ij3x4'
      // pg.toLocaleUpperCase()
      let signs = this.$md5(`account_number=${account_number}&businessId=11542&pg=getLiarData&key=79i1f06cu782r19pem7yxhm2531is2ij3x4`)
      let sign = signs.toLocaleUpperCase()
      let fabu = {
        pg,
        businessId,
        account_number,
        sign
      }
      // console.log(fabu)
      this.$axios({
        method: 'post',
        url: 'http://mh.poxiaowy.com/admin/other/liarData.php',
        headers: {
   'Content-Type': 'multipart/form-data'
},
        data: fabu,
      })
        .then((response) => {
          console.log(response.data)
          console.log(11111)
          if(!response.data.data){
            this.asd=99
            console.log('正常')
          }else{
            this.asd=9
            console.log('不正常')
          }
          
        })
        

    },
    async serviceQqfun(){
      // await serviceQq().then((res) =>{
      //   this.qqNum = res.serviceQq
      // })
    },
    indexfun(){
      this.$router.push('/index')
    },
    zhaohuifun(){
      this.$router.push('/TransactionSecurity')
    },
    yewufun(){
      this.$router.push('/TradingInformation')
    },
    fuwufang(){
      this.$router.push('/TradingInformation')
    },
    agree() {
      this.$router.push('/AgreeOur')
    },
    details(type) {

      this.$router.push({
        path:'./AccountDetails',
        query:{type}
      })
    },
    protect() {
      this.$router.push('/AgencyMortgage')
    },
    TransactionSecurity() {
      this.$router.push('/TransactionSecurity')
    },
    TradingInformation() {
      this.$router.push('/TradingInformation')
    },
    account() {
      this.$router.push('/AccountTransactions')
    },
    bidding() {
      this.$router.push('/Bidding')
    },
    all() {
      this.$router.push('/GameList')
    },
    async jumpes(gameId,type) {
      this.list = []
      this.gameId = gameId
      this.currentPage1 = 1
      this.totalNum = 0
      this.pageNum = 0
      let params = {
        gameId: this.gameId , 
        size: this.size,
        page: this.pageNum,
        sort: 'createTime,desc'
      }
      await product(params).then((res)=>{
        this.list = res.content
        this.totalNum = res.totalElements
      })
      this.activeses = type
    },
    async jjumpes() {
      let params = {
        gameId: this.gameId, 
        size: this.size,
        page: this.pageNum,
        sort: 'createTime,desc'
      }
      await product(params).then((res)=>{
        this.list = res.content
        this.totalNum = res.totalElements
      })
    },
    // 查询商品列表
    async productfun(){
      let params = {
        page: this.currentPage1 - 1,
        size: this.size,
        gameId: this.gameId,
        sort: 'createTime,desc'
      }
      await product(params).then((res) =>{
        this.totalNum = res.totalElements
      })
    },
    handleCurrentChange(val) {
      this.currentPage1 = val
      this.pageNum = val - 1
      console.log(`当前页: ${val}`);
      this.jjumpes()// 查询商品列表
    },
    sell(sellnum, number) {
      this.sellnum = sellnum
      this.show = number
    },
    PlatformConsignmentfun() {
      this.$router.push('/PlatformConsignmentfun')
    },
    shows(show) {
      this.show = show
    },
    allnumsellc(allnumsell) {
      this.allnumsell = allnumsell
    },
    cutoff() {
      this.sellnum = 9
      this.show = 1
    },
    validation(type) {
      this.sty = type
    },
    findemo(type) {
      this.fin = type
    },
    findemos(type) {
      this.fins = type
    },
    allmethods(fun) {
      this[fun]()
    },
    hero(item) {
      console.log(item)
      return;
      this.$router.push({
        path:'/HeroAlliance',
        query:{
          gameName:item.gameName,
          gameId:item.gameId,
          iconPath:item.iconPath
        }
      })
    },
    heroEstimate(item) {
      this.$router.push({
        path:'/HeroEstimate',
        query:{
          gameName:item.gameName,
          gameId:item.gameId,
          iconPath:item.iconPath
        }
      })
    },
    god() {

      this.$router.push('/TwentySeven')
    },
    // 查询游戏字段
    async dictDetailfun() {
      await dictDetail(this.dictDetailnum).then((res) => {
        this.dictDetailCont = res.content
        this.dictDetailCont.unshift({
          label: '全部',
          dictSort: 0,
          value: ''
        })
      })
    },
    // 查询游戏
    async jump(type) {
      this.active = type
      let gamePlatforms = { gamePlatform: Number(this.dictDetailCont[type].value) }
      await game(gamePlatforms.gamePlatform !== 0 ? gamePlatforms : '').then((res) => {
        this.gamelist = res
      })
    },

    async jumps(){
      await game().then((res) =>{
          this.gamelist=res
          this.gameId = res[0].gameId
          this.productfun()
          this.jjumpes()
      })
    },
    async homeCarouselfun(){
      await homeCarousel({imageType:0}).then((res)=>{
        this.navpho=res
      })
    },

    findGameById(gameId){
      for(let game of this.gamelist){
        if(gameId==game.gameId){
          return game;
        }
      }
      return this.gamelist[0];
    },
    
    herohero(){
      let gameId = 5;
      let game = this.findGameById(gameId);
      localStorage.setItem('TZgameName',game.gameName)
      localStorage.setItem('TZiconPath',game.iconPath)
      localStorage.setItem('TZgameId',gameId)
      let types={gameName:game.gameName,
                iconPath:game.iconPath,
                gameId:game.gameId}
      this.$router.push({
        path:'/TwentySeven',
        query:{types}
      })

      /*let gameID = { gameId: 1,iconPath:serverConfig.baseURL+'/file/图片/lol-20220708040953687.png' };
      this.$router.push({
        path:'/HeroAlliance',
        query:gameID
      })*/
    },
    herohero2(){
      let gameId = 4;
      let game = this.findGameById(gameId);
      localStorage.setItem('TZgameName',game.gameName)
      localStorage.setItem('TZiconPath',game.iconPath)
      localStorage.setItem('TZgameId',gameId)
      let types={gameName:game.gameName,
                iconPath:game.iconPath,
                gameId:game.gameId}
      this.$router.push({
        path:'/TwentySeven',
        query:{types}
      })
      /*let gameID = { gameId: 2,iconPath:serverConfig.baseURL+'/file/图片/元神-20220708042049498.png' };
      this.$router.push({
        path:'/HeroAlliance',
        query:gameID
      })*/
    },
    herohero3(){
      let gameId = 2;
      let game = this.findGameById(gameId);
      localStorage.setItem('TZgameName',game.gameName)
      localStorage.setItem('TZiconPath',game.iconPath)
      localStorage.setItem('TZgameId',gameId)
      let types={gameName:game.gameName,
                iconPath:game.iconPath,
                gameId:game.gameId}
      this.$router.push({
        path:'/TwentySeven',
        query:{types}
      })
      /*let gameID = { gameId: 3,iconPath:serverConfig.baseURL+'/file/图片/王者-2022070804212336.png' };
      this.$router.push({
        path:'/HeroAlliance',
        query:gameID
      })*/
    },
    herohero5(){
      let gameId = 3;
      let game = this.findGameById(gameId);
      localStorage.setItem('TZgameName',game.gameName)
      localStorage.setItem('TZiconPath',game.iconPath)
      localStorage.setItem('TZgameId',gameId)
      let types={gameName:game.gameName,
                iconPath:game.iconPath,
                gameId:game.gameId}
      this.$router.push({
        path:'/TwentySeven',
        query:{types}
      })
      /*let gameID = { gameId: 4,iconPath:serverConfig.baseURL+'/file/图片/和平-20220708042105875.png' };
      this.$router.push({
        path:'/HeroAlliance',
        query:gameID
      })*/
    },
  },
  data() {
    return {
      gameId: null,
      kefuhaoma:'',
      qqNum:'',
      list: [
        { name: '英雄联盟', area: '比尔特沃夫', describe: '【L321421】总批复2020重击皮肤0深化皮肤衣157限定皮肤8', price: '¥2200', time: '2020-3-29', type: 1 },
        { name: '英雄联盟', area: '神圣之心', describe: '【L321323】总批复2020重击皮肤0深化皮肤衣157限定皮肤9', price: '¥2200', time: '2020-3-30', type: 2 },
      ],
      games: [
        { name: '英雄联盟', type: 1 },
        { name: '王者荣耀', type: 2 },
        { name: '原神', type: 3 },
        { name: '绝地求生', type: 4 },
        { name: '和平精英', type: 5 },
      ],
      KingGlory: [
        { name: '王者荣耀', area: '比尔特沃夫', describe: '【L321421】总批复2020重击皮肤0深化皮肤衣157限定皮肤8', price: '¥2200', time: '2020-3-29', type: 1 },
        { name: '王者荣耀', area: '神圣之心', describe: '【L321323】总批复2020重击皮肤0深化皮肤衣157限定皮肤9', price: '¥2200', time: '2020-3-30', type: 2 },
      ],
      OriginalGod: [
        { name: '原神', area: '比尔特沃夫', describe: '【L321421】总批复2020重击皮肤0深化皮肤衣157限定皮肤8', price: '¥2200', time: '2020-3-29', type: 1 },
        { name: '原神', area: '神圣之心', describe: '【L321323】总批复2020重击皮肤0深化皮肤衣157限定皮肤9', price: '¥2200', time: '2020-3-30', type: 2 },
      ],
      PUBG: [
        { name: '绝地求生', area: '比尔特沃夫', describe: '【L321421】总批复2020重击皮肤0深化皮肤衣157限定皮肤8', price: '¥2200', time: '2020-3-29', type: 1 },
        { name: '绝地求生', area: '神圣之心', describe: '【L321323】总批复2020重击皮肤0深化皮肤衣157限定皮肤9', price: '¥2200', time: '2020-3-30', type: 2 },
      ],
      PeaceElite: [
        { name: '和平精英', area: '比尔特沃夫', describe: '【L321421】总批复2020重击皮肤0深化皮肤衣157限定皮肤8', price: '¥2200', time: '2020-3-29', type: 1 },
        { name: '和平精英', area: '神圣之心', describe: '【L321323】总批复2020重击皮肤0深化皮肤衣157限定皮肤9', price: '¥2200', time: '2020-3-30', type: 2 },
      ],
      navpho: [],
      activeType: 1,
      sellnum: 0,
      allnumsell: 1,
      show: 1,
      sty: 1,
      fin: 1,
      fins:1,
      advantage: [
        { src: require('../assets/路径 254.svg'), title: '找回包赔 玩腻换号', type: 1 },
        { src: require('../assets/路径 255.svg'), title: '官方交易 资金保障', type: 2 },
        { src: require('../assets/路径 256.svg'), title: '专属客服 随叫随到', type: 3 },
        { src: require('../assets/路径 257.svg'), title: '平台担保 安全可靠', type: 4 },
      ],
      union: [
        { fun: 'herohero', src: require('../assets/7J__SDY(_Q7T9_95WFE8H93.png'), title: '英雄联盟', type: 1 },
        { fun: 'herohero2', src: require('../assets/../assets/__M_EI)EZKR0PJ70I_WAQ)Q.png'), title: '原神', type: 2 },
        { fun: 'herohero3',src: require('../assets/../assets/wangzherongyao.png'), title: '王者荣耀', type: 3 },
        { fun: 'herohero4',src: require('../assets/Z7)6(O5YQ_6_H0I_B__XD62.png'), title: 'CS-GO', type: 4 },
        { fun: 'herohero5',src: require('../assets/juediqiushengtwo.png'), title: '和平精英', type: 5 },
      ],
      dictDetailCont: [],
      dictDetailnum: {
        dictName: 'game_platform',
        page: 0,
        size: 9999,
      },
      active: 0,
      gamelist:[],
      activeses:0,
      qqNum:'',
      asd:0,
      heihao:'',
      currentPage1: 1,
      size: 12,
      totalNum: 0,
      pageNum: 0,
      qrDialogVisible: false,
      serviceQrCode: '',
    }
  },
  mounted() {
  },
  computed: {
    gamename() {
      return this.$store.state.name
    }
  },
  beforeMount() {
    this.serviceQqfun()
    this.dictDetailfun()
    this.jumps()
    this.homeCarouselfun()
    
  }
}
</script>

<style scoped lang="less">
.adssadsa{
  color: #05D652 !important;
}
.heihao{
  position: fixed;
  flex-direction: column;
  width: 360px;
  padding: 0 20px;
  height: 133px;
  background: #ffffff;
  border-radius: 4px;
  z-index: 2;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
  left: 768px;
  top: 378px;
  .heihao2{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    span{
      width: 30px;
      height: 20px;
      font-size: 15px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #fb070f;
    }
    img{
      width: 15px;
      height: 15px;
      opacity: 0.54;
    }
  }
  .dangqianwei{
    width: 252px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
  }
  button{
    width: 70px;
    height: 30px;
    background: #0076FF;
    border: 1px solid #0076FF;
    border-radius: 4px;
    color: #fff;
    transform: translate(111px,30px);
  }
}
.heihaoNoGov{
  display: flex;
  flex-direction: column;
  .btnDiv{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button{
      transform: translate(0,0);
    }
  }
}

.heihaopro{
  .chenggonggg{
    color: #05D652 !important;
  }
  .kefutupian{
    width: 300px;
    height: 202px;
  }
  button{
  }
}
.chaxunheihao{
  display: flex;
  width: 220px;
  height: 36px;
  background: #ffffff;
  border: 2px solid #0076FF;
  border-radius: 4px;
  margin-left: 13px;
  margin-top: 15px;
  margin-bottom: 39px;
  position: relative;
  overflow: hidden;
  
  
  input{
    border: none;
    outline: none;
    padding-left: 10px;
  }
  button{
    width: 50px;
    height: 38px;
    background: #0076FF!important;
    border-radius: 0px 4px 4px 0px;
    border: none;
    color: #fff; 
    transform: translateX(3px);
    position: absolute;
    right: 0;
    top: 0;
  }
}

.contentindex{
  border-radius: none !important;
  .leftindex{
    // transform: translateX(25px);
  }
}

.hmaxindex{
  height: 44px;
  border-radius: unset !important;
}

.quanbuyouxi{
  padding-bottom: 10px;
}

.lianxikefu{
  color: #fff;
  text-decoration: none;
}

.indexnav {
  position: absolute;
  top: 0;
  right: -200px !important;
}


.navphoto {
  width: 100%;
  height: 100%;

  .navparent {
    width: 100% !important;
    height: 100% !important;

    img {
      width: 100%;
      height: 100%;
    }
  }

}

.h_mid {
  width: 100%;

  .h_midmax {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 1200px;
    margin: 0 auto;

    div {
      font-size: 20px;
    }

    .hotdoor {
      display: flex;
      padding: 10px;
      transform: translateY(-2px);
      height: 26px;
      // border-radius: 8px;
      background-color: #0076FF;
      color: #fff;

      span {
        margin-left: 10px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      }
    }

    .navlist {
      flex-wrap: wrap;
    }
  }
}

.blackbold {
  font-weight: bold;
  color: #333 !important;
}

.blackbolded {
  font-weight: bold;
  color: #000 !important;
}

.backg {
  background-color: #0076FF;
}

.cont_left {
  width: 1100px;
  margin: 0 auto;
}

.TradeType {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  .tradeType {
    position: absolute;
    top: 250px;
    left: 644px;
    width: 740px;
    height: 459px;
    background: #ffffff;
    border-radius: 8px;

    .typetop {
      display: flex;
      width: 100%;
      height: 70px;
      border-bottom: 1px dashed #D9D9D9;

      span {
        line-height: 70px;
        color: #333;
        font-size: 25px;
        font-weight: bold;
        margin-left: 20px;
      }
    }

    .quecklysell {
      display: flex;
      flex-direction: column;
      margin-left: 52px;
      margin-top: 16px;

      .selltop {
        display: flex;
        align-items: center;

        img {
          margin-right: 12px;
        }

        span:last-child {
          font-size: 20px;
          font-weight: bold;
          color: #333;
        }
      }

      .sellbut {
        margin-top: 11px;
        font-size: 15px;
        color: #333;
      }
    }

    .buttonxtwo {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 60px;
      border-bottom: 1px dashed #D9D9D9;

      .sell,
      .selltwo {
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 169px;
        height: 60px;
        border-radius: 8px;
        text-align: center;
        line-height: 60px;
      }

      .sell {
        background-color: #FC9325;

        span {
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          margin-right: 10px;
        }
      }

      .selltwo {
        margin-left: 20px;
        border: 1px solid #FC9325;
        color: #FC9325;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .buttonoff {
      width: 113px;
      height: 40px;
      background-color: #E6E6E6;
      border: 1px solid #ABAAAA;
      color: #333;
      font-weight: bold;
      font-size: 13px;
      margin-left: 309px;
      margin-top: 20px;
      border-radius: 8px;
    }
  }

  .quecklySell {
    display: flex;
    flex-direction: column;

    div {
      margin-left: 20px;

      li {
        cursor: pointer;
        list-style: none;
        font-size: 20px;
        color: #919191;
      }
    }

    div:nth-child(2) {
      display: flex;

      li:nth-child(1) {
        margin-left: 15px;
      }

      li:nth-child(2),
      li:nth-child(3) {
        margin-left: 90px;
      }
    }

    div:nth-child(3) {
      display: flex;

      li {
        width: 52px;
        height: 8px;
        border-radius: 4px;
      }

      li:nth-child(1) {
        margin-left: 8px;
      }

      li:nth-child(2) {
        margin-left: 80px;
      }

      li:nth-child(3) {
        margin-left: 78px;
      }
    }

    .sellAll {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 280px;
      margin-top: 20px;

      .onlyOne {
        margin-top: 10px;
        margin-right: 33px;
        display: flex;
        flex-direction: column;
        width: 180px;
        height: 180px;
        border: 1px solid #707070;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        img{
          width: 80px;
          height: 80px;
        }
        span {
          margin-top: 10px;
        }
      }
    }
  }

  .quecklySELL {
    display: flex;
    flex-direction: column;
    width: 1200px;
    height: 646px;
    background: #ffffff;
    border: 0.2px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 40px;
    left: 270px;

    button {
      position: absolute;
      bottom: 10px;
      left: 539px;
      width: 113px;
      height: 40px;
      background: #eeeeee;
      border: 0.3px solid #333333;
      border-radius: 8px;
    }
  }
}

.fontstyle {
  font-size: 20px;
  font-weight: bold;
  
  border-bottom: 6px solid #0076FF;
}

/* 头部大盒子 */



/* 中间部分 */



/* 中间内容部分 */
.content {
  width: 100.65%;
  height: auto;
  opacity: 0.85;
  background: linear-gradient(0deg, #fffefe, #303131);
}

.numlist {
  width: 250px;
  height: 300px;
  background: #fffefc;
  border-radius: 8px;
  transform: translateY(10px);
}

.rent {
  display: flex;
}

// .rent div:nth-child(1) div:nth-child(1) {
//   width: 242px;
//   height: 80px;
//   border-radius: 8px;
//   /* box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.16);  */
//   background: linear-gradient(224deg,#0085ff 9%, #001a89 95%);
// }

.rent .num:nth-child(1){
  background: linear-gradient(224deg,#0085ff 9%, #001a89 95%);
  margin-top: 10px;
}

.rent div:nth-child(1) div {
  width: 242px;
  height: 80px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  margin: 0px 4px 0px 4px;
  transform: translateY(10px);
}

.rent div:nth-child(1) div:nth-child(2) {
  background: #80A1FC;
  margin: 10px 4px 30px 4px;
}

.rent div:nth-child(1) div:nth-child(3) {
  background: #F3D956;
  margin: 0px 4px;
  transform: translateY(-10px);
}

.num {
  display: flex;
  cursor: pointer;
}

.num img {
  width: 78px;
  height: 71px;
  margin: 7px 15px 2px;
}

.num div {
  display: flex;
  flex-direction: column;
  background-color: unset !important;
  box-shadow: unset !important;
}

.num div span {
  color: #fff;
}

.num div img{
  width: 106px;
  height: 56px;
  margin: 0;
}

.num div span:nth-child(1) {
  font-size: 26px;
  
}

.num div span:nth-child(2) {
  font-size: 5px;
}

.navimg {
  width: 660px;
  height: 300px;
  transform: translateY(10px);
  margin-left: 20px;
}

.navimg img {
  width: 100%;
  height: 100%;
}

.virity {
  position: relative;
  width: 250px;
  height: 300px;
  background: #ffffff;
  border: 0.2px solid #707070;
  border-radius: 8px;
  transform: translateY(10px);
  margin-left: 21px;
}

.query {
  display: flex;
  border-bottom: 1px solid #707070;
  justify-content: center;
}

.query span {
  cursor: pointer;
  font-size: 18px;
  color: #333333;
  width: 90px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
}

.find {
  display: flex;
  position: relative;
  margin: 16px 0 38px 20px;
  border: 2px solid #0076FF;
  border-radius: 4px;
  width: 210px;
}

.find input {
  width: 167px;
  height: 40px;
  border: none;
  font-size: 16px;
  text-align: center;
  outline: none;
}

.find button {
  cursor: pointer;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 50px;
  height: 43px;
  border: none;
  background-color: #0076FF;
  color: #fff;
}

.public {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.public li {
  cursor: pointer;
  list-style: none;
  margin: 10px 0 0 14px;
  color: #707070;
  font-size: 15px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
}

.union {
  display: flex;
}

.union-o {
  position: relative;
  margin-top: 15px;
  width: 200px;
  height: 280px;
}

.union-o img {
  width: 100%;
  height: 100%;
}

.union-o span {
  position: absolute !important;
  font-size: 25px;
  top: 7px;
  left: 10px;
  color: #ffffff;
  font-weight: bold;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
}

.union-l {
  cursor: pointer;
  position: relative;
  margin-left: 8px;
  margin-top: 15px;
  width: 200px;
  height: 280px;
  transform: translateX(-7px);
}

.union-l img {
  width: 100%;
  height: 100%;
}

.union-l span {
  position: absolute !important;
  font-size: 25px;
  top: 7px;
  left: 10px;
  color: #ffffff;
  font-weight: bold;
}

.every {
  width: 160px;
  height: 280px;
  background: #ffffff;
  // border: 0.2px solid #707070;
  border-radius: 8px;
  margin-top: 20px;
  transform: translateY(-5px);
  display: flex;
  flex-direction: column;

  .cat {
    font-weight: bold;
    font-size: 16px;
    margin: 10px;
  }

  .everys {
    margin-top: 20px;
    display: flex;
    margin-left: 10px;

    img {
      width: 25px;
      height: 25px;
    }

    span {
      margin-left: 10px;
      font-size: 11px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    }
  }
}

.one {
  width: 340px;
  font-size: 20px;
  line-height: 50px;
  height: 50px;
  margin-left: 14px;
  text-align: left;
  border-bottom: 1px solid #777272;
}

.lable {
  display: flex;
  flex-wrap: wrap;
}

.lable li {
  margin: 15px 0 0 15px;
  padding: 2px 5px;
  list-style: none;
  height: 30px;
  opacity: 0.38;
  border: 1px solid #707070;
  border-radius: 16px;
}

.phonenum {
  margin-top: 6px;
  height: 110px;
  overflow: hidden;
}

.numb {
  display: flex;
}

.numb li {
  font-size: 12px;
  list-style: none;
}

.numb li:nth-child(1) {
  margin-top: 8px;
  color: #FB0E0E;
  transform: translateY(3px);
  margin-left: 15px;
}

.numb li:nth-child(2) {
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 110px;
}

.numb li:nth-child(3) {
  margin-top: 8px;
  color: #FB0E0E;
  transform: translateY(3px);

}

.numb li:nth-child(4) {
  margin-top: 10px;
}

.goods {
  width: 1200px;
  height: auto;
  background-color: #ffffff;
  border: 0.2px solid #707070;
  border-radius: 8px;
  margin-top: 10px;
}

.performance {
  display: flex;
  flex-direction: column;
}

.liitem {
  display: flex;
}

.liitem li:nth-child(1) {
  list-style: none;
  margin-left: 10px;
  width: 62px;
  height: 5px;
  background: #0076FF;
  border-radius: 3px;
  margin-left: 133px;
  transform: translateY(-20px);
}

.liitem li:nth-child(2) {
  list-style: none;
  margin-left: 222px;
  width: 62px;
  height: 5px;
  background: #0076FF;
  border-radius: 3px;
  transform: translateY(-20px);
}

.liitem li:nth-child(3) {
  list-style: none;
  margin-left: 310px;
  width: 30px;
  height: 5px;
  background: #0076FF;
  border-radius: 3px;
  transform: translateY(-20px);
}

.liitem li:nth-child(4) {
  list-style: none;
  margin-left: 370px;
  width: 62px;
  height: 5px;
  background: #0076FF;
  border-radius: 3px;
  transform: translateY(-20px);
}

.liitem li:nth-child(5) {
  list-style: none;
  margin-left: 460px;
  width: 62px;
  height: 5px;
  background: #0076FF;
  border-radius: 3px;
  transform: translateY(-20px);
}

.lablist {
  display: flex;
  position: relative;
}

.lablist i {
  width: 62px;
  height: 5px;
  background-color: #0076FF;
  border-radius: 3px;
}

.lablist li {
  list-style: none;
}

.lablist li:nth-child(1) {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 5px;
}

.lablist li:nth-child(1) span {
  color: #0076FF;
}

.lablist li:nth-child(2),
.lablist li:nth-child(3),
.lablist li:nth-child(4),
.lablist li:nth-child(5),
.lablist li:nth-child(6) {
  font-size: 16px;
  margin-left: 30px;
  height: 40px;
  line-height: 60px;
  cursor: pointer;
}

.lablist li:nth-child(2) {
  margin-left: 20px;
}



.lablist li:nth-child(7) {
  float: left;
  height: 60px;
  line-height: 60px;
  color: #0076FF;
  position: absolute;
  right: 20px;
}

.herolist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-left: 16px;
  margin-bottom: 20px;
}

.hero {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 285px;
  height: 251px;
  margin-right: 11px;
  background: rgba(0, 0, 0, 0.00);
  // border: 0.2px solid #707070;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16);
  margin-right: 8px;
  margin-top: 10px;
}

.photo {
  width: 285px;
  height: 165px;
  border-radius: 8px 8px 0px 0px;
}

.photo img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
}

.font {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.font div li {
  list-style: none;
  color: #BABABA;
  font-size: 12px;
}

.font_o {
  display: flex;
  margin-top: 5px;
}

.font_o li:nth-child(1) {
  color: #151CF3;
}

.font_o li:nth-child(2) {
  margin: 0 16px;
  color: #b1b1b1;
}

.font_t {
  height: 30px;
  overflow: hidden;
  display: flex;
  text-align: left;
  margin: 5px 0;
  li{
    height: 30px;
    overflow:hidden;
    text-overflow: ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }
}

.font_th {
  display: flex;
  position: relative;
}

.font_th li:nth-child(1) {
  color: #FB0E16;
  font-weight: bold;
  font-size: 16px;
}

.font_th li:nth-child(2) {
  transform: translateY(4px);
  position: absolute;
  right: 10px;
}



.navlist li {
  cursor: pointer;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
}

.cont_right{
  width: 101%;
}

.record li{
    color: #999999;
    padding-right: 50px;
  }
</style>
