import Vue from "vue";
import Vuex from "vuex"
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 玩家名字
        name: '',
        // 玩家头像
        HeadPortrait: '',
        // 玩家账号ID
        accountId: '',
        // 实名状态
        realName: '',
        // 手机号
        phone:'',
    },
    mutations: {
        LoginValue(state, el) {
         
            state.name = el.name//玩家名字
            state.HeadPortrait = el.HeadPortrait//玩家头像
            state.accountId = el.accountId//玩家ID
            state.realName = el.realName//实名状态
            state.phone = el.phone//手机号
        },

    },
    actions: {

    },
    modules: {

    }
})