// @Author: ygq

import serviceAxios  from "./http";
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 公司介绍 /web/content/aboutIntroduce
export const getUerInfo = (params) =>{
    return serviceAxios({
        url:'/web/content/aboutIntroduce',
        method:'get',
        params,
    })
}

// 企业文化 /web/content/aboutIntroduce
export const culture = (params) =>{
    return serviceAxios({
        url:'/web/content/aboutCulture',
        method:'get',
        params,
    })
}

// 公司风采 /web/content/aboutIntroduce
export const CompanyElegantDemeanour = (params) =>{
    return serviceAxios({
        url:'/web/content/aboutPresence',
        method:'get',
        params,
    })
}

// 找回赔偿 /web/content/aboutIntroduce
export const RecoverDamages = (params) =>{
    return serviceAxios({
        url:'/web/content/guaranteeBackCompensation',
        method:'get',
        params,
    })
}

// 售后服务 /web/content/guaranteeAfterSales
export const AfterSalesService = (params) =>{
    return serviceAxios({
        url:'/web/content/guaranteeAfterSales',
        method:'get',
        params
    })
}

//售后案例 /web/content/guaranteeAfterSalesCase
export const AfterCase = (params) =>{
    return serviceAxios({
        url:'/web/content/guaranteeAfterSalesCase',
        method:'get',
        params
    })
}

// 防骗提醒 /web/content/guaranteeFraudPreventionRemind
export const FraudPrevention = (params) =>{
    return serviceAxios({
        url:'/web/content/guaranteeFraudPreventionRemind',
        method:'get',
        params
    })
}

// 未成年禁止参加交易 /web/content/guaranteeMinors
export const eighteenForbidden = (params) =>{
    return serviceAxios({
        url:'/web/content/guaranteeMinors',
        method:'get',
        params
    })
}

// 包赔流程 /web/content/guaranteePriceGuarantee
export const bagpay = (params) =>{
    return serviceAxios({
        url:'/web/content/guaranteePriceGuarantee',
        method:'get',
        params
    })
}

//专业鉴定  /web/content/guaranteeProfessionalIdentification
export const professional = (params) =>{
    return serviceAxios({
        url:'/web/content/guaranteeProfessionalIdentification',
        method:'get',
        params
    })
}

// 业务介绍   /web/content/transactionBusinessIntroduction
export const BusinessIntroduction = (params) =>{
    return serviceAxios({
        url:'/web/content/transactionBusinessIntroduction',
        method:'get',
        params
    })
}

// 服务协议   /web/content/transactionServiceAgreement
export const serviceAgreement = (params) =>{
    return serviceAxios({
        url:'/web/content/transactionServiceAgreement',
        method:'get',
        params
    })
}

// 评分计划  /web/content/transactionEvaluationScore
export const GradingPlan = (params) =>{
    return serviceAxios({
        url:'/web/content/transactionEvaluationScore',
        method:'get',
        params
    })
}

// 投诉建议  /web/content/transactionComplaintsSuggestions
export const ComplaintsSuggestions = (params) =>{
    return serviceAxios({
        url:'/web/content/transactionComplaintsSuggestions',
        method:'get',
        params
    })
}


// 担保中介 /web/content/mediationGuarantee
export const AgencyMortgage = (params) =>{
    return serviceAxios({
        url:'/web/content/mediationGuarantee',
        method:'get',
        params
    })
}


// 密码加密

// 密钥对生成 http://web.chacuo.net/netrsakeypair
// 公匙
const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD\n' +
  '2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ=='

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}


// 用户注册 /web/userAccount/register
export const userRegistered = (data) =>{
    return serviceAxios({
        url:'/web/userAccount/register',
        method:'post',
 
        data
    })
}

// 用户登录 /web/userAccount/login
export const usersLogin = (data) =>{
    return serviceAxios({
        url:'/web/userAccount/login',
        method:'post',
        data
    })
}
 
// 修改名字 /web/userAccount/updateBasicInfo
export const editor = (data) =>{
    return serviceAxios({
        url:'/web/userAccount/updateBasicInfo',
        method:'put',
        data
    })
}

// 修改密码 /web/userAccount/updatePass
export const updatePass = (data) =>{
    return serviceAxios({
        url:'/web/userAccount/updatePass',
        method:'put',
        data
    })
}

// 实名认证 /web/userAccount/idCertification
export const idCertification = (data) =>{
    return serviceAxios({
        url:'/web/userAccount/idCertification',
        method:'put',
        data
    })
}

// 上传图片 /api/localStorage/pictures
export const pictures = (data) =>{
    return serviceAxios({
        url:'/api/localStorage/pictures',
        method:'post',
        data
    })
}

// 退出登录 /web/userAccount/logout
export const exitLogin = (data) =>{
    return serviceAxios({
        url:'/web/userAccount/logout',
        method:'delete',
        data
    })
}

// 查询游戏字段 /api/dictDetail
export const dictDetail = (params) =>{
    return serviceAxios({
        url:'/api/dictDetail',
        method:'get',
        params
    })
}

// 查询游戏 /web/product/game
export const game = (params) =>{
    return serviceAxios({
        url:'/web/product/game',
        method:'get',
        params
    })
}

// 查询游戏区服
export const areaService = (params) =>{
    return serviceAxios({
        url:'/web/product/game/areaService',
        method:'get',
        params
    })
}

// 查询游戏属性
export const gameProperty = (params) => {
    return serviceAxios({
      url: '/web/product/game/property',
      method: 'get',
      params,
    });
  };

//   查询轮播图 /web/content/homeCarousel
export const homeCarousel = (params) => {
    return serviceAxios({
      url: '/web/content/homeCarousel',
      method: 'get',
      params,
    });
  };

  export const customerServiceQrCode = () => {
    return serviceAxios({
      url: '/web/content/homeCarousel',
      method: 'get',
      params: {imageType:1},
    });
  };

//   查询用户商品 /web/product
export const product = (params) => {
    return serviceAxios({
      url: '/web/product',
      method: 'get',
      params,
    });
  };

//   查询用户商品 /web/product
export const biddingProduct = (params) => {
  return serviceAxios({
    url: '/web/biddingProduct',
    method: 'get',
    params,
  });
};
  

//   查询用户订单 /web/order
export const order = (params) => {
    return serviceAxios({
      url: '/web/order',
      method: 'get',
      params,
    });
  };

// 查询商品详情   /web/product/detail
export const detail = (params) => {
    return serviceAxios({
      url: '/web/product/detail',
      method: 'get',
      params,
    });
  };

// 查询商品详情   /web/product/detail
export const biddingDetail = (params) => {
  return serviceAxios({
    url: '/web/biddingProduct/detail',
    method: 'get',
    params,
  });
};  

//   查询订单详情  /web/order/detail
export const orderDetail = (params) => {
    return serviceAxios({
      url: '/web/order/detail',
      method: 'get',
      params,
    });
  };


//  查询商品详情  /web/product/user/detail
export const goodsDetail = (params) => {
    return serviceAxios({
      url: '/web/product/user/detail',
      method: 'get',
      params,
    });
  };

//  查询商品详情  /web/biddingProduct/user/detail
export const biddingGoodsDetail = (params) => {
  return serviceAxios({
    url: '/web/biddingProduct/user/detail',
    method: 'get',
    params,
  });
};
  
//  查询商品详情  /web/biddingProduct/user/detail
export const estimateGoodsDetail = (params) => {
  return serviceAxios({
    url: '/web/estimateProduct/user/detail',
    method: 'get',
    params,
  });
};

// 查询用户商品 /web/product/user
export const user = (params) => {
    return serviceAxios({
      url: '/web/product/user',
      method: 'get',
      params,
    });
  };

// 查询用户商品 /web/biddingProduct/user
export const biddingUser = (params) => {
  return serviceAxios({
    url: '/web/biddingProduct/user',
    method: 'get',
    params,
  });
};

// 查询用户商品 /web/estimateProduct/user
export const estimateUser = (params) => {
  return serviceAxios({
    url: '/web/estimateProduct/user',
    method: 'get',
    params,
  });
};

//   发布商品  /web/product
export const productGoods = (params) => {
    return serviceAxios({
      url:'/web/product',
      method:'post',
      params
    });
  };

//  获取用户信息 /web/userAccount/info
export const info = (params) => {
    return serviceAxios({
      url:'/web/userAccount/info',
      method:'get',
      params
    });
  };

//  商品状态变更  /web/product/productStatus
export const productStatus = (params) => {
    return serviceAxios({
      url:'/web/product/productStatus',
      method:'put',
      params
    });
  };

//  查询用户收支记录 /web/userAccount/incomeExpenditure
export const incomeExpenditure = (params) => {
    return serviceAxios({
      url:'/web/userAccount/incomeExpenditure',
      method:'get',
      params
    });
  };

  //  查询提现记录 /web/userAccount/withdraw

export const withdraw = (params) => {
    return serviceAxios({
      url:'/web/userAccount/withdraw',
      method:'get',
      params
    });
  };

// 查询用户累计收益   /web/userAccount/accountBalance
export const accountBalance = (params) => {
    return serviceAxios({
      url:'/web/userAccount/accountBalance',
      method:'get',
      params
    });
  };

  // // 获取客服qq号
  // export const serviceQq = (params) => {
  //   return serviceAxios({
  //     url:'/web/userAccount/serviceQq',
  //     method:'get',
  //     params
  //   });
  // };


// 获取人机校验验证码
export const code = (params) => {
  return serviceAxios({
    url:'/web/userAccount/code',
    method:'get',
    params
  });
};

// 人机校验码确认
export const codeCheck = (params) => {
  return serviceAxios({
    url:'/web/userAccount/codeCheck',
    method:'get',
    params
  });
};
// 获取亲友号
export const friend = (params) => {
  return serviceAxios({
    url:'/web/userAccount/friend',
    method:'get',
    params
  });
};

// 校验客服
export const verifyService = (params) => {
  return serviceAxios({
    url:'/web/userAccount/verifyService',
    method:'get',
    params
  });
};

// 全局配置
export const configListService = (params) => {
  return serviceAxios({
    url:'/web/config/all',
    method:'get',
    params
  });
};

// 配置详情
export const configDetailService = (params) => {
  return serviceAxios({
    url:'/web/config/detail',
    method:'get',
    params
  });
};

// 配置详情
export const configByKeyService = (params) => {
  return serviceAxios({
    url:'/web/config/configByKey',
    method:'get',
    params
  });
};
