<!--
 * @Author: ygq
-->
<template>
    <div id="app">
        <!--  交易信息 -->

        <div class="maxbox">
            <index-heat class="indexheat"></index-heat>
            <div class="h_midmax tranding">
                <div class="h_mid">
                <div @click="index()" class="webindex">
                    <span>网站首页</span>
                </div>
                <div class="navlist">
                    <li @click="all()">全部游戏</li>
                    <li @click="bidding()">竞价中心</li>
                    <li @click="account()">我要卖号</li>
                    <li @click="protect()">担保中介</li>
                    <li class="agree">交易信息</li>
                    <li @click="TransactionSecurity()">交易保障</li>
                    <li @click="agree()">关于我们</li>
                </div>
            </div>
            </div>
            <div class="company">
                <i class="fill"></i>
                <div class="minbox">
                    <div class="agreeour">
                        <div class="our">
                            <div class="agreew">
                                <i>
                                    <img src="../../assets/路径 59.png" alt="">
                                </i>
                                <span>
                                    <router-link to="index" class="agreeme">交易必看</router-link>
                                </span>
                            </div>
                            <div class="companylist">
                                <div @click="find(item.type)" v-for="item in list" :key="item.type" :class="{fixback:item.type===activeType}">
                                    <span :class="{fixfont:item.type===activeType}">{{item.title}}</span>
                                    <i v-if="item.type===activeType">
                                        <img src="../../assets/路径 652.png" alt="">
                                    </i>
                                    <i v-else>
                                        <img src="../../assets/路径 65.png" alt="">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="introduce">
                        <navi-gation class="infornav"></navi-gation>
                        <div class="logofon">
                            <!-- <div class="logimgo">
                                <img src="../../assets/猫咪logo2.png" alt="" v-show="this.show!=1">
                            </div> -->
                            <!-- 公司介绍 -->
                            <div class="logimgt">
                                <p v-show="this.activeType===1" style="font-weight:bolder;color:#444">业务介绍</p>
                                <p v-show="this.activeType===2" style="font-weight:bolder;color:#444">服务协议</p>
                                <p v-show="this.activeType===3" style="font-weight:bolder;color:#444">隐私政策</p>
                                <p v-show="this.activeType===4" style="font-weight:bolder;color:#444">投诉建议</p>
                                <!--<img src="../../assets/yewujieshao.png" alt="" v-show="this.activeType===1">
                                <img src="../../assets/服务协议.png" alt="" v-show="this.activeType===2">
                                <img src="../../assets/yinsizhence.png" v-show="this.activeType===3">
                                <img src="../../assets/tousujianyi.png" alt="" v-show="this.activeType===4">-->
                            </div>
                        </div>
                        <!-- 公司介绍 -->
                        <div class="AboutUs AboutUsAboutUs">
                            <div v-html="BusinessIntroduction" v-show="this.activeType===1"></div>
                            <div v-html="serviceAgreement" v-show="this.activeType===2"></div>
                            <div v-html="GradingPlan" v-show="this.activeType===3"></div>
                             <a class="lianxikefu" @click="showCustomerServiceQrCode">
                            <div v-html="ComplaintsSuggestions" v-show="this.activeType===4"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
            <index-tail></index-tail>
        </div>
        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import IndexTail from '../IndexTail.vue'
import NaviGation from '../NaviGation.vue'
import {customerServiceQrCode} from '@/request/api'
import {
    BusinessIntroduction,
    serviceAgreement,
    GradingPlan,
    ComplaintsSuggestions,
    serviceQq
} from '@/request/api'


export default {
    name: 'TradingInformation',
    components: {
        IndexHeat,
        IndexTail,
        NaviGation
    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        async serviceQqfun(){
    //   await serviceQq().then((res) =>{
    //     this.qqNum = res.serviceQq
    //   })
    },
        all() {
            this.$router.push('/GameList')
        },
        protect() {
            this.$router.push('/AgencyMortgage')
        },
        index() {
            this.$router.push('/index')
        },
        find(type){
            this.activeType=type
        },
        agree() {
            this.$router.push('/AgreeOur')
        },
        TransactionSecurity(){
            this.$router.push('/TransactionSecurity')
        },
        account(){
            this.$router.push('/AccountTransactions')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        // 业务介绍
        async BusinessIntroductionfun(){
            await BusinessIntroduction().then((res)=>{
                this.BusinessIntroduction = res.contentText
            })
        },
        // 服务协议
        async serviceAgreementfun(){
            await serviceAgreement().then((res)=>{
                this.serviceAgreement = res.contentText
            })
        },
        // 评分计划
        async GradingPlanfun(){
            await GradingPlan().then((res)=>{
                this.GradingPlan = res.contentText
            })
        },
        // 投诉建议
        async ComplaintsSuggestionsfun(){
            await ComplaintsSuggestions().then((res)=>{
                this.ComplaintsSuggestions = res.contentText
            })
        },
        
        
    },
    data(){
        return{
            qqNum:'',
            show:1,
            list:[
                {title:'业务介绍',type:1},
                {title:'服务协议',type:2},
                {title:'隐私政策',type:3},
                {title:'投诉建议',type:4},
                
            ],
            activeType:1,
            // 业务介绍
            BusinessIntroduction:``,
            // 服务协议
            serviceAgreement:``,
            // 评分计划
            GradingPlan:``,
            // 投诉建议
            ComplaintsSuggestions:``,
            qrDialogVisible: false,
            serviceQrCode: '',
        }
    },
    mounted(){
        this.GradingPlanfun()
        this.serviceAgreementfun()
        this.BusinessIntroductionfun()
        this.ComplaintsSuggestionsfun()
        this.serviceQqfun()
    }
}
</script>

<style>
.lianxikefu{
    text-decoration: none;
    color: #000000;
}

.fixback{
    background-color: #0076FF;
}
.fixfont{
    color: #FBF6F6;
}

.tranding{
    background-color: #fff;
    border-top: 1px solid #707070;
}

/* 中间部分 */


.agree {
    background-color: #0076FF;
    color: #fff !important;
    border-radius: 8px;
}

.company {
    width: 1920px;
    height: auto;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    padding-bottom: 250px;
}

.minbox {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
}

.fill {
    width: 100vw;
    height: 20px;
    background-color: #f6f6f6;
}

.agreeour {
    width: 290px;
    height: 329px;
}

.introduce {
    display: flex;
    flex-direction: column;
    width: 900px;
    height: auto;
    background-color: #fff;
    border-radius: 8px !important;
    padding-bottom: 20px;
}

.our {
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: #fff;
}

.agreew {
    display: flex;
    width: 290px;
    height: 70px;
    background: url('../../assets/有纹理的背景4.svg') no-repeat;
    background-size: 100% 100%;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.agreew i {
    width: 21.79px;
    height: 21.81px;
}

.agreew i img {
    width: 100%;
    height: 100%;
}

.agreeme {
    font-size: 20px;
    font-weight: bold;
    color: #FBF6F6;
    text-decoration: none;
}

.companylist {
    display: flex;
    flex-direction: column;
}

.companylist div {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 185px;
    height: 50px;
    padding-left: 105px;
    border-radius: unset;
    /* border-radius: 8px; */
}

.companylist div i {
    margin-right: 12px;
}

.companylist div:nth-child(1) {
    margin-top: 16px;
    color: #333;
}

.companylist div span {
    font-size: 16px;
    color: #909090;
}

.companylist div:nth-child(1) span {
    /* color: #333; */
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
}

.logofon {
    display: flex;
    margin: 10px 0 0 20px;
}

.logimgo {
    width: 50px;
    height: 60px;
}

.logimgt {
    width: 348px;
    height: 52px;
}

.logimgo img,
.logimgt img {
    width: 100%;
    height: 100%;
}

.AboutUs {
    position: relative;
    margin: 10px 0 0 20px;
}

.infornav {
    position: absolute;
    top: 0px;
    right: -200px;
}

.abs {
    position: absolute;
    top: 39px;
    left: 214px;
}

.abss {
    position: absolute;
    bottom: 380px;
    left: 80px;
}
.server{
    display: flex;
}
.servers{
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 350px;
    margin-right: 10px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.16); 
}
.servers div{
    width: 84px;
    height: 88px;
    margin:  40px auto;
}
.servers span{
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin: 20px auto;
}
.servers span:last-child{
    margin-top:  40px;
}
.servers button{
    width: 120px;
    height: 40px;
    background: #0076FF;
    border-radius: 8px;
    margin: 28px auto;
    border: none;
    color: #fff;
}
.servers div img{
    width: 100%;
    height: 100%;
}
</style>
