import { render, staticRenderFns } from "./WebThirty.vue?vue&type=template&id=458b2ad3&scoped=true&"
import script from "./WebThirty.vue?vue&type=script&lang=js&"
export * from "./WebThirty.vue?vue&type=script&lang=js&"
import style0 from "./WebThirty.vue?vue&type=style&index=0&id=458b2ad3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458b2ad3",
  null
  
)

export default component.exports