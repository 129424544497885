
<!--
 * @Author: ygq
-->
<!-- web 1920-28 -->
<template>
    <div id="app">
        <div class="epicskin">
            <p>{{this.checkdetail ? this.checkdetail.typeName : ''}}</p>
            <i></i>
            <span class="sums">共计<span>{{this.checkdetail.skins.length}}</span>款</span>
            <div class="sumlist">
                <div class="lists" v-for="(item,index) in this.checkdetail.skins" :key="index">
                    <div class="lisimg">
                        <!-- <img :src="item.skinPath" alt=""> -->
                        <el-image :src="item.skinPath" fit="fill" ></el-image>
                    </div>
                    <span>{{item.skinName}}</span>
                </div>
                

            </div>
            <!-- <button class="guanbi">关闭</button> -->
        </div>
    </div>
</template>

<script>


export default {
  name: 'EpicsKin',
  components: {
    
  },
  props:['checkdetail'],
  mounted(){
  }
}
</script>
<style scoped lang="less">
    .epicskin{
        width: 902px;
        height: 500px;
        background: #ffffff;
        border: 0.3px solid #707070;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 20px;
        /* // 滚动条 */
        ::-webkit-scrollbar {/*滚动条整体样式*/
            width: 10px;     /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
        }
        ::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: #E5E5E5;
        }
        ::-webkit-scrollbar-track {/*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 10px;
            background: #ffffff;
        }
    }
    .guanbi{
        margin: 20px auto;
        width: 65px;
    }
    .epicskin p{
        font-size: 20px;
        font-weight: bold; 
        margin-left: 10px;
    }
    .epicskin i{
        display: block;
        width: 100%;
        height: 1px;
        background: #D0D0D0;
        margin-bottom: 20px;
    }
    .sums{
        display: block;
        margin-left: 10px;
        font-size: 14px;
        
    }
    .sums span{
        color: #FF9526;
    }
    .sumlist{
        width: 100%;
        margin-top: 20px;
        margin-left: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    .lists{
        width: 120px;
        height: auto;
        overflow: hidden;
        margin-top:  20px;
        margin-right: 22px;
    }
    
    .lisimg{
        width: 120px;
        height: auto;
    }
    .lisimg img{
        width: 100%;
        height: 100%;
    }
</style>