<!--
 * @Author: ygq
-->
<template>
    <div id="app">
        <!-- 关于我们 -->

        <div class="maxbox">
            <index-heat class="idnexheat"></index-heat>
            <div class="h_midmax agreewithme">
                <div class="h_mid">
                    <div @click="index()" class="webindex">
                        <span>网站首页</span>
                    </div>
                    <div class="navlist">
                        <li @click="all()">全部游戏</li>
                        <li @click="bidding()">竞价中心</li>
                        <li @click="account()">我要卖号</li>
                        <li @click="protect()">担保中介</li>
                        <li @click="TradingInformation()">交易信息</li>
                        <li @click="TransactionSecurity()">交易保障</li>
                        <li class="agree">关于我们</li>
                    </div>
                </div>
            </div>
            <div class="company">
                <i class="fill"></i>
                <div class="minbox">
                    <div class="agreeour">
                        <div class="our guanyuiur">
                            <div class="agreew">
                                <i>
                                    <img src="../../assets/组 221.png" alt="">
                                </i>
                                <span>
                                    <router-link to="index" class="agreeme">关于我们</router-link>
                                </span>
                            </div>
                            <div class="companylist" ref="paita">
                                <div @click="show(item.type)" v-for="item in list" :key="item.type"
                                    :class="{ fixback: item.type === activeType }">
                                    <span :class="{ fixfont: item.type === activeType }">{{ item.title }}</span>
                                    <i v-if="item.type !== activeType">
                                        <img src="../../assets/路径 65.png" alt="">
                                    </i>
                                    <i v-else>
                                        <img src="../../assets/路径 652.png" alt="">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="introduce introduceintroduce" style="width: 900px !important;">
                        <navi-gation class="agreenav"></navi-gation>
                        <div class="logofon">
                            <!-- <div class="logimgo">
                                <img src="../../assets/写字猫.png" alt="">
                            </div> -->
                            <!-- 公司介绍 -->
                            <div class="logimgt">
                                <p v-show="this.activeType===1" style="font-weight:bolder;color:#444">公司介绍</p>
                                <p v-show="this.activeType===2" style="font-weight:bolder;color:#444">企业文化</p>
                                <p v-show="this.activeType===3" style="font-weight:bolder;color:#444">公司风采</p>
                                <!--<img src="../../assets/公司介绍.png" alt="" v-show="activeType === 1">
                                <img src="../../assets/企业文化.png" alt="" v-show="activeType === 2">
                                <img src="../../assets/公司风采.png" alt="" v-show="activeType === 3">-->
                            </div>
                        </div>

                        <!-- 关于我们 右侧数据 -->
                        <div class="AboutUs guanyuaboyutus">
                            <div v-html="this.CompanyIntroduction" v-show="activeType === 1"></div>
                            <div class="culture" v-html="this.culture" v-show="activeType === 2"></div>
                            <div v-html="this.CompanyElegantDemeanour" v-show="activeType === 3"></div>
                        </div>
                    </div>
                </div>
            </div>

            <index-tail></index-tail>
        </div>
        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import IndexTail from '../IndexTail.vue'
import NaviGation from '../NaviGation.vue'
import { getUerInfo,culture,CompanyElegantDemeanour } from '@/request/api'
import {customerServiceQrCode} from '@/request/api'

export default {
    name: 'AgreeOur',
    components: {
        IndexHeat,
        IndexTail,
        NaviGation
    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        all() {
            this.$router.push('/GameList')
        },
        protect() {
            this.$router.push('/AgencyMortgage')
        },
        index() {
            this.$router.push('/index')
        },
        TransactionSecurity() {
            this.$router.push('/TransactionSecurity')
        },
        TradingInformation() {
            this.$router.push('/TradingInformation')
        },
        account() {
            this.$router.push('/AccountTransactions')
        },
        bidding() {
        this.$router.push('/Bidding')
        },
        show(item) {
            this.activeType = item

        },
        // 公司介绍
        async getUerInfofun() {
            await getUerInfo().then((res)=>{
                this.CompanyIntroduction=res.contentText
            })
           
        },
        // 企业文化
        async culturefun(){
            await culture().then((res)=>{
                this.culture = res.contentText
            })
        },
        // 公司风采
        async CompanyElegantDemeanourfun(){
            await CompanyElegantDemeanour().then((res)=>{
                this.CompanyElegantDemeanour = res.contentText
            })
        },
    },
    data() {
        return {
            list: [
                { title: '公司介绍', type: 1 },
                { title: '企业文化', type: 2 },
                { title: '公司风采', type: 3 },
            ],
            activeType: 1,
            //企业文化
            culture: ``,
            // 公司介绍
            CompanyIntroduction: ``,
            // 公司风采
            CompanyElegantDemeanour: ``,
            qrDialogVisible: false,
            serviceQrCode: '',
        }
    },
    mounted() {
        this.getUerInfofun()
        this.culturefun()
        this.CompanyElegantDemeanourfun()
    }
}
</script>

<style lang="less">
.agreewithme {
    border-top: 1px solid #707070;
    background-color: #fff;
}

.idnexheat {
    background-color: #fff;
    padding-bottom: 10px;
}

.agree {
    background-color: #0076FF;
    color: #fff !important;
    border-radius: 8px;
}

.company {
    width: 1900px;
    height: auto;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    padding-bottom: 250px;
}

.minbox {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
}

.fill {
    width: 100vw;
    height: 20px;
    background-color: #f6f6f6;
}

.agreeour {
    width: 290px;
    height: 329px;
}

.introduce {
    display: flex;
    flex-direction: column;
    width: 900px ;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
}

.our {
    width: 100%;
    height: 233px;
    background-color: #fff;
}

.agreew {
    display: flex;
    width: 290px;
    height: 70px;
    background: url('../../assets/有纹理的背景4.svg') no-repeat;
    background-size: 100% 100%;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.agreew i {
    width: 21.79px;
    height: 21.81px;
}

.agreew i img {
    width: 100%;
    height: 100%;
}

.agreeme {
    font-size: 20px;
    font-weight: bold;
    color: #FBF6F6;
    text-decoration: none;
}

.companylist {
    display: flex;
    flex-direction: column;
}

.companylist div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 185px;
    height: 50px;
    padding-left: 105px;
}

.fixback {
    background-color: #0076FF;
}

.fixfont {
    color: #FBF6F6 !important;
}

.companylist div i {
    margin-right: 12px;
}

.companylist div:nth-child(1) {
    margin-top: 16px;
    color: #333;
}

.companylist div span {
    font-size: 16px;
    color: #333;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
}

.logofon {
    display: flex;
    margin: 10px 0 0 20px;
}

.logimgo {
    width: 50px;
    height: 60px;
}

.logimgt {
    width: 348px;
    height: 52px;
}

.logimgo img,
.logimgt img {
    width: 100%;
    height: 100%;
}

.AboutUs {
    position: relative;
    margin: 10px 0 0 20px;
    img{
        max-width: 800px !important;
    }
}

.agreenav {
    position: absolute;
    top: 0px;
    right: -200px !important;
}

.abs {
    position: absolute;
    top: 39px;
    left: 214px;
}

.abss {
    position: absolute;
    bottom: 380px;
    left: 80px;
}

.guanyuiur{
    height: 220px !important;
}
.guanyuaboyutus{
    margin-left: 70px !important;
}
.introduceintroduce{
    width: auto !important;
    padding-right: 20px;
    margin-left: 20px;
}
</style>
