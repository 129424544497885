<!--
 * @Author: ygq
-->
<template>
  <div id="app">
      <div class="cont_right">
        <div class="butdiv">
          <img src="../assets/gmlogo.png" alt="">
          <div class="serve">
            <span><i>答案网游服务网</i></span>
            <span></span>
            <span>让账号交易享受法律保护</span>
          </div>
        </div>
        <div class="butdiv line"><img src="../assets/P}TXXO~71M[QRHJI0P31[UF.png" alt=""></div>
        <div class="butdiv">
          <div class="agreement">
            <li @click="indexfun">首页</li>
            <li>|</li>
            <li @click="fuwufang">服务协议</li>
            <li>|</li>
            <li @click="yewufun">业务介绍</li>
            <li>|</li>
            <li @click="zhaohuifun">找回赔偿</li>
            <li>|</li>
            <li>追回案例</li>
            <li>|</li>
            <li>法务介绍</li>
            <li>|</li>
            <li>公司价值</li>
          </div>
          <div class="record">
            <a href="http://beian.miit.gov.cn" class="beian"><li>蜀ICP备2024062281号-2</li></a>
            <!-- <li>浙B2-20210138</li> -->
          </div>
          <div class="copyright"><li>版权所有 <span>答案网游服务网</span> All Rights Reserved 抄袭必究</li></div>
          <div class="pot">
            <img class="potpro" src="../assets/shimingren.png" alt="">
            <img src="../assets/quanweiren.png" alt="">
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods:{
    indexfun(){
      this.$router.push('/index')
    },
    zhaohuifun(){
      this.$router.push('/TransactionSecurity')
    },
    yewufun(){
      this.$router.push('/TradingInformation')
    },
    fuwufang(){
      this.$router.push('/TradingInformation')
    }
  }
}
</script>

<style lang="less">
.beian{
  text-decoration: none;
}
    .cont_right{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 301px;
        background-color: #fff;
        /* border: 1px solid red; */
        margin-top: 20px;
      }
      .butdiv{
        margin-top: 89px;
      }
      .cont_right div:nth-child(1){
        display: flex;
      }
      .cont_right div:nth-child(1) div{
        display: flex;
        flex-direction: column;
      }
      .cont_right div:nth-child(1) img:nth-child(1){
        width: 108px;
        height: 106px;
      }
      
      .cont_right div:nth-child(2) img{
        height: 100px;
        width: 20px;
      }
      .serve span{
        margin-top: 8px;
      }
      .serve :nth-child(1){
        font-size: 30px;
        font-weight: bold;
        color: #333333;
      }
      .serve :nth-child(2){
        width: 200px;
        position: relative;
        height: 20px;
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        margin-left: 59px;
      }
      .serve :nth-child(2)::before{
        content: '';
        position: absolute;
        top: 7px;
        left: -40px;
        width: 40px;
        height: 5px;
        background-color: #0076FF;
      }
      .serve :nth-child(2)::after{
        content: '';
        position: absolute;
        top: 7px;
        right: 67px;
        width: 40px;
        height: 5px;
        background-color: #0076FF;
      }
      .serve :nth-child(3){
        font-size: 12px;
        font-weight: bold;
        color: #333333;
      }
      .gg{
        width: 30px;
        height: 30px;
        background-color: #0076FF;
      }
      .line{ 
        margin-left: 20px;
        margin-right: 20px;
      }
      .butdiv li{
        list-style: none;
      }
      .agreement li{
        color: #ACADAE;
        padding-right: 20px;
        cursor: pointer;
      }
      .record{
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
      }
      .record li{
        color: #999999;
        padding-right: 50px;
      }
      .copyright li{
        text-align: left;
        color: #E3D3D3;
      }
      .copyright li span{
        color: #C0C0C0;
      }
      .pot{
        margin-top: 30px;
        img{
          width: 135px;
          height: 45px;
        }
      }
      .potpro{
        margin-right: 30px;
      }
      .content{
        transform: translateX(-50px);
      }
</style>
