<!--
 * @Author: ygq
-->
<!-- 登录页 -->
<template>
    <div id="app">
        <div class="loginbox">
            <div class="yanzhengma" v-if="yanzm === 1">
                <img :src="tup" alt="" class="tupianee">
                <input type="text" v-model="yyy" name="" id="" placeholder="请输入验证码">
                <button @click="yanz">确定</button>
            </div>
            <!-- <web-thirty class="CustomerService" v-if="loginyz === 4"></web-thirty> -->
            <!-- <div class="logosImgs">
                <img src="../../assets/miaomiyouxifuwuwang.png" alt="">
                <span>游戏交易·账号代售·买卖担保</span>
            </div> -->
            <div class="backgr">
                <!-- <img src="../../assets/登录页背景图2.png" alt=""> -->
                <!-- 用户登录 -->
                <div id="logbox" class="logbox" v-if="loginyz === 1||loginyz === 2">
                    <p>
                        <span :class="{ logboxbold: loginyz === 1 }" @click="loginyz=1">账号密码</span>
                        <span :class="{ logboxbold: loginyz === 2 }" @click="switchWeixin">微信登录</span>
                    </p>
                    <div v-if="loginyz === 1" class="loginp">
                        <img src="../../assets/路径 131.png" alt="" class="user">
                        <img src="../../assets/路径 130.png" alt="" class="pass">
                        <input @keydown.enter="login()" type="text" v-model="Login.phone" placeholder="请输入账号"
                            ref="username">
                        <input @keydown.enter="login()" type="password" v-model="Login.password" placeholder="请输入密码"
                            ref="userpassword">
                    </div>
                    <!-- <div id="wxLoginContainer" style="display: flex;flex-direction: column;align-items: center;height: 0px;visibility: hidden">
                    </div> -->
                    <span class="question">
                        <span @click="register(4)">登录遇到问题</span>
                        <span>|</span>
                        <span @click="register(3)">注册新账号</span>
                    </span>
                    <div class="loginp">
                        <button class="btn" @click="usersLoginfun()">登录</button>
                        <div class="logo">
                            <img src="../../assets/gmlogo.png" alt="">
                        </div>
                    </div>
                    <div class="returnindex" @click="returnindex()">
                        <img src="../../assets/fanhuishouye.png" alt="">
                        <span>返回首页</span>
                    </div>


                </div>
                <!-- 注册新账号 -->
                <div class="logbox two" v-if="loginyz === 3" style="margin-top: -5% !important;">
                    <p>
                        <span class="logboxbold">免费注册</span>
                    </p>
                    <div class="loginp">
                        <img src="../../assets/路径 131.png" alt="" class="user">
                        <img src="../../assets/suo.png" alt="" class="pass">
                        <img src="../../assets/路径 130.png" alt="" class="pass passwowowo">
                        <img src="../../assets/路径 130.png" alt="" class="pass passwowowowowo">
                        <div class="shoujihaoma">
                            <input type="text" class="shoujihao" placeholder="手机号" v-model.trim="numPassnum.phone"
                                ref="telephonenumber" @keyup="judge()">
                            <span class="huoquyanzhengma" ref="getnum" @click="getNumber">获取验证码</span>
                        </div>
                        <input type="password" placeholder="请输入验证码" v-model.trim="numPassnum.code">
                        <input type="password" placeholder="请输入密码" v-model.trim="password1"
                            ref="userpasswordes">
                        <input type="password" placeholder="请再次输入密码" v-model.trim="numPassnum.password">
                        <img v-show="loginphone === 99" class="location" src="../../assets/cuow.png" alt="">
                        <img v-show="loginphone === 98" class="location" src="../../assets/zhengque.png" alt="">
                    </div>
                    <span class="question" @click="register(1)">
                        <span>去登录</span>
                        <span>|</span>
                        <span>已有账号</span>
                    </span>
                    <div class="loginp">
                        <button class="btn" @click="userRegisteredfun()">注册</button>
                        <div class="logo">
                            <img src="../../assets/gmlogo.png" alt="">
                        </div>
                    </div>
                    <div class="returnindex" @click="returnindex()">
                        <img src="../../assets/fanhuishouye.png" alt="">
                        <span>返回首页</span>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
        <div id="wxloginContainer" :style="{visibility:wxloginDialogVisible?'visible':'hidden'}" style="width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.9);position:absolute;display: flex;justify-content: center;align-items: center;">
            <!-- <iframe id="weixinFrame" @load="handleIframeLoad" style="height: 400px;border: none;" src="https://open.weixin.qq.com/connect/qrconnect?appid=wxe61888b3854a64ef&scope=snsapi_login&redirect_uri=http://www.zhijiancode.com&state=login&login_type=jssdk&self_redirect=false&styletype=&sizetype=&bgcolor=&rst=&style=white">
            </iframe> -->
        </div>
    </div>
</template>

<script>
import WebThirty from '../details/WebThirty.vue'
import { encrypt, userRegistered, usersLogin, code, codeCheck } from '@/request/api'
import serverConfig from '../../request/config.js';
import {customerServiceQrCode} from '@/request/api'

export default {
    name: 'LoginPage',
    components: {
        WebThirty

    },
    data() {
        return {
            uuid: '',
            yyy: '',
            yanzm: 0,
            tup: '',
            loginyz: 1,
            loginphone: 1,
            password1:'',
            // 注册
            numPassnum: {
                password: '',
                phone: '',
                code:'',
            },
            // 登录
            Login: {
                password: '',
                phone: '',
            },
            qrDialogVisible: false,
            serviceQrCode: '',
            wxloginDialogVisible: false,
        }
    },
    created(){
    },
    mounted(){
        
        let code = this.$route.query.code;
        console.log('code', code)
        if(code!=undefined){
            let that = this;
            setTimeout(()=>{
                let loginbox = document.getElementById("logbox");
                if(loginbox!=undefined){
                    loginbox.style.display='none';
                }
            },100);
            
            this.$axios({
                method: 'post',
                url: serverConfig.baseURL + '/web/userAccount/wxlogin',
                data: {
                    code: code
                },
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
            })
                .then((res) => {
                    console.log(res)
                    that.storeUserInfo(res.data)
                    console.log('storeUserInfo ok')
                    setTimeout(() => {
                        that.$router.push('/index')
                    }, 100)
                })
                .catch((error) => {
                    console.log(error);
                    that.$router.push('/login')
                    // this.$message.error('无效失败' + error);
                });
        }
    },
    methods: {
        handleIframeLoad(e){
            console.log('weixinFrame1:', e);
            let weixinFrame = document.getElementById("weixinFrame");
            if (weixinFrame) {      
                console.log('weixinFrame2:', weixinFrame);
                console.log('weixinFrame.src:', weixinFrame.src);      
            }
        },
        closeWxLoginDialog(){
            this.wxloginDialogVisible = false;
        },
        switchWeixin(){
            document.getElementById("logbox").style.display = 'none';
            window.location = 'https://open.weixin.qq.com/connect/qrconnect?appid=wx775e614d4f0c9366&scope=snsapi_login&redirect_uri=http://www.qumaihao.cn/login&state=login&login_type=jssdk&self_redirect=true&styletype=&sizetype=&bgcolor=&rst=&style=white'
            // document.getElementById("wxloginContainer").innerHTML = "";
            // this.wxloginDialogVisible = true;
            // let ifr = document.createElement('iframe');
            // ifr.id = "weixinFrame"
            // ifr.style.border = 'none';
            // ifr.style.height = '400px';
            // ifr.src = "https://open.weixin.qq.com/connect/qrconnect?appid=wxe61888b3854a64ef&scope=snsapi_login&redirect_uri=http://www.zhijiancode.com&state=login&login_type=jssdk&self_redirect=true&styletype=&sizetype=&bgcolor=&rst=&style=white";
            // // document.body.appendChild(ifr);
            // ifr.onload = this.handleIframeLoad
            // document.getElementById("wxloginContainer").appendChild(ifr);

            // setTimeout(()=>{
            //     var obj = this.wxlogin({
            //         self_redirect: false,
            //         id: "wxLoginContainer", 
            //         appid: "wxe61888b3854a64ef", 
            //         scope: "snsapi_login", 
            //         redirect_uri: "http://www.zhijiancode.com",
            //         state: "login",
            //         style: "white",
            //         href: ""
            //     });
            // },300)

            // document.getElementsByClassName("impowerBox")[0].getElementsByClassName("title")[0].style.display='none'
        },
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        returnindex() {
            localStorage.removeItem('username')
            localStorage.removeItem('accountId')
            localStorage.removeItem('phone')
            localStorage.removeItem('realName')
            localStorage.removeItem('HeadPortrait')
            localStorage.removeItem('name')
            this.$router.push('/index')
        },
        register(type) {
            if(type == 4){
                this.showCustomerServiceQrCode()
            }else{
                this.loginyz = type
            }
        },
        async yanz() {
            let phone = this.numPassnum.phone.replace(/\s*/g, "")
            let phones = {phone,uuid:this.uuid}
            let codenum = { code: this.yyy, uuid: this.uuid }
            await codeCheck(codenum).then((res) => {
                console.log(res)
                if (res === false) {
                    this.$message.error('验证码错误')
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                } else {
                    this.$message.success('验证成功！')
                    this.yanzm = 0
                    console.log(phones)
                    setTimeout(() => {
                        this.$axios({
                        method:'post',
                        url: serverConfig.baseURL + '/web/userAccount/smsCode',
                        headers:{
                            'Content-Type': 'application/json;charset=UTF-8',
                        },
                        data:phones
                    })
                    }, 2000);
                    // 短信倒计时 ↓
                    let btn = this.$refs.getnum
                    var time, timer
                    if (btn.getAttribute("flag") == "false" || btn.getAttribute("flag") == false) {
                        return false;
                    }
                    btn.setAttribute("flag", false);
                    time = 60;
                    clearInterval(timer); //清除计时器
                    btn.innerText = time + "s";
                    btn.classList.add("getcode-disabled")
                    timer = setInterval(function () {
                        time--;
                        btn.innerText = time + "s";
                        if (time <= 0) {
                            //重置获取验证码按钮状态，变为可点击，即可再次获取
                            clearInterval(timer); //清除计时器
                            btn.classList.remove("getcode-disabled");
                            btn.innerText = "获取验证码";
                            btn.setAttribute("flag", true);
                        }
                    }, 1000);
                    // ↑
                }
            })
        },
        async getNumber() {
            let phone = this.numPassnum.phone.replace(/\s*/g, "")
            if (phone === '') return this.$message.error('请输入手机号')
            await code().then((res) => {
                this.uuid = res.uuid
                this.tup = res.img
                this.yanzm = 1
            })
        },
        judge() {
            let phonenumbuer = this.$refs.telephonenumber
            // loginyz
            let judge = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
            if (!judge.test(phonenumbuer.value)) {
                this.loginphone = 99
            } else {
                this.loginphone = 98
            }
        },
        // 用户注册
        async userRegisteredfun() {
            let phone = this.numPassnum.phone.replace(/\s*/g, "")
            let password = this.numPassnum.password.replace(/\s*/g, "")
            let phoneyz = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
            if (phone === '' || password === '') {
                return this.$message.warning('请输入手机或密码！')
            }
            if (!phoneyz.test(phone)) {
                return this.$message.error('请输入正确的手机号')
            }
            if(this.password1!==this.numPassnum.password) return this.$message.error('两次密码不相同')
            if(this.numPassnum.yzm==='') return this.$message.error('请输入验证码')
            if (password.length < 8 || password.length > 16) {
                return this.$message.error('密码必须在8-16位')
            }
            let passwords = password
            let params = {...this.numPassnum}
            params.password = encrypt(password)
            await userRegistered(params).then((res) => {
                if (res) {
                    if(!res.message){
                        this.$message.success('注册成功')
                        this.numPassnum.password = passwords
                        setTimeout(() => {
                            this.$router.push('/index')
                        }, 1000)
                    }
                    
                } else {
                    this.numPassnum.password = passwords
                    return this.$message.success(res.message)
                    
                }

            })
        },

        storeUserInfo(res){
            if(!res.message){
                        this.$message.success('登录成功')
                    }
                    // 本地缓存 token
                    localStorage.setItem('username', res.token)
                    localStorage.setItem('name', res.user.user.nickName)//玩家名字
                    if (!res.user.user.HeadPortrait) {
                        localStorage.setItem('HeadPortrait', 'https://p1.ssl.qhimgs1.com/sdr/400__/t01b4efa4b32349258b.jpg')//玩家头像
                    } else {
                        localStorage.setItem(res.user.user.HeadPortrait)//玩家头像
                    }

                    localStorage.setItem('accountId', res.user.user.accountId)//玩家ID
                    localStorage.setItem('realName', res.user.user.isCertification)//实名状态
                    localStorage.setItem('phone', res.user.user.phone)//手机号
                    localStorage.setItem('accountBalance', res.user.user.accountBalance)//账户余额
                    localStorage.setItem('actualName', res.user.user.actualName)//真实姓名
                    localStorage.setItem('idCard', res.user.user.idCard)//身份证号
                    // localStorage.setItem('avatarPath', res.user.user.avatarPath)//用户头像地址
                    // 传网名和头像到 vuex
                    if (!res.user.user.HeadPortrait) {
                        this.$store.commit(
                            'LoginValue',
                            {
                                name: res.user.user.nickName,//玩家名字
                                HeadPortrait: 'https://p1.ssl.qhimgs1.com/sdr/400__/t01b4efa4b32349258b.jpg',//玩家头像
                                accountId: res.user.user.accountId, //玩家ID
                                realName: res.user.user.isCertification, //实名状态
                                phone: res.user.user.phone,//手机号
                            }
                        )
                    } else {
                        this.$store.commit(
                            'LoginValue',
                            {
                                name: res.user.user.nickName,//玩家名字
                                HeadPortrait: res.user.user.HeadPortrait,//玩家头像
                                accountId: res.user.user.accountId, //玩家ID
                                realName: res.user.user.isCertification, //实名状态
                                phone: res.user.user.phone,//手机号
                            }
                        )
                    }
        },
        // 用户登录
        async usersLoginfun() {
            let phone = this.Login.phone.replace(/\s*/g, "")
            let password = this.Login.password.replace(/\s*/g, "")
            if (phone === '' || password === '') {
                return this.$message.warning('请输入手机或密码！')
            }
            let passwords = password

            let params = {...this.Login}
            params.password = encrypt(password)
            await usersLogin(params).then((res) => {
                if (res) {
                    this.storeUserInfo(res)

                    let that = this

                    setTimeout(() => {
                        that.$router.push('/index')
                    }, 1000)
                } 
                // else {
                //     this.$message.warning('用户名或密码错误')
                // }
                this.Login.password = passwords
            })
        },
    }
}
</script>

<style lang="less">
* {
    margin: 0;
    padding: 0;
}

.yanzhengma {
    position: absolute;
    background-color: #ffffff;
    top: 50%;
    transform: translate(0,-156px);
    right: 60px;
    width: 365px;
    height: 312px;
    z-index: 9999;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid;
}

.yanzhengma img {
    position: absolute;
    left: 18%;
    top: 63px;
    width: 200px;
    z-index: 99;
    height: 110px;
}

.yanzhengma input {
    margin: 200px auto;
    width: 100px;
    height: 40px;
    font-size: 15px;
    text-align: center;
}

.yanzhengma button {
    transform: translateY(-175px);
    width: 63px;
    height: 25px;
    margin: 0 auto;
    border: none;
    background-color: #0076FF;
    color: #ffffff;
    padding: 10px 0;
    line-height: 7px;
}

.tupianee {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
}

.shoujihao {
    width: 170px !important;
    padding-right: 110px;
}

.shoujihaoma {
    position: relative;
}

.huoquyanzhengma {
    cursor: pointer;
    position: absolute;
    text-align: center;
    width: 116px;
    right: -10px;
    top: 38px;
    font-size: 14px;
    color: #0076FF;
}

.passwowowo {
    top: 177px !important;
}

.passwowowowowo {
    top: 245px !important;
}

.CustomerService {
    position: absolute;
    top: 80px;
    left: 410px;
    z-index: 999;
}

.location {
    position: absolute;
    right: 8px;
    top: 40px;
    width: 20px !important;
    height: 20px !important;
}

.getnumber {
    top: 30px !important;
}

.authCode {
    position: absolute;
    top: 98px;
    right: 28px;
    height: 37px;
    width: 100px;
    border: none;
    background-color: #0076FF;
    color: #fff;
    border-radius: 8px;
}

.two {
    height: 480px !important;
    padding-bottom: 60px;
}

.miaomi {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
}

.backgr {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    background: url('.././../assets/loginbg.webp') no-repeat;
    background-size: 100% 100%;
}

// .backgr::before {
//     content: '';
//     position: fixed;
//     left: 0;
//     top:  0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.8);
// }

// .backgr img {
//     width: 100vw;
//     height: 100vh;
// }

.logbox {
    position: absolute;
    top: 40%;
    right: 30px;
    width: 365px;
    height: 412px;
    transform: translate(-30px,-152px);
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.16);
}

.logbox p {
    /* text-align: center; */
    display: flex;
    border-bottom: 1px solid #D0D0D0;
    font-size: 18px;
    color: #B1B1B1;
}

.logbox p span {
    cursor: pointer;
    display: block;
    margin: 20px 0 20px 60px;
}

.logboxbold {
    color: #333333;
    font-weight: bold;
}

.loginp {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginp input {
    margin-top: 30px;
    width: 280px;
    padding-left: 25px;
    height: 36px;
    border: 0.3px solid #C0C0C0;
    border-radius: 4px;
    outline: none;
    color: #B1B1B1;
}

.loginp img {
    width: 15px;
    height: 15px;
}

.user {
    position: absolute;
    top: 41px;
    left: 35px;
    z-index: 99;
}

.pass {
    position: absolute;
    top: 109px;
    left: 35px;
}

.passes {
    position: absolute;
    top: 176px;
    left: 35px;
}

.question {
    display: flex;
    flex-direction: row-reverse;
    color: #B1B1B1;
}

.question span {
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
}

.question span:nth-child(1) {
    margin-right: 30px;
}

.question span:nth-child(2) {
    margin: 0 10px;
    margin-top: 20px;
}

.btn {
    width: 231px;
    height: 51px;
    background-color: #0076FF;
    border: none;
    border-radius: 8px;
    margin: 20px auto;
    color: #fff;
}

.logo {
    width: 58px;
    height: 58px;
}

.logo img {
    width: 100%;
    height: 100%;
}

.returnindex {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -80px;
    left: 160px;

}

.returnindex img {
    width: 28px !important;
    height: 29px !important;
}

.returnindex span {
    color: #fff;
    margin-top: 10px;
    transform: translateX(-20px);
}

.maomi {
    position: absolute;
    width: 782px;
    height: 294px;
    z-index: 999;
    margin-top: 268px;
    margin-left: 265px;
}

.youxijiaoyizhanghao {
    position: absolute;
    /* width: 312px; */
    height: 33px;
    font-size: 25px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    z-index: 999;
    margin-top: 545px;
    margin-left: 451px;
}


/* Nest Hub Max */
@media screen and (max-width:1280px) {
    /* .logbox {
        top: 230px;
        right: 80px;
    } */
}

/* iPad Pro */
@media screen and (max-width:1025px) {
    /* .logbox {
        top: 430px;
        right: 80px;
    } */
}

/* Surface Pro 7 */
@media screen and (max-width:913px) {
    /* .logbox {
        top: 400px;
        right: 35px;
    } */
}


/* surface Duo */
@media screen and (max-width:541px) {
    /* .logbox {
        top: 400px;
        right: 80px;
    } */
}

/* iPhone XR  */
@media screen and (max-width:415px) {
    /* .logbox {
        top: 155px;
        right: 40px;
        width: 330px;
    } */

    .loginp input {
        width: 250px;
    }
}

/* iPhone 12 Pro */
@media screen and (max-width:391px) {
    /* .logbox {
        top: 155px;
        right: 30px;
        width: 330px;
    } */
}

/* Pixel 5 */
@media screen and (max-width:394px) {
    /* .logbox {
        top: 155px;
        right: 30px;
        width: 330px;
    } */
}

/* iPhone X */
@media screen and (max-width:376px) {
    /* .logbox {
        top: 155px;
        right: 20px;
        width: 330px;
    } */

    .loginp input {
        width: 240px;
        padding-left: 41px;
    }

    .btn {
        color: #fff;
    }
}

/* Samsung Galaxy s8+ */
@media screen and (max-width:361px) {
    /* .logbox {
        top: 155px;
        right: 15px;
        width: 330px;
    } */
}

/* iPhone 4 */
@media screen and (max-width:321px) {
    /* .logbox {
        top: 155px;
        right: 20px;
        width: 280px;
    }

    .logbox p span {
        margin: 20px 0 20px 45px;
    } */

    .loginp input {
        width: 200px;
    }

    .returnindex {
        transform: translateX(-25px);
    }
}

.logosImgs{
    width: 50%;
    position: relative;
    z-index: 99;
    height: 400px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(0,-200px);
    color: white;
    font-size: 25px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    img{
        width: 100%;
        height: auto;
    }
}

</style>
<!-- 登录页 -->
