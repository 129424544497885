
// @Author: ygq

const serverConfig = {
  // baseURL: 'http://192.168.31.248:8000', // 请求基础地址,可根据环境自定义
  // baseURL: 'http://test.yobibyte.info:8000',
  // baseURL: 'https://www.mmds8.com/api',
  // baseURL: 'http://gm0207.yobibyte.info:18010',
  baseURL: 'http://api.qumaihao.cn:18010/api',
  // baseURL: 'http://127.0.0.1:18010',
  useTokenAuthorization: true, // 是否开启 token 认证
};
export default serverConfig;
