<!--
 * @Author: ygq
-->
<template>
    <div id="app">
        <!-- 交易保障 -->

        <div class="maxbox">
            <index-heat class="indexheat"></index-heat>
            <div class="h_midmax transaction">
                <div class="h_mid">
                    <div @click="index()" class="webindex">
                        <span>网站首页</span>
                    </div>
                    <div class="navlist">
                        <li @click="all()">全部游戏</li>
                        <li @click="bidding()">竞价中心</li>
                        <li @click="account()">我要卖号</li>
                        <li @click="protect()">担保中介</li>
                        <li @click="TradingInformation()">交易信息</li>
                        <li class="agree">交易保障</li>
                        <li @click="agree()">关于我们</li>
                    </div>
                </div>
            </div>
            <div class="company">
                <i class="fill"></i>
                <div class="minbox">
                    <div class="agreeour">
                        <div class="our">
                            <div class="agreew">
                                <i>
                                    <img src="../../assets/路径 90.png" alt="">
                                </i>
                                <span>
                                    <router-link to="index" class="agreeme">交易保障</router-link>
                                </span>
                            </div>
                            <div class="companylist">
                                <div @click="find(item.type)" v-for="item in list" :key="item.type"
                                    :class="item.type === activeType ? 'fixback' : 'companylistDiv'">
                                    <span :class="{ fixfont: item.type === activeType }">{{ item.title }}</span>
                                    <i v-if="item.type !== activeType">
                                        <img src="../../assets/路径 65.png" alt="">
                                    </i>
                                    <i v-else>
                                        <img src="../../assets/路径 652.png" alt="">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="introduce">
                        <navi-gation class="transnav"></navi-gation>
                        <div class="logofon">
                            <!-- <div class="logimgo">
                                <img src="../../assets/天平猫.png" alt="">
                            </div> -->
                            <!-- 公司介绍 -->
                            <div class="logimgt">
                                <p v-show="this.activeType===1" style="font-weight:bolder;color:#444">找回赔偿</p>
                                <p v-show="this.activeType===2" style="font-weight:bolder;color:#444">售后服务</p>
                                <p v-show="this.activeType===3" style="font-weight:bolder;color:#444">售后案例</p>
                                <p v-show="this.activeType===4" style="font-weight:bolder;color:#444">防骗提醒</p>
                                <p v-show="this.activeType===5" style="font-weight:bolder;color:#444">未成年禁止参与交易</p>
                                <p v-show="this.activeType===6" style="font-weight:bolder;color:#444">包赔流程</p>
                                <p v-show="this.activeType===7" style="font-weight:bolder;color:#444">专业鉴定</p>
                                <!--<img src="../../assets/找回赔偿.png" alt="" v-show="this.activeType === 1">
                                <img src="../../assets/售后服务.png" alt="" v-show="this.activeType === 2">
                                <img src="../../assets/售后案例.png" alt="" v-show="this.activeType === 3">
                                <img src="../../assets/防骗提醒.png" alt="" v-show="this.activeType === 4">
                                <img src="../../assets/未成年禁止参与交易.png" alt="" v-show="this.activeType === 5">
                                <img src="../../assets/包赔流程.png" alt="" v-show="this.activeType === 6">
                                <img src="../../assets/专业鉴定.png" alt="" v-show="this.activeType === 7">-->
                            </div>
                        </div>
                        <!-- 公司介绍 -->
                        <div class="AboutUs AboutUsAboutUs">
                            <div v-html="this.RecoverDamages" v-show="this.activeType === 1"></div>
                            <div v-html="this.AfterSalesService" v-show="this.activeType === 2"></div>
                            <div v-html="this.AfterCase" v-show="this.activeType === 3"></div>
                            <div v-html="this.FraudPrevention" v-show="this.activeType === 4"></div>
                            <div v-html="this.eighteenForbidden" v-show="this.activeType === 5"></div>
                            <div v-html="this.bagpay" v-show="this.activeType === 6"></div>
                            <div v-html="this.professional" v-show="this.activeType === 7"></div>
                        </div>
                    </div>
                </div>
            </div>

            <index-tail></index-tail>
        </div>
        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import IndexTail from '../IndexTail.vue'
import NaviGation from '../NaviGation.vue'
import {customerServiceQrCode} from '@/request/api'
import {
    RecoverDamages,
    AfterSalesService,
    AfterCase,
    FraudPrevention,
    eighteenForbidden,
    bagpay,
    professional
} from '@/request/api'

export default {
    name: 'AgreeOur',
    components: {
        IndexHeat,
        IndexTail,
        NaviGation
    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        all() {
            this.$router.push('/GameList')
        },
        protect() {
            this.$router.push('/AgencyMortgage')
        },
        index() {
            this.$router.push('/index')
        },
        find(type) {
            this.activeType = type
        },
        agree() {
            this.$router.push('/AgreeOur')
        },
        TradingInformation() {
            this.$router.push('/TradingInformation')
        },
        account() {
            this.$router.push('/AccountTransactions')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        TransactionSecurity() {

        },
        // 找回赔偿
        async RecoverDamagesfun() {
            await RecoverDamages().then((res) => {
                this.RecoverDamages = res.contentText
            })
        },
        // 售后服务
        async AfterSalesServicefun(){
            await AfterSalesService().then((res)=>{
                this.AfterSalesService = res.contentText
            })
        },
        // 售后案例
        async AfterCasefun(){
            await AfterCase().then((res) => {
                this.AfterCase = res.contentText
            })
        },
        // 防骗提醒
        async FraudPreventionfun(){
            await FraudPrevention().then((res)=>{
                this.FraudPrevention = res.contentText
            })
        },
        // 未成年禁止参与交易
        async eighteenForbiddenfun(){
            await eighteenForbidden().then((res)=>{
                this.eighteenForbidden = res.contentText
            })
        },
        // 包赔流程
        async bagpayfun(){
            await bagpay().then((res)=>{
                this.bagpay = res.contentText
            })
        },
        // 专业鉴定
        async professionalfun(){
            await professional().then((res)=>{
                this.professional = res.contentText
            })
        }
    },
    data() {
        return {
            show: 1,
            list: [
                { title: '找回赔偿', type: 1 },
                { title: '售后服务', type: 2 },
                { title: '售后案例', type: 3 },
                { title: '防骗提醒', type: 4 },
                { title: '未成年禁止参与交易', type: 5 },
                { title: '包赔流程', type: 6 },
                { title: '专业鉴定', type: 7 },
            ],
            activeType: 1,
            // 找回赔偿
            RecoverDamages: ``,
            AfterSalesService: ``,
            AfterCase: ``,
            FraudPrevention: ``,
            eighteenForbidden: ``,
            bagpay: ``,
            professional: ``,
            qrDialogVisible: false,
            serviceQrCode: '',
        }
    },
    mounted() {
        this.TransactionSecurity()
        // 找回赔偿
        this.RecoverDamagesfun()
        // 售后服务
        this.AfterSalesServicefun()
        // 售后案例
        this.AfterCasefun()
        // 防骗提醒
        this.FraudPreventionfun()
        // 未成年禁止参与交易
        this.eighteenForbiddenfun()
        // 包赔流程
        this.bagpayfun()
        // 专业鉴定
        this.professionalfun()
    }

}
</script>

<style lang="less">
.fixback {
    background-color: #0076FF;
}

.fixfont {
    color: #FBF6F6;
}

.transaction {
    border-top: 1px solid #707070;
    background-color: #fff;
}

.agree {
    background-color: #0076FF;
    color: #fff !important;
    border-radius: 8px;
}

.company {
    width: 1920px;
    height: auto;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    padding-bottom: 350px;
}

.minbox {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
}

.fill {
    width: 100vw;
    height: 20px;
    background-color: #f6f6f6;
}

.agreeour {
    width: 290px;
    height: 329px;
}

.introduce {
    display: flex;
    flex-direction: column;
    width: 900px;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
}

.our {
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: #fff;
}

.agreew {
    display: flex;
    width: 290px;
    height: 70px;
    background: url('../../assets/有纹理的背景4.svg') no-repeat;
    background-size: 100% 100%;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.agreew i {
    width: 21.79px;
    height: 21.81px;
}

.agreew i img {
    width: 100%;
    height: 100%;
}

.agreeme {
    font-size: 20px;
    font-weight: bold;
    color: #FBF6F6;
    text-decoration: none;
}

.companylist {
    display: flex !important;
    flex-direction: column !important;
}

.companylist div {
    width: 185px !important;
    height: 50px !important;
    display: flex !important;
    justify-content: space-between !important;
}

.companylistDiv {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 185px;
    height: 50px;
    padding-left: 105px;
    border-radius: 8px;
}

.companylist div i {
    margin-right: 12px;
}

.companylist div:nth-child(1) {
    margin-top: 16px;
    color: #333;
}

.companylist div span {
    font-size: 16px;
    color: #909090;
}

.companylist div:nth-child(1) span {
    /* color: #333; */
}

.logofon {
    display: flex;
    margin: 10px 0 0 20px;
}

.logimgo {
    width: 50px;
    height: 60px;
}

.logimgt {
    width: 348px;
    height: 52px;
}

.logimgo img,
.logimgt img {
    width: 100%;
    height: 100%;
}

.AboutUs {
    position: relative;
    margin: 10px 0 0 20px;
}

.transnav {
    position: absolute;
    top: 0px;
    right: -200px;
}

.abs {
    position: absolute;
    top: 39px;
    left: 214px;
}

.abss {
    position: absolute;
    bottom: 380px;
    left: 80px;
}
.AboutUsAboutUs{
    margin-left: 70px !important;
    div:nth-child(1){
        overflow-x: hidden;
    }
}
</style>
