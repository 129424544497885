<!--
 * @Author: ygq
-->
<!-- 账号交易 -->
<template>
    <div id="app">
        <index-heat class="zhanghaojiaoyi"></index-heat>
        <!-- <hr class="bordertop"> -->
        <div class="h_mid">
            <div class="webindex"  @click="index()">
                <span>网站首页</span>
            </div>
            <div class="navlist">
                <li @click="all()">全部游戏</li>
                <li @click="bidding()">竞价中心</li>
                <li class="agree">我要卖号</li>
                <li @click="protect()">担保中介</li>
                <li @click="TradingInformation()">交易信息</li>
                <li @click="TransactionSecurity()">交易保障</li>
                <li @click="agree()">关于我们</li>
            </div>
        </div>
        <div class="maxbox">
            
            <div class="fill"></div>
            <div class="mixbox" style="margin-top:10px">
                <navi-gation class="accoutnav"></navi-gation>
                <div class="gamelist">
                    <div>
                        <img src="../../assets/平台寄售.svg" alt="">
                    </div>
                </div>
                <div class="nav">
                    <li @click="jump(item.dictSort)" :class="{fixfonts:item.dictSort===active}" v-for="item in dictDetailCont" :key="item.dictSort">{{item.label}}</li>
                </div>
                <div class="navline" >
                    <li v-show="item.dictSort==active" v-for="item in dictDetailCont" :key="item.dictSort" ></li>
                </div>
                <div class="allgame" v-show="activeType===1">
                    <div class="alonegame" v-for="item in gamelist" :key="item.type" @click="hero(item)">
                        <div class="gamepto">
                            <img :src="item.iconPath" alt="">
                        </div>
                        <span>{{item.gameName}}</span>
                    </div>
                </div>
            </div>
            
            <div class="fill filllll"></div>
        </div>
        <index-tail></index-tail>
        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import IndexTail from '../IndexTail.vue'
import NaviGation from '../NaviGation.vue'
import {dictDetail,game} from '@/request/api'
import {customerServiceQrCode} from '@/request/api'

export default {
    name: 'GameList',
    components: {
        IndexHeat,
        IndexTail,
        NaviGation

    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        agree() {
            this.$router.push('/AgreeOur')
        },
        protect(){
            this.$router.push('/AgencyMortgage')
        },
        TransactionSecurity(){
            this.$router.push('/TransactionSecurity')
        },
        TradingInformation(){
            this.$router.push('/TradingInformation')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        all() {
            this.$router.push('/GameList')
        },
        // 查询游戏
        async jump(type){
            this.active=type
            let gamePlatforms={gamePlatform:Number(this.dictDetailCont[type].value)}
            await game(gamePlatforms.gamePlatform!==0?gamePlatforms:'').then((res) =>{
                this.gamelist=res
            })
        },
        index() {
            this.$router.push('/index')
        },
        hero(type){
            this.$router.push({
                path:'/HeroAlliance',
                query:type
            })
        },
        // 查询游戏字段
        // 查询游戏字段
        async dictDetailfun(){
            await dictDetail(this.dictDetailnum).then((res) =>{
                this.dictDetailCont = res.content
                this.dictDetailCont.unshift({
                    label:'全部',
                    dictSort:0,
                    value:''
                })
            })
        },
        async jumps(){
            await game().then((res) =>{
                this.gamelist=res
            })
        },
    },
    data(){
        return{
            list:[
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'英雄联盟',type:1},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'穿越火线',type:2},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'古剑奇谭',type:3},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'逆战',type:4},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'原神',type:5},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'梦幻三国',type:6},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'永劫无间',type:7},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'魔兽世界',type:8},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'星际争霸',type:9},
                {url:'http://b.hiphotos.baidu.com/image/pic/item/e824b899a9014c08878b2c4c0e7b02087af4f4a3.jpg',title:'天涯明月刀',type:10},
            ],
            listtwos:[
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'英雄联盟',type:1},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'穿越火线',type:2},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'古剑奇谭',type:3},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'逆战',type:4},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'原神',type:5},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'梦幻三国',type:6},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'永劫无间',type:7},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'魔兽世界',type:8},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'星际争霸',type:9},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'天涯明月刀',type:10},
            ],
            listthrees:[
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'英雄联盟',type:1},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'穿越火线',type:2},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'古剑奇谭',type:3},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'逆战',type:4},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'原神',type:5},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'梦幻三国',type:6},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'永劫无间',type:7},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'魔兽世界',type:8},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'星际争霸',type:9},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'天涯明月刀',type:10},
            ],
            listfours:[
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'英雄联盟',type:1},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'穿越火线',type:2},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'古剑奇谭',type:3},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'逆战',type:4},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'原神',type:5},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'梦幻三国',type:6},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'永劫无间',type:7},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'魔兽世界',type:8},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'星际争霸',type:9},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'天涯明月刀',type:10},
            ],
            listtwo:[
                {title:'全部',type:1},
                {title:'手游',type:2},
                {title:'端游',type:3},
            ],
            activeType:1,
            dictDetailCont:[],
            dictDetailnum:{
                dictName:'game_platform',
                page:0,
                size:9999,
            },
            active:0,
            // 游戏列表
            gamelist:[],
            qrDialogVisible: false,
            serviceQrCode: '',
        }
    },
    beforeMount(){
        this.dictDetailfun()
        this.jumps()
    }

}
</script>

<style>

.zhanghaojiaoyi{
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.bordertop{
    width: 100%;
    height: 1px;
    border-top: 1px solid #707070;
}

.maxbox {
    width: 100%;
    height: auto;
    background-color: #F6F6F6;
}

.fill {
    width: 100%;
    height: 20px;
    padding-bottom: 70px;
}

.mixbox {
    display: flex;
    flex-direction: column;
    width: 1200px;
    background-color: #fff;
    margin: 0 auto;
    padding-bottom: 120px;
}

.gamelist {
    display: flex;
    height: 58.18px;
    width: 100%;
    margin: 10px 0 0 10px;
}



.gamelist div:nth-child(1) {
    width: 188px;
    height: 52px;
}

.gamelist div:nth-child(1) img {
    width: 100%;
    height: 100%;
}

.nav {
    display: flex;
    margin: 20px 0 0 10px;
}

.nav li {
    cursor: pointer;
    list-style: none;
    color: #919191;
    font-size: 20px;
    width: 100px;
    height: 30px;
    line-height: 30px;
}



.navline {
    display: flex;
}

.navline li {
    list-style: none;
    width: 50px;
    height: 4px;
    margin-left: 5px;
    border-radius: 20px;
    background-color: #0076FF;
}

.navline li:nth-child(2) {
    margin-left: 53px;
}

.navline li:nth-child(3) {
    margin-left: 48px;
}

.navline li:nth-child(4) {
    margin-left: 50px;
    width: 88px;
}

.allgame {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    transform: translateX(5px);
}

.alonegame {
    width: 180px;
    height: 180px;
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 8px;
    margin-right: 16px;
    margin-top: 10px;
}
.gamepto{
    width: 80px;
    height: 80px;
    margin: 30px auto;
}
.gamepto img{
    width: 100%;
    height: 100%;
}
.alonegame span{
    display: block;;
    text-align: center;
    margin: -10px auto;
}
.accoutnav{
    position: absolute;
    top: 0px;
    right: -200px;
}
/* .fill:first-child{
  margin-top: 10px !important;
} */
</style>
