<!--
 * @Author: ygq
-->
<!-- web 1920-7 -->
<template>
    <div id="app">
        <index-heat></index-heat>
        <web-thirty class="webthirty" v-show="false"></web-thirty>
        <epics-kin class="epicskin" v-show="false"></epics-kin>
        <div class="h_mid">
            <div class="indexmax" @click="index()">
                <span @click="index()">网站首页</span>
            </div>
            <div>
                <li @click="all()">全部游戏</li>
                <li @click="bidding()">竞价中心</li>
                <li @click="account()">我要卖号</li>
                <li @click="protect()">担保中介</li>
                <li @click="TradingInformation()">交易信息</li>
                <li @click="TransactionSecurity()">交易保障</li>
                <li @click="agree">关于我们</li>
            </div>
        </div>
        <div class="fill"></div>

        <div class="contmax" style="margin-top:-60px">

            <div class="content">

                <div class="searchgames">
                    <div class=" gametop">
                        <div class="portrait">
                            <img :src="tztx" alt="">
                        </div>
                        <div class="portraitri">
                            <span>{{ upPagenum.gameName }}</span>
                            <span>共有{{ this.thisPagenums.totalElements }}个账号在出售</span>
                        </div>
                    </div>
                    <div class="gameprice">
                        <span>按价格：</span>
                        <li @click="screen(0,'','')" :class="{lifontbackcolor:screennum===0}">全部</li>
                        <li @click="screen(1,0,200)" :class="{lifontbackcolor:screennum===1}">200以下</li>
                        <li @click="screen(2,201,1000)" :class="{lifontbackcolor:screennum===2}">201-1000</li>
                        <li @click="screen(3,1001,3000)" :class="{lifontbackcolor:screennum===3}">1001-3000</li>
                        <li @click="screen(4,3001,5000)" :class="{lifontbackcolor:screennum===4}">3001-5000</li>
                        <li @click="screen(5,5000,'')" :class="{lifontbackcolor:screennum===5}">5000以上</li>
                        <div class="updi">
                            <input type="text" v-model="priceSerchDown" name="" id="" @keyup.enter="down()">
                            -
                            <input type="text" name="" v-model="priceSerchUp" id="" @keyup.enter="up()">
                        </div>
                    </div>
                    <!-- <div class="gameprice">
                        <span>区服筛选：</span>
                        <el-cascader v-model="value" :options="treeList" @change="handleChange"></el-cascader>
                    </div> -->
                    <div class="gameprice key">
                        <span>搜关键词：</span>
                        <input v-model="souguanjian" type="text">
                        <button @click="chongz">重置</button>
                        <button @click="shaix">筛选</button>
                    </div>
                    <div class="priceitems" @click="AccountDetails(item.productId)" v-for="(item, index) in this.thisPagenum"
                        :key="index">
                        <div class="ptempic">
                            <img :src="item.mainImgPath" alt="">
                        </div>
                        <div class="ptfont">
                            <span>{{ item.title }}</span>
                            <span>{{ item.description }}</span>
                            <span>{{ item.createTime }}</span>
                            <span>{{ item.gameService }}</span>
                        </div>
                        <div class="monny">￥{{ item.price }}</div>
                        <div class="shoping">
                            <button>立即购买</button>
                                               <button><a class="lianxikefuee"
                             target="_blank"
                             @click="showCustomerServiceQrCode"
                             >联系客服</a></button>
                        </div>
                    </div>

                    <!-- 分页 -->
                    <div class="paging">
                        <el-pagination
                          background
                          layout="prev, pager, next"
                          :current-page="SurePage"
                          @current-change="fenPage"
                          :total="sumPage">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="fill"></div>
            <!-- 底部 -->
            <div class="bottom">
                <IndexTail></IndexTail>
                <!-- <img src="../../assets/法律信息.png" alt=""> -->
            </div>
        </div>
        <div class="searches">

        </div>
        <navi-gation class="navigation"></navi-gation>
<el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import NaviGation from '../NaviGation.vue'
import EpicsKin from './EpicsKin.vue'
import WebThirty from './WebThirty.vue'
import IndexHeat from '../IndexHeat.vue'
import IndexTail from '../IndexTail.vue'
import { product, areaService,serviceQq } from '@/request/api'
import {customerServiceQrCode} from '@/request/api'
export default {
    name: 'TwentySeven',
    components: {
        NaviGation,
        EpicsKin,
        WebThirty,
        IndexHeat,
        IndexTail
    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        async serviceQqfun(){
    //   await serviceQq().then((res) =>{
    //     this.qqNum = res.serviceQq
    //   })
    },
        chongz(){
            this.screennum = 0
            this.priceSerchDown = null
            this.priceSerchUp = null
            this.souguanjian=''
            this.currentPage = 0
            this.shaix()
        },
        index() {
            this.$router.push('./index')
        },
        AccountDetails(type) {
            this.$router.push({
            path:'./AccountDetails',
            query:{type}
      })
        },
        agree() {
            this.$router.push('/AgreeOur')
        },
        details() {
            this.$router.push('./TwentySeven')
        },
        protect() {
            this.$router.push('/AgencyMortgage')
        },
        TransactionSecurity() {
            this.$router.push('/TransactionSecurity')
        },
        TradingInformation() {
            this.$router.push('/TradingInformation')
        },
        account() {
            this.$router.push('/AccountTransactions')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        all() {
            this.$router.push('/GameList')
        },
        // 查询商品
        async productfun() {
            await product(this.pageSize).then((res) => {
                this.thisPagenum = res.content
                this.thisPagenums = res
                this.sumPage = Math.ceil(res.totalElements /5)*10
            })
        },

        getTree1(tree, name, id, children) {
            function getTree(tree, name, id, children) {
                tree.forEach(item => {
                    item.label = item[name];
                    item.value = item[id];
                    item.children = item[children];
                    if (item.children && item.children.length > 0) {
                        getTree(item.children, name, id, children)
                    }
                })
            };
            getTree(tree, name, id, children)
            return tree;
        },

        async areaServicefun() {

            let gameid = { gameId: this.upPagenum.gameId }
            await areaService(gameid).then((res) => {
               this.treeList=this.getTree1(res[0].children,'label','id','children')
 
            }) 
        },
        // 区服筛选
        async handleChange(value) {
            
            let serviceld = {
                gameId:this.pageSize.gameId,
                serviceld:value[2]
            }
            await product(serviceld).then((res) =>{
                this.sumPage = Math.ceil(res.totalElements /5)*10
                this.thisPagenum = res.content
            })
        },
        async fenPage(page){
            this.currentPage = page - 1
            this.SurePage = page
            // this.pageSize.page = this.currentPage
            // await product(this.pageSize).then((res) =>{
            //     this.sumPage = Math.ceil(res.totalElements /5)*10
            //     this.thisPagenum = res.content
            // })
            this.shaix();
            
        },
        // 价格筛选
        screen(num,priceUp,priceDown){
            this.screennum=num
            this.priceSerchDown = priceUp
            this.priceSerchUp = priceDown
        },
        async shaix(){
            this.pageSize.title = this.souguanjian
            this.searchParams = {
                gameId: Number(localStorage.getItem('TZgameId')),//游戏id
                priceDown: Number(this.priceSerchDown),//价格下限
                priceUp: Number(this.priceSerchUp),//价格上限
                page: this.currentPage,//分页
                size: 5,//每页显示的数目
                title: this.pageSize.title,//商品标题
            }
            if(!this.souguanjian){
                delete this.searchParams.title
            }
            if(!this.priceSerchDown){
                delete this.searchParams.priceDown
            }
            if(!this.priceSerchUp){
                delete this.searchParams.priceUp
            }
            if(this.searchParams.priceUp && this.searchParams.priceDown){
                if(Number(this.searchParams.priceDown)>Number(this.searchParams.priceUp)) return this.$message.error('第一个值不能大于第二个值')
            }
            await product(this.searchParams).then((res) => {
                this.thisPagenum = res.content
                this.thisPagenums = res
                this.sumPage = Math.ceil(res.totalElements /5)*10
            })
        }
    },
    mounted() {
        this.serviceQqfun()
        this.tztx = localStorage.getItem('TZiconPath') //头像
        this.pageSize.title = localStorage.getItem('TZgameName') //标题
        this.pageSize.gameId = Number(localStorage.getItem('TZgameId'))
        this.upPagenum = {gameId:Number(localStorage.getItem('TZgameId')),gameName:localStorage.getItem('TZgameName')}
        
        this.pageSize.title = this.$route.query.types.title

        this.searchParams = {
            gameId: Number(localStorage.getItem('TZgameId')),//游戏id
            priceDown: Number(this.priceSerchDown),//价格下限
            priceUp: Number(this.priceSerchUp),//价格上限
            page: this.currentPage,//分页
            size: 5,//每页显示的数目
            title: this.pageSize.title,//商品标题
            sort: 'createTime,desc'
        }
        this.productfun()
        this.areaServicefun()
    },
    watch: {
        $route: {
            handler() {
                this.screennum = 0;
                this.serviceQqfun()
                this.tztx = localStorage.getItem('TZiconPath') //头像
                this.pageSize.title = localStorage.getItem('TZgameName') //标题
                this.pageSize.gameId = Number(localStorage.getItem('TZgameId'))
                this.upPagenum = {gameId:Number(localStorage.getItem('TZgameId')),gameName:localStorage.getItem('TZgameName')}
                
                this.pageSize.title = this.$route.query.types.title

                this.searchParams = {
                    gameId: Number(localStorage.getItem('TZgameId')),//游戏id
                    priceDown: Number(this.priceSerchDown),//价格下限
                    priceUp: Number(this.priceSerchUp),//价格上限
                    page: this.currentPage,//分页
                    size: 5,//每页显示的数目
                    title: this.pageSize.title,//商品标题
                }
                this.productfun()
                this.areaServicefun()
            //深度监听，同时也可监听到param参数变化
            },
            deep: true,
        }
    },
    data() {
        return {
            searchParams: {
                gameId: '',//游戏id
                priceDown: '',//价格下限
                priceUp: '',//价格上限
                page: 0,//分页
                size: 5,//每页显示的数目
                title: '',//商品标题
            },
            qqNum:'',
            tztx:'',
            souguanjian:'',
            // 上个页面携带的参数
            upPagenum: {},
            // 本页面的所有数据
            thisPagenum: {},
            thisPagenums:{},
            value: [],
            options: [],
            children: '',
            treeList:[],
            sumPage:0,
            currentPage: 0,
            SurePage: 1,
            // 分页查询
            pageSize:{
                // 分页
                gameId:'',
                page:0,
                size:5,
                title:'',
            },
            screennum:0,
            priceSerchDown:'',
            priceSerchUp:'',
            qrDialogVisible: false,
            serviceQrCode: '',
        }
    }
}
</script>

<style scoped>
.updi input{
    text-align: center;
}
.indexmax {
    cursor: pointer;
    display: flex;
    width: 150px;
    height: 44px;
    background-color: #fff !important;
    justify-content: center;
    align-items: center;
}

.lijigoumaiee{
    color: #fff;
    text-decoration: none;
}
.lianxikefuee{
    color: #000;
    text-decoration: none;
}

.indexmax span {
    font-size: 20px;
    color: #333333 !important;
}


.contmax {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
}

.content {
    margin: 0px auto;
    width: 1200px;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    transform: translateX(10px);
}

p {
    font-size: 20px;
}

.instructions {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    height: 382px;
    width: 1077px;
    padding: 10px;
    border: 1px dashed #969798;
}

.instructions img {
    width: 100%;
    height: 100%;
}

.process {
    font-size: 24px;
    font-weight: bold;
}

.fill {
    width: 100%;
    height: 30px;
    background-color: #F6F6F6;
}

.bottom img {
    width: 100%;
}

.navigation {
    position: absolute;
    top: 190px;
    right: 20px;
}

.webthirty {
    position: absolute;
    z-index: 99;
    top: 290px;
    left: 30px;
}

.epicskin {
    position: absolute;
    z-index: 2;
}

.searchgames {
    display: flex;
    flex-direction: column;
}

.gametop {
    display: flex;
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 31px;
}

.portrait {
    width: 100px;
    height: 100px;
}

.portrait img {
    width: 100%;
    height: 100%;
}

.portraitri {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100px;
    margin-left: 10px;
}

.portraitri :nth-child(1) {
    font-size: 30px;
    font-weight: bold;
    color: #505050;

}

.portraitri :nth-child(2) {
    font-size: 16px;
    position: absolute;
    bottom: 0;
    color: #333;
}

.gameprice {
    display: flex;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #D0D0D0;
}

.gameprice span {
    font-size: 20px;
    margin-top: 5px;
}

.gameprice li {
    cursor: pointer;
    list-style: none;
    font-size: 20px;
    padding: 5px 10px;
    background-color: #C9C9C9;
    margin-right: 10px;
    border-radius: 8px;
}

.lifontbackcolor {
    background-color: #0076FF !important;
    color: #ffffff;
}

.gameprice input {
    width: 95px;
    height: 30px;
    border: 0.3px solid #C9C9C9;
    border-radius: 8px;
}

.gameprice select {
    margin-top: 6px;
    width: 180px;
    height: 30px;
    text-align: center;
    border: 0.3px solid #C9C9C9;
    color: #C9C9C9;
}

.pricetw {
    display: flex;
    flex-wrap: wrap;
}

.pricetw span {
    display: block;
    width: 100px;
    height: 100%;
}

.ite {
    height: 100%;
    width: 910px;
    display: flex;
    flex-wrap: wrap;
}

.ite div {
    display: flex;
    flex-direction: column;
    margin-right: 90px;
    margin-bottom: 20px;
    font-size: 20px;
}

.key input {
    border: 1px solid #FFA03B;
    width: 371px;
    height: 40px;
}

.key button {
    cursor: pointer;
    background-color: #EEEEEE;
    width: 90px;
    height: 40px;
    font-size: 20px;
    border: none;
    margin-left: 10px;
    border-radius: 8px;
}

.key button:last-child {
    background-color: #0076FF;
    color: #ffffff;
}

.priceitems {
    position: relative;
    height: 140px;
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.ptempic {
    width: 230px;
    height: 140px;
}

.ptempic img {
    width: 100%;
    height: 100%;
}

.ptfont {
    display: flex;
    flex-direction: column;
    width: 500px;
    transform: translateX(10px);
}

.ptfont span:nth-child(1) {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #333333;
    height: 40px;
    overflow:hidden;
    text-overflow: ellipsis;
     display:-webkit-box;
     -webkit-box-orient:vertical;
     -webkit-line-clamp:2;
}

.ptfont span:nth-child(2) {
    font-size: 12px;
    color: #939393;
    height: 34px;
    overflow:hidden;
    text-overflow: ellipsis;
     display:-webkit-box;
     -webkit-box-orient:vertical;
     -webkit-line-clamp:2;
    margin-top: 5px !important;
}

.ptfont span:nth-child(3) {
    font-size: 12px;
    color: #939393;
    margin-top: 5px;
}

.ptfont span:nth-child(4) {
    font-size: 12px;
    margin-top: 10px;
}

.paging {
    width: 513px;
    height: 28px;
    margin: 30px auto;
    border: unset !important;
}

.monny {
    margin-left: 120px;
    height: 140px;
    line-height: 140px;
    font-size: 25px;
    font-weight: bold;
    color: #FB2706;
}

.shoping {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 140px;
    margin-left: 100px;
}

.shoping button {
    width: 120px;
    height: 40px;
    background-color: #0076FF;
    border-radius: 8px;
    border: none;
    color: #fff;
    margin-bottom: 20px;
}

.shoping button:nth-child(2) {
    border: 1px solid #666666;
    background-color: #fff;
    color: #333;
}
</style>
