<!--
 * @Author: ygq
-->
<!-- web 1920-27 -->
<template>
    <div id="app" @click="zeze">
        <div class="masker" v-if="this.shopnum===1">
            <div class="maskpay">
                <img src="../../assets/chacha.png" class="chacha" @click="shop(0)" alt="">
                <span class="alreadysucc">您的订单已提交成功，请尽快完成支付</span>
                <span class="masktime">{{payTimeDuration}}分钟内未完成付款，订单自动取消</span>
                <img v-if="QrCode" :src="QrCode" alt="">
                <span class="pleace">请使用支付宝扫码支付</span>
                <span class="maskprice">￥{{allnum.price}}</span>
                <div class="orderNumber" v-for="item in ordernumber" :key="item.type">
                    <span class="ordernu">{{item.title}}</span>
                    <span class="ordernumbers">{{item.num}}</span>
                </div>
            </div>
        </div>
        <index-heat></index-heat>
        <web-thirty class="webthirty" v-show="false"></web-thirty>
        <epics-kin class="epicskin" :checkdetail="chi" v-if="guanbi!=0"></epics-kin>
        <div class="h_midmax">
            <div class="h_mid">
            <div class="w_index accountwindex">
                <span @click="index()">网站首页</span>
            </div>
            <div>
                <li @click="all()">全部游戏</li>
                <li @click="bidding()">竞价中心</li>
                    <li @click="account()">我要卖号</li>
                    <li @click="protect()">担保中介</li>
                    <li @click="TradingInformation()">交易信息</li>
                    <li @click="TransactionSecurity()">交易保障</li>
                    <li @click="agree()">关于我们</li>
            </div>
        </div>
        </div>
        <div class="fill"></div>

        <div class="contmax">

            <div class="content accountdetcontent">
                <!-- web 1920-27 -->
                <div class="particulars">
                    <!-- 商品详情 -->
                    <div class="goodsdel">
                        <div class="dialogueFloat">
                            <span>商品详情</span><br>
                            <span>荣耀黄金 大元素使拉克丝 灵魂守卫乌迪尔 未来战士伊泽瑞尔 武装战姬厄运小姐 DJ娑娜 海克斯科技崔丝塔娜 摄魂使者薇恩 十周年纪念安妮 源代码乐芙兰 阿木木：“主人不要我了” 安妮梦游仙境 暗杀星蒙多 暗杀星伊泽瑞尔 北辰之威内瑟斯，荣耀黄金 大元素使拉克丝 灵魂守卫乌迪尔 未来战士伊泽瑞尔 武装战姬厄运小姐 DJ娑娜 海克斯科技崔丝塔娜 摄魂使者薇恩 十周年纪念安妮 源代码乐芙兰 阿木木：“主人不要我了” 安妮梦游仙境 暗杀星蒙多 暗杀星伊泽瑞尔 北辰之威内瑟斯...</span>
                        </div>
                        <div class="maxpto">
                            <div class="leftpho" ref="leftpho">
                                <!-- <img :src="this.hnjt" alt=""> -->
                                <!-- <div class="preview" ref="offsetphoto">
                                    <div class="chooseme" @click="allfun(item.fun,item.src)" v-for="(item,index) in lunbo" :key="index">
                                        <span v-show="choosemenumber!==index+1"></span>
                                        <img :src="item.src" alt="">
                                    </div>
                                </div> -->
                                <el-carousel indicator-position="outside">
                                    <el-carousel-item v-for="(item,index) in bannerArr" :key="index">
                                        <!-- <h3>{{ item }}</h3> -->
                                        <img :src="item" class="elcorImages" alt=""/>
                                    </el-carousel-item>
                                </el-carousel>
                                <div class="baopeima">
                                    <!-- 是否包赔。0：否，1：是 -->
                                    <img v-if="isIndemnity == 1" src="../../assets/kemaibaopei.png" alt="">
                                    <img v-if="isIndemnity == 0" src="../../assets/bukebaopei.png" alt="">
                                </div>
                            </div>
                            <div class="maxfont">
                                <p>{{allnum.title}}</p>
                                <div>
                                    <div class="super">
                                        <span>{{allnum.gameName}}</span>
                                        <span>|</span>
                                        <span>{{allnum.gameService}}</span>
                                    </div>
                                    <div class="honer">{{allnum.description}}</div>
                                    <p class="platform">平台保障</p>
                                    <p class="time">{{allnum.createTime}}</p>
                                    <p class="price">￥{{allnum.price}}</p>
                                </div>
                                <div class="consulting">
                                    <button v-show="flag==0"><a class="lijizixun"
          target="_blank"
          @click="showCustomerServiceQrCode"
          >立即咨询</a></button>
           <!-- @click="shop(1)" -->
                                    <button v-show="flag==0" style="cursor: pointer;"><a class="lijigoumai"
          target="_blank"
          @click="shop(1)"
          >立即购买</a></button>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <!-- 基本信息 -->
                    <div class="basic">
                        <p class="message">基本信息</p>
                        <div class="Serial">
                            <li>
                                <span>账号编号</span><span>{{allnum.productNo}}</span>
                                <button class="tag-read" :data-clipboard-text="allnum.productNo" @click="copy"><img src="../../assets/辅助.png" alt=""></button>
                                <!-- <span class="copy" @click="copy()"><img src="../../assets/辅助.png" alt=""></span> -->
                            </li>
                            <li>
                                <span>所属区服</span><span>{{allnum.gameService}}</span>
                            </li>
                            <li v-for="(item,index) in allnum.gameProperties" :key="index">
                                <span>{{item.propertyName}}</span><span>{{item.propertyValue}}</span>
                            </li>
                            <li>
                                <span>账号描述</span><span class="zhanghaomiaos">{{allnum.description}}</span>
                            </li>
                        </div>
                        <div class="skin">
                            <li v-for="(item,index) in slkn" :key="index">
                                <span>{{item.typeName}}</span>
                                <span>{{item.skinTotal}}</span>
                                <span @click.stop="chakan(index)" class="chakanxiang">查看详情</span>
                            </li>
                            
                        </div>
                    </div>
                    <!-- 稀有内容 -->
                    <p class="message">稀有内容</p>
                    <!-- <p class="sum">共计<span>{{xyou.length}}</span>款</p> -->
                    <div class="rare">
                        <div class="rarelist" v-for="(item,index) in xyou" :key="index">
                            <div>
                            <el-image :src="item.skinPath" fit="contain" ></el-image>

                                <!-- <img :src="item.skinPath" alt=""> -->
                            </div>
                            <span>{{item.skinName}}</span>
                        </div>

                    </div>
                    <!-- 资产截图 -->
                    <p class="message assets">资产截图</p>
                    <div class="screenshots">
                        <div v-for="(item,index) in allnum.accountImagesPath" :key="index">
                            <img :src="item" alt="">
                        </div>
                    </div>
                    <!-- 交易说明 -->
                    <p class="message assets">交易说明 <a style="text-decoration: none;" href="a.docx" target="_blank">《交易须知》</a> </p>

                    <div class="instructions">
                        <!-- <img src="../../assets/图像 37.png" alt=""> -->
                        <!-- <span class="process">交易流程</span> -->
                        <h5>交易流程</h5>
                        <div class="setpDiv">
                            <el-steps :active="4">
                                <el-step title="下单" icon="el-icon-goods"></el-step>
                                <el-step title="发货" icon="el-icon-truck"></el-step>
                                <el-step title="验收" icon="el-icon-search"></el-step>
                                <el-step title="完成" icon="el-icon-document-checked"></el-step>
                                <!-- el-icon-s-claim -->
                            </el-steps>
                        </div>
                        <h5>免责声明</h5>
                        <p>1、请勿私下联系卖家交易，防止被骗。</p>
                        <p>2、所展示的商品供求信息由买卖双方自行提供、其真实性、准确性和合法性由信息发布人负责。</p>
                        <p>3、买家付款后可与客服联系查看账号最新截图，确认信息，商品信息由卖家负责，因网易、腾讯、微信游戏特殊性，商品信息由卖家实时截图提供。</p>
                        <p>4、国家法律规定，未成年人不能参与虚拟物品交易。</p>
                        <p>5、本平台提供的数字化商品更具商品性质故不支持七天无理由退货及三包服务。</p>
                    </div>
                </div>
                
            </div>
            <div class="fill"></div>
            <!-- 底部 -->
            <div class="bottom">
                <index-tail></index-tail>
            </div>
        </div>
        <div class="searches">

        </div>

        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import NaviGation from '../NaviGation.vue'
import EpicsKin from './EpicsKin.vue'
import WebThirty from './WebThirty.vue'
import { detail,serviceQq, orderDetail } from '@/request/api'
import Clipboard from 'clipboard'
import IndexTail from '../IndexTail.vue'
import serverConfig from '../../request/config.js';
import {customerServiceQrCode, configByKeyService} from '@/request/api'

export default {
    name: 'TwentySeven',
    components: {
        NaviGation,
        EpicsKin,
        WebThirty,
        IndexHeat,
        IndexTail
    },
    methods:{
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        async serviceQqfun(){
    //   await serviceQq().then((res) =>{
    //     this.qqNum = res.serviceQq
    //   })
    },
        zeze(){
            this.guanbi=0
        },
        chakan(type){
            this.guanbi=1
            
            this.chi = this.slkn[type]
            console.log(this.chi)
        },
        all(){
            this.$router.push('/GameList')
        },
        account(){
            this.$router.push('/AccountTransactions')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        TradingInformation(){
            this.$router.push('/TradingInformation')
        },
        TransactionSecurity(){
            this.$router.push('/TransactionSecurity')
        },
        agree(){
            this.$router.push('/AgreeOur')
        },
        protect(){
            this.$router.push('/AgencyMortgage')
        },
        index() {
            this.$router.push('/index')
        },
        offsetone(src){
            this.choosemenumber=1
            const offset = this.$refs.offsetphoto.children
            const maxpto = this.$refs.leftpho.children[0]
            maxpto.src=src
            for (let i = 0; i < offset.length; i++) {
                offset[i].style=`transform: translateX(188px);`
            }

        },
        offsettwo(src){
            this.choosemenumber=2
            const offset = this.$refs.offsetphoto.children
            const maxpto = this.$refs.leftpho.children[0]
            maxpto.src=src
            for (let i = 0; i < offset.length; i++) {
                offset[i].style=`transform: translateX(94px);`
            }
        },
        offsetthree(src){
            this.choosemenumber=3
            const offset = this.$refs.offsetphoto.children
            const maxpto = this.$refs.leftpho.children[0]
            maxpto.src=src
            
            for (let i = 0; i < offset.length; i++) {
                offset[i].style=`transform: translateX(0px);`
            }
        },
        offsetfour(src){
            this.choosemenumber=4
            const offset = this.$refs.offsetphoto.children
            const maxpto = this.$refs.leftpho.children[0]
            maxpto.src=src
            
            for (let i = 0; i < offset.length; i++) {
                offset[i].style=`transform: translateX(-94px);`
            }
        },
        offsetfive(src){
            this.choosemenumber=5
            const offset = this.$refs.offsetphoto.children
            const maxpto = this.$refs.leftpho.children[0]
            maxpto.src=src
            for (let i = 0; i < offset.length; i++) {
                offset[i].style=`transform: translateX(-188px);`
            }
        },
        copy(){
            var clipboard = new Clipboard('.tag-read')
        clipboard.on('success', e => {
          this.$message.success('复制成功！')
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', e => {
          // 不支持复制
        this.$message.error('该浏览器不支持自动复制')
          // 释放内存
          clipboard.destroy()
        })
        },
        shop(type){
            if(type==1){
                this.$axios({
                    method: 'post',
                    url: serverConfig.baseURL + '/web/order/buy',
                    data: {
                        id:this.detailsnum,
                        orderType: 1
                    },
                    headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: localStorage.getItem('username'),
                    },
                })
                .then((response) => {
                    let result = response.data;
                    this.QrCode = result.qrCode;
                    this.orderId = result.orderId;
                    let orderInfo = [
                            {title:'订单编号：',num:result.orderNo,type:1},
                            {title:'下单时间：',num:result.date,type:2},
                            {title:'实际付款：',num:result.price,type:3},
                            {title:'手续费用：',num:result.servicePrice,type:4},
                            // {title:'实际付款：',num:'￥2442.00',type:5},
                        ]
                    this.ordernumber = orderInfo;
                    this.shopnum=type
                    setTimeout(()=>{
                        this.loopWaitOrderFinish();
                    },1000)
                })
                .catch((error) => {
                });
            }
            else{
                this.shopnum=type
            }
        },
        async detailfun(){
            let detailnum = {productId:this.detailsnum}
            await detail(detailnum).then((res) =>{
                // console.log(res)
                this.isIndemnity = res.isIndemnity
                this.bannerArr = res.detailImagesPath
                this.slkn = res.typeSkips
                this.xyou = res.rareSkins
                this.allnum = res
                switch (res.accountImagesPath.length) {
                case 1:
                    this.numshow=0
                     this.lunbo=[
                                    {fun:'offsetone',src:''},
                                ]
                    break;
                case 2:
                    this.numshow=1
                    this.lunbo=[
                                    {fun:'offsetone',src:''},
                                    {fun:'offsettwo',src:''},
                                ]
                    break;
                case 3:
                    this.numshow=2
                    this.lunbo=[
                                    {fun:'offsetone',src:''},
                                    {fun:'offsettwo',src:''},
                                    {fun:'offsetthree',src:''},
                                ]
                    break;
                case 4:
                    this.numshow=3
                    this.lunbo=[
                                    {fun:'offsetone',src:''},
                                    {fun:'offsettwo',src:''},
                                    {fun:'offsetthree',src:''},
                                    {fun:'offsetfour',src:''},
                                ]
                    break;        
                default:
                    this.numshow=3
                    this.lunbo=[
                                    {fun:'offsetone',src:''},
                                    {fun:'offsettwo',src:''},
                                    {fun:'offsetthree',src:''},
                                    {fun:'offsetfour',src:''},
                                    {fun:'offsetfive',src:''},
                                ]
                    break;
            }
            this.hnjt = res.accountImagesPath[this.numshow]
                for(let i=0;i<res.accountImagesPath.length;i++){
                    this.lunbo[i].src = res.accountImagesPath[i]
                }
            })            
        },
        loopWaitOrderFinish(){
            if(this.shopnum==0){
                return;
            }
            orderDetail({orderId:this.orderId}).then((res) => {
                console.log(res)
                if(res.orderStauts==1){// 待支付
                    setTimeout(()=>{
                        this.loopWaitOrderFinish();
                    },1000)
                }
                else{
                    this.$message.success('支付完成！');
                    setTimeout(() => {
                        this.shopnum = 0;
                    }, 2000)
                }
            })
        },
        allfun(fun,src){
            this[fun](src)
        },
        indicatorToimage:function(){
            var a = document.querySelectorAll(".el-carousel__button");
            console.log(a);
            for (let i in this.bannerArr){
                //添加一个img
                let img = document.createElement('img');
                img.src=this.bannerArr[i];
                img.class = "bannerClass";
                //加到button里面
                a[i].appendChild(img);
            }
        }
    },
    data(){
        return{
            payTimeDuration: 20,
            qrDialogVisible:false,
            serviceQrCode: '',
            orderId: -1,
            specImages: [
                    //350x350大小的图片
            ],
            smallSpecImages:[
                //50x50大小的图片
            ],
            isIndemnity:1,
            qqNum:'',
            xyou:0,
            guanbi:0,
            QrCode:'',
            choosemenumber:3,
            ordernumber:[
                {title:'订单编号：',num:'',type:1},
                {title:'下单时间：',num:'',type:2},
                {title:'实际付款：',num:'￥',type:3},
                {title:'手续费用：',num:'￥',type:4},
                // {title:'实际付款：',num:'￥2442.00',type:5},
            ],
            shopnum:0,
            flag: 0,
            // 详情页参数
            detailsnum:0,
            // 所有数据
            allnum:'',
            lunbo:[
                {fun:'offsetone',src:''},
                {fun:'offsettwo',src:''},
                {fun:'offsetthree',src:''},
                {fun:'offsetfour',src:''},
                {fun:'offsetfive',src:''},
            ],
            bannerArr: [],
            slkn:'',
            hnjt:'',
            chi:'',
            numshow:0,
            qrDialogVisible: false,
            serviceQrCode: '',
        }
    },

    mounted(){
        configByKeyService({configKey:'ORDER_PAY_MINUTES'}).then((res) =>{
            console.log('ORDER_PAY_MINUTES', res)
            this.payTimeDuration = res.configValue;
        })

        this.detailsnum = Number(this.$route.query.type)
        if(this.$route.query.flag!=undefined){
            this.flag = Number(this.$route.query.flag)
        }
        this.detailfun()
        this.serviceQqfun()

        setTimeout(() => {
            this.$nextTick(()=>this.indicatorToimage());
        }, 500);
    }
}
</script>
<style lang="less">
.el-carousel__container{
    height: 265px!important;
}
.el-carousel__button img{
    width: 30px!important;
    height: 30px!important;
}
</style>
<style lang="less">
.preview-wrap /deep/.el-carousel__button{
    width: 30px!important;
    height: 30px!important;
    margin-right: 20px;
}

.zhanghaomiaos{
    width: 900px;
}
.accountwindex{
    margin-top: 9px !important;
}

.tag-read{
    border: none;
    cursor: pointer;
}

.lijizixun{
    text-decoration: none;
    color: #000000;
}
.lijigoumai{
    text-decoration: none;
    color: #fff;
}
.chakanxiang{
    cursor: pointer;
}
.masker{
    width: 100%;
    height: 110%;
    transform: translateY(-10%);
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9;
    .maskpay{
        position: relative;
        margin: 10% auto;
        width: 750px;
        height: 578px;
        background: #ffffff;
        border: 0.5px solid rgba(112,112,112,0.54);
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16); 
        display: flex;
        flex-direction: column;
        align-items: center;
        .chacha{
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 10px;
            width: 12px;
            height: 12px;
        }
        .alreadysucc{
            font-weight: bold;
            margin: 30px 0;
            font-size: 16px;
        }
        .masktime{
            color: #FF1D1D;
            font-size: 12px;
            margin-bottom: 30px;
        }
        img{
            width: 198px;
            height: 192px;
        }
        .pleace{
            font-size: 12px;
            margin-top: 10px;
        }
        .maskprice{
            color: #FF0000;
            font-size: 20px;
            font-weight: bold;
            margin: 10px 0;
        }
        .orderNumber{
            font-size: 12px;
            width: 200px;
            display: flex;
            justify-content: space-between;
        }
    }
}
.copy{
    cursor: pointer;
}
.chooseme{
    position: relative;
    span{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.7);
    }
}

.h_midmax{
    width: 100%;
    .h_mid{
        width: 1200px;
        margin: 0 auto;
        border-top: none;
        .w_index{
            margin-top: 20px;
            font-size: 20px;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}

.contmax {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
}
.accountdetcontent{
    transform: translateX(10px);
}

.content {
    margin: 0px auto;
    width: 1200px;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.goodsdel {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 445px;
    .dialogueFloat{
        position: absolute;
        flex-direction: column;
        top: 40px;
        left: 60px;
        width: 351px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); 
        border: none;
        text-align: center;
        padding: 20px;
        display: none;
        z-index: 33;
        span:nth-child(1){
            display: block;
            font-size: 16px;
            color: #0076FF;
            text-align: left;
        }
        span:nth-child(2){
            display: block;
            margin-top: 10px;
            font-size: 12px;
            text-align: left;
        }
    }
}

.maxpto {
    display: flex;
}

.leftpho {
    display: flex;
    flex-direction: column;
    width: 470px;
    margin-top: 20px;
    position: relative;
}

// .leftpho img {
//     width: 100%;
//     height: 265px;
// }
.baopeima{
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 30px;
    z-index: 999;

}
.baopeima img{
    width: 80px;
    height: 25px;
}

.maxfont {
    width: 750px;
    height: 315px;
    margin-left: 20px;
    transform: translateY(15px);
}

p {
    font-size: 20px;
}

.super{
    margin: 4px 0;
}

.super span {
    font-size: 16px;
    color: #333333;
    margin-right: 26px;
}

.super span:nth-child(1) {
    color: #0076FF
}

.honer {
    position: relative;
    font-size: 14px;
    color: #919191;
}

.honer:hover .dialogueFloat{
    color: #fc0404;
}

.platform {
    margin: 3px 0;
    width: 90px;
    height: 25px;
    border: 0.3px solid #0076FF;
    border-radius: 4px;
    opacity: 0.54;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #0076FF;
    text-align: center;
    line-height: 25px;
}

.time {
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #B1B2B1;
}

.price {
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    text-align: left;
    color: #fc0404;
}

.consulting button {
    margin-top: 15px;
    width: 84px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px;
    margin-right: 40px;
    border: none;
}

.consulting button:last-child {
    background-color: #0076FF;
    color: #fff;
}

.preview {
    display: flex;
    width: 100%;
    height: 65px;
    margin-top: 15px;
    overflow: hidden;
}

.preview div {
    position: relative;
    width: 100px;
    height: 65px;
    margin-left: 13px;
    transform: translateX(40px);
    transition: all .8s;
}

// .preview div:first-child::before{
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(255, 255, 255, 0.6);
// }

.preview div img {
    width: 100%;
    height: 100%;
}

.preview :first-child {
    margin-left: 0;
}


// .preview::before {
//     content: '';
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: rgba(228, 228, 228, 0.6);
// }

.preview div:nth-child(3)::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: none;
}

.basic {
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 20px;
}

.message {
    border-bottom: 1px solid #D0D0D0;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    margin-top: 10px;
}

.Serial {
    display: flex;
    flex-direction: column;
}

.Serial li,
.skin li {
    margin-top: 10px;
    display: flex;
    list-style: none;
}

.Serial li span:nth-child(1),
.skin li span:nth-child(1) {
    color: #919191;
}

.Serial li span {
    margin-right: 30px;
}

.skin li span:nth-child(2) {
    margin-left: 30px;
    margin-right: 10px;
}

.skin li span:nth-child(3) {
    color: #0076FF;
}

.sum {
    font-size: 14px;
}

.sum span {
    color: #0076FF;
}

.rare {
    display: flex;
    overflow-y: auto;
    ::-webkit-scrollbar {/*滚动条整体样式*/
        width: 10px;     /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }
    ::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: #E5E5E5;
    }
    ::-webkit-scrollbar-track {/*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 10px;
        background: #ffffff;
    }
}

.rarelist {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 20px;
    /* border-bottom: 1px solid #D0D0D0; */
}

.rarelist div {
    width: 120px;
    height: auto;
}

.rarelist div img {
    width: 100%;
    height: 100%;
}

.rarelist span {
    width: 90px;
}

.assets {
    border-top: 1px solid #D0D0D0;
    padding-top: 20px;
}

.screenshots {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #D0D0D0;
}

.screenshots div {
    width: 1045px;
    height: 587px;
    margin-bottom: 20px;
}

.screenshots div img {
    width: 100%;
    height: 100%;
}

.instructions {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    height: 382px;
    width: 1077px;
    padding: 10px;
    // border: 1px dashed #969798;
    .setpDiv{
        width: 50%;
        margin: 20px 0;
    }
    h5{
        font-size: 16px;
        height: 50px;
        line-height: 50px;
    }
    p{
        font-size: 14px;
        line-height: 30px;
    }
}

.instructions img {
    width: 100%;
    height: 100%;
}

.process {
    font-size: 24px;
    font-weight: bold;
}

.fill {
    width: 100%;
    height: 30px;
    background-color: #F6F6F6;
}

.bottom img {
    width: 100%;
}

.navigation {
    position: absolute;
    top: 253px;
    right: -67px;
}

.webthirty {
    position: absolute;
    z-index: 99;
    top: 290px;
    left: 30px;
}

.epicskin {
    position: fixed;
    left: 260px;
    z-index: 2;
}



.ptempic {
    width: 230px;
    height: 140px;
}

.ptempic img {
    width: 100%;
    height: 100%;
}

.ptfont {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.ptfont span:nth-child(1) {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #333333;
}

.ptfont span:nth-child(2) {
    font-size: 12px;
    color: #939393;
    margin-top: 35px;
}

.ptfont span:nth-child(3) {
    font-size: 12px;
    color: #939393;
    margin-top: 10px;
}

.ptfont span:nth-child(4) {
    font-size: 12px;
    margin-top: 10px;
}

.paging {
    width: 513px;
    height: 28px;
    border: 1px solid rebeccapurple;
    margin: 30px auto;
}

.monny {
    margin-left: 120px;
    height: 140px;
    line-height: 140px;
    font-size: 25px;
    font-weight: bold;
    color: #FB2706;
}

.shoping {
    display: flex;
    flex-direction: column;
    height: 140px;
    margin-left: 100px;
}

.shoping button {
    width: 120px;
    height: 40px;
    background-color: #0076FF;
    border-radius: 8px;
    border: none;
    color: #fff;
    margin-bottom: 20px;
}

.shoping button:nth-child(2) {
    border: 1px solid #666666;
    background-color: #fff;
    color: #333;
}
</style>
