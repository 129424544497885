<!--
 * @Author: ygq
-->
<template>
    <div id="app">
        <index-heat class="quanbuyouxi"></index-heat>
        <div class="h_midmaxgame">
            <div class="h_mid">
            <div class="webindex" @click="index()">
                <span>网站首页</span>
            </div>
            <div class="navlist">
                <li  @click="all()">全部游戏</li>
                <li class="agree">竞价中心</li>
                <li @click="account()">我要卖号</li>
                <li @click="protect()">担保中介</li>
                <li @click="TradingInformation()">交易信息</li>
                <li @click="TransactionSecurity()">交易保障</li>
                <li @click="agree()">关于我们</li>
            </div>
        </div>
        </div>
        <div class="maxbox">
            <div class="fill"></div>
            <div class="mixbox" style="margin-top:10px">
                <navi-gation class="gamelistnav"></navi-gation>
                <div class="gamelist">
                    <!-- <div class="gamecat">
                        <img src="../../assets/游戏猫.png" alt="">
                    </div> -->
                    <div>
                        <img src="../../assets/游戏列表.svg" alt="">
                    </div>
                </div>
                <!-- 全部-手游-端游 -->
                <div class="nav">
                    <li @click="jump(item.dictSort)" :class="{fixfonts:item.dictSort===active}" v-for="item in dictDetailCont" :key="item.dictSort">{{item.label}}</li>
                </div>
                <div class="navline" >
                    <li v-show="item.dictSort==active" v-for="item in dictDetailCont" :key="item.dictSort" ></li>
                </div>
                <!-- 游戏列表 -->
                <div class="allgame" v-show="activeType===1">
                    <div class="alonegame" v-for="item in gamelist" :key="item.type" @click="hero(item)">
                        <div class="gamepto">
                            <img :src="item.iconPath" alt="">
                        </div>
                        <span>{{item.gameName}}</span>
                    </div>
                </div>
            </div>
            
            <div class="fill filllll"></div>
        </div>
        <index-tail></index-tail>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import IndexTail from '../IndexTail.vue'
import NaviGation from '../NaviGation.vue'
import {dictDetail,game} from '@/request/api'
import {customerServiceQrCode} from '@/request/api'

export default {
    name: 'Bidding',
    components: {
        IndexHeat,
        IndexTail,
        NaviGation
    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        all(){
            this.$router.push('/GameList')
        },
        agree() {
            this.$router.push('/AgreeOur')
        },
        protect(){
            this.$router.push('/AgencyMortgage')
        },
        TransactionSecurity(){
            this.$router.push('/TransactionSecurity')
        },
        TradingInformation(){
            this.$router.push('/TradingInformation')
        },
        account(){
            this.$router.push('/AccountTransactions')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        index() {
            this.$router.push('/index')
        },
        hero(type){
            let types ={
                gameName:type.gameName,
                iconPath:type.iconPath,
                gameId:type.gameId
            }
            localStorage.setItem('TZgameName',types.gameName)
            localStorage.setItem('TZiconPath',types.iconPath)
            localStorage.setItem('TZgameId',types.gameId)
            this.$router.push({
                path:'/BiddingGoods',
                query:{types}
            })
        },
        // 查询游戏字段
        async dictDetailfun(){
            
            await dictDetail(this.dictDetailnum).then((res) =>{
                this.dictDetailCont = res.content
                this.dictDetailCont.unshift({
                    label:'全部',
                    dictSort:0,
                    value:''
                })
            })
        },
        // 查询游戏
        async jump(type){
            this.active=type
            let gamePlatforms={gamePlatform:Number(this.dictDetailCont[type].value)}
            await game(gamePlatforms.gamePlatform!==0?gamePlatforms:'').then((res) =>{
                // for(let game of res){
                //     game.iconPath = '../../assets/hpjy.webp'
                // }
                console.log('jumps type', res)
                this.gamelist=res
            })
        },
        async jumps(){
            await game().then((res) =>{
                // for(let game of res){
                //     game.iconPath = '../../assets/hpjy.webp'
                // }
                console.log('jumps', res)
                this.gamelist=res
            })
        },
        
    },
    data(){
        return{
            active:0,

            // 查询游戏字段
            dictDetailnum:{
                dictName:'game_platform',
                page:0,
                size:9999,
            },
            // 游戏列表
            gamelist:[],
            dictDetailCont:[],
            listtwos:[
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'英雄联盟',type:1},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'穿越火线',type:2},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'古剑奇谭',type:3},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'逆战',type:4},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'原神',type:5},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'梦幻三国',type:6},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'永劫无间',type:7},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'魔兽世界',type:8},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'星际争霸',type:9},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/6d81800a19d8bc3e770bd00d868ba61ea9d345f2.jpg',title:'天涯明月刀',type:10},
            ],
            listthrees:[
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'英雄联盟',type:1},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'穿越火线',type:2},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'古剑奇谭',type:3},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'逆战',type:4},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'原神',type:5},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'梦幻三国',type:6},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'永劫无间',type:7},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'魔兽世界',type:8},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'星际争霸',type:9},
                {url:'http://h.hiphotos.baidu.com/image/pic/item/902397dda144ad340668b847d4a20cf430ad851e.jpg',title:'天涯明月刀',type:10},
            ],
            listfours:[
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'英雄联盟',type:1},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'穿越火线',type:2},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'古剑奇谭',type:3},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'逆战',type:4},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'原神',type:5},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'梦幻三国',type:6},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'永劫无间',type:7},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'魔兽世界',type:8},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'星际争霸',type:9},
                {url:'http://g.hiphotos.baidu.com/image/pic/item/0d338744ebf81a4c87a3add4d52a6059252da61e.jpg',title:'天涯明月刀',type:10},
            ],
            activeType:1,
            qrDialogVisible: false,
            serviceQrCode: '',
        }
    },
    beforeMount(){
        this.dictDetailfun()
        this.jumps()
    }

}
</script>

<style>
.quanbuyouxi{
    padding-bottom: 10px;
}

.fixfonts{
    color: #000 !important;
    font-weight: bold;
}
.h_midmaxgame{
    width: 100%;
    border-top: 1px solid #707070;
}
.h_mid {
    display: flex;
    flex-direction: row;
    width: 1200px !important;
    margin: 0 auto;
}

.h_mid div {
    /* margin-top: 10px; */
    
}

.webindex {
    display: flex;
    width: 150px;
    height: 44px;
    background-color: #ffffff !important;
    justify-content: center;
    align-items: center;
}

.webindex span {
    font-size: 20px;
    color: #333 !important;
    cursor: pointer;
}

.h_mid div:nth-child(2) {
    display: flex;
    width: auto;
    height: 44px;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.h_mid div:nth-child(2) li {
    list-style: none;
    font-size: 20px;
    color: #333333;
    width: 140px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
}


.maxbox {
    width: 1900px;
    height: auto;
    background-color: #F6F6F6;
}

.fill {
    width: 100%;
    height: 20px;
    
}

.mixbox {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1200px;
    background-color: #fff;
    margin: 0 auto;
    padding-bottom: 120px;
}

.gamelist {
    display: flex;
    height: 58.18px;
    width: 100%;
    margin: 10px 0 0 10px;
}

.gamecat {
    width: 50px !important;
    height: 58px !important;
}

.gamelist div:nth-child(1) img {
    width: 100%;
    height: 100%;
}

.gamelist div:nth-child(2) {
    width: 188px;
    height: 52px;
}

.gamelist div:nth-child(2) img {
    width: 100%;
    height: 100%;
}

.nav {
    display: flex;
    margin: 20px 0 0 10px;
}

.nav li {
    cursor: pointer;
    list-style: none;
    color: #919191;
    font-size: 20px;
    width: 100px;
    height: 30px;
    line-height: 30px;
}



.navline {
    display: flex;
}

.navline li {
    list-style: none;
    width: 50px;
    height: 4px;
    margin-left: 5px;
    border-radius: 20px;
    background-color: #0076FF;
}

.navline li:nth-child(2) {
    margin-left: 105px !important;
}

.navline li:nth-child(3) {
    margin-left: 204px !important;
}

.navline li:nth-child(4) {
    margin-left: 305px !important;
    width: 88px;
}

.allgame {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    transform: translateX(5px);
}

.alonegame {
    width: 180px;
    height: 180px;
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 8px;
    margin-right: 16px;
    margin-top: 10px;
}
.gamepto{
    width: 80px;
    height: 80px;
    margin: 30px auto;
}
.gamepto img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.alonegame span{
    display: block;;
    text-align: center;
    margin: -10px auto;
}
.gamelistnav{
    position: absolute;
    top: 0px;
    right: -200px;
    margin-bottom: 100px;
}
.filllll{
    margin-top: 150px;
}
</style>
