<!--
 * @Author: ygq
-->
<!-- web 1920-29 -->
<template>
    <div id="app">
        <div class="convisition">
            <div class="top">
                <img src="../../assets/弹窗有纹理背景.png" alt="" class="bg">
                <span>答案网游客服-甜甜</span>
                <img src="../../assets/路径 150.png" alt="" class="horn">
                <img src="../../assets/路径 151.png" alt="" class="add">
                <img src="../../assets/路径 152.png" alt="" class="minus">
            </div>
            <div class="button">
                <div class="convirsiton">
                    
                    <div class="ltbox">
                        <!-- 客服 -->
                        <div class="service">
                            <p>
                            <span>答案网游客服-甜甜</span>
                            <span>2022-6-7 15：03</span>
                        </p>
                        <div>
                            您好，请问需要购买哪个账号呢？
                        </div>
                        </div>
                        <!-- 客户 -->
                        <div class="customer">
                            <p>2022-6-7 15：03</p>
                            <div class="data">
                                <div class="left">
                                    <img src="../../assets/_1(Y5BZO__46XIZF(_)J9AG.png" alt="">
                                </div>
                                <div class="right">
                                    <span>购买[LG65221]</span>
                                    <span>艾欧尼亚</span>
                                    <span>查看详情</span>
                                </div>
                            </div>
                        </div>
                        <div class="service">
                            <p>
                            <span>答案网游客服-甜甜</span>
                            <span>2022-6-7 15：03</span>
                        </p>
                        <div>
                            您好，请问需要购买哪个账号呢？
                        </div>
                        </div>
                        <div class="service">
                            <p>
                            <span>答案网游客服-甜甜</span>
                            <span>2022-6-7 15：03</span>
                        </p>
                        <div>
                            您好，请问需要购买哪个账号呢？
                        </div>
                        </div>
                        <div class="service">
                            <p>
                            <span>答案网游客服-甜甜</span>
                            <span>2022-6-7 15：03</span>
                        </p>
                        <div>
                            您好，请问需要购买哪个账号呢？
                        </div>
                        </div>
                        
                    </div>
                    <div class="import">
                        <textarea cols="30" rows="10" placeholder="请输入内容"></textarea>
                        <button>发送</button>
                    </div>
                </div>
                <div class="convenient">
                    <div class="entrance">
                        <span>快捷入口</span>
                        <span>常见问题</span>
                        <span>客服名片</span>
                    </div>
                    <div class="conpic">
                        <!-- 快捷入口 -->
                        <!-- <img src="../../assets/图像 39.png" alt="" class="picone"> -->
                        <!-- 常见问题 -->
                        <span class="line">
                            <span>客服在线时间及联系方式</span>
                        </span>
                        <span class="line">
                            <span>客服在线时间及联系方式</span>
                        </span>
                        <!-- 客服名片-->
                        <!-- <img src="../../assets/gmlogo.png" alt="" class="pictwo"> -->
                    </div>
                    <div class="communication">
                        <p>答案网游交流群</p>
                        <li>英雄联盟交流群：718445693</li>
                        <li>英雄联盟交流群：718445693</li>
                        <li>英雄联盟交流群：718445693</li>
                        <li>英雄联盟交流群：718445693</li>
                        <li>英雄联盟交流群：718445693</li>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: 'WebThirty',
  components: {
    
  }
}
</script>
<style scoped>

    .convisition{
        width: 620px;
        height: 517px;
        background: #ffffff;
        border: 0.3px solid #707070;
        border-radius: 8px;
        box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.16);
    }
    .top{
        position: relative;
        width: 620px;
        height: 60px;
        background: rgba(0,0,0,0.00);
        border-radius: 8px 8px 0px 0px;
    }
    .bg{
        width: 100%;
        height: 100%;
    }
    .top span{
        position: absolute;
        color: #FFFEFE;
        font-size: 14px;
        display: block;
        top: 20px;
        left: 20px;
    }
    .horn{
        position: absolute;
        top: 20px;
        left: 520px;
    }
    .add{
        position: absolute;
        top: 25px;
        left: 552px;
    }
    .minus{
        position: absolute;
        top: 20px;
        left: 582px;
    }
    .button{
        display: flex;
    }
    .convirsiton{
        display: flex;
        flex-direction: column;
        width: 435px;
        height: 457px;
        background-color: #ffffff;
    }
    .ltbox{
        width: 435px;
        height: 367px;
        overflow-y: auto; 
        scrollbar-width: none;
        margin-bottom: 20px;
    }
    .ltbox::-webkit-scrollbar{
        display: none;
    }
    .import{
        position: relative;
        width: 435px;
        height: 90px;
        border: 1px solid #E6E6E6;
        background-color: #FFFFFF;
        border-radius: 0px 0px 0px 8px;    
    }
    .import textarea{
        width: 100%;
        height: 47px;
        border: none;
        outline: none;
        resize: none;
        overflow-y: hidden;
    }
    .import button{
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 43px;
        height: 25px;
        background: #0076FF;
        border-radius: 4px;
        border: none;
        color: #FFFEFE;
    }
    .convenient{
        display: flex;
        flex-direction: column;
        width: 185px;
        height: 457px;
        border: 1px solid #C0C0C0;
    }
    .entrance{
        display: flex;
        width: 100%;
        height: 54px;
    }
    .entrance span{
        display: block;
        color: #919191;
        font-size: 12px;
        line-height: 30px;
        margin-left: 10px;
    }
    .conpic {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        width: 100%;
        height: 275px;
        overflow: hidden;
    }
    .line{
        display: block;
        width: 100%;
        height: 20px;
        border-bottom: 1px solid #D0D0D0;
    }
    .line span{
        display: block;
        margin-left: 20px;
        color: #6E6E6E;
        font-size: 10px;
    }
    .picone{
        width: 100%;
    }
    .pictwo{
        width: 139px;
        height: 138px;
    }
    .communication{
        z-index: 1;
        overflow: hidden;
        width: 185px;
        height: 127px;
        background: #0076FF;
        border-radius: 0px 0px 8px 0px;
    }
    .communication p{
        font-size: 12px;
        color: #FFFEFE;
        margin-left: 10px;
    }
    .communication li{
        margin-left: 10px;
        list-style: none;
        color: #FFFEFE;
        font-size: 10px;
        margin-bottom: 14px;
    }
    .service{
        transform: translateY(20px);
    }
    .service p span{
        font-size: 10px;
        color: #B2B2B2;
        margin-left: 20px;
    }
    .service div{
        width: 239px;
        background: #ffffff;
        border: 0.3px solid #707070;
        border-radius: 4px;
        box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.16); 
        text-align: left;
        padding-left: 20px;
        margin-left: 20px;
        font-size: 14px;
    }
    .customer{
        position: relative;
    }
    .customer p{
        position: absolute;
        right: 10px;
        font-size: 10px;
        color: #B2B2B2;
    }
    .data{
        display: flex;
        position: relative;
        margin: 20px 0px 0px 190px;
        right: 10px;
        top: 30px;
        width: 239px;
        height: 86px;
        background: #ffffff;
        border: 0.3px solid #707070;
        border-radius: 4px;
        box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.16); 
    }
    .left{
        width: 70px;
        height: 70px;
        margin: 8px 10px;
    }
    .left img{
        width: 100%;
        height: 100%;
    }
    .right{
        display: flex;
        flex-direction: column;
    }
    .right span:nth-child(1){
        color: #333;
        font-size: 14px;
        margin: 8px 0 10px 0;
    }
    .right span:nth-child(2){
        color: #959595;
        font-size: 12px;
    }
    .right span:nth-child(3){
        color: #0076FF;
        font-size: 12px;
        margin: 9px 0 0 96px;
    }
    
</style>