import { render, staticRenderFns } from "./EpicsKin.vue?vue&type=template&id=a39a9a56&scoped=true&"
import script from "./EpicsKin.vue?vue&type=script&lang=js&"
export * from "./EpicsKin.vue?vue&type=script&lang=js&"
import style0 from "./EpicsKin.vue?vue&type=style&index=0&id=a39a9a56&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a39a9a56",
  null
  
)

export default component.exports