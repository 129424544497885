<!--
 * @Author: ygq
-->
<template>
    <div id="app">
        <!-- 中介担保 -->

        <div class="maxbox">
            <index-heat class="indexheat dingbu"></index-heat>
            <div class="h_midmaxaccount">
                <div class="h_mid">
                <div class="webindex webindexagency" @click="index()">
                    <span>网站首页</span>
                </div>
                <div class="navlist">
                    <li @click="all()">全部游戏</li>
                    <li @click="bidding()">竞价中心</li>
                    <li @click="account()">我要卖号</li>
                    <li class="agree">担保中介</li>
                    <li @click="TradingInformation()">交易信息</li>
                    <li @click="TransactionSecurity()">交易保障</li>
                    <li @click="agree()">关于我们</li>
                </div>
            </div>
            </div>
            <div class="company" style="margin-top:-10px">
                <i class="fill"></i>
                <div class="minbox">
                    <div class="agreeour">
                        <div class="our">
                            <div class="agreew">
                                <i>
                                    <img src="../../assets/路径 59.png" alt="">
                                </i>
                                <span><router-link to="index" class="agreeme">中介担保</router-link></span>
                            </div>
                            <div class="companylist">
                                <div class="fixback">
                                    <span class="fixfont pingtaidanbao">平台担保</span>
                                    <i>
                                        <img src="../../assets/路径 652.png" alt="">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="introduce">
                        <navi-gation class="agencynav"></navi-gation>
                        <div class="logofon">
                            <!-- <div class="logimgo">
                                <img src="../../assets/猫咪logo4.png" alt="">
                            </div> -->
                            <!-- 平台担保 -->
                            <div class="logimgt">
                                <p style="font-weight:bolder;color:#444">平台担保</p>
                                <!--<img src="../../assets/平台担保.png" alt="">-->
                            </div>
                        </div>
                        <!-- 平台担保 -->
                        <div class="AboutUs">
                            <div v-html="AgencyMortgage"></div>
                            <!-- <img src="../../assets/图像 18.png" alt=""> -->
                        </div>
                    </div>
                </div>
            </div>
            
            <index-tail></index-tail>
        </div>
        <el-dialog :visible.sync="qrDialogVisible" title="扫码添加客服" width="240px" @close="qrDialogVisible = false">
            <img v-if="serviceQrCode" :src="serviceQrCode" alt="" width='200' height='200'>
        </el-dialog>
    </div>
</template>

<script>
import IndexHeat from '../IndexHeat.vue'
import IndexTail from '../IndexTail.vue'
import NaviGation from '../NaviGation.vue'
import {AgencyMortgage} from '@/request/api'
import {customerServiceQrCode} from '@/request/api'

export default {
    name: 'AgreeOur',
    components: {
    IndexHeat,
    IndexTail,
    NaviGation
    },
    data(){
        return{
            AgencyMortgage:``,
      qrDialogVisible: false,
      serviceQrCode: '',
        }
    },
    methods: {
        showCustomerServiceQrCode(){
            customerServiceQrCode().then((res) =>{
                this.qrDialogVisible = true;
                console.log('customerServiceQrCode')
                let n = res.length;
                let x = Math.floor(Math.random()*n);
                if(x>=n){ x=n-1;}
                this.serviceQrCode = res[x].url;
            })
        },
        all(){
            this.$router.push('/GameList')
        },
        agree(){
            this.$router.push('/AgreeOur')
        },
        TransactionSecurity(){
            this.$router.push('/TransactionSecurity')
        },
        TradingInformation(){
            this.$router.push('/TradingInformation')
        },
        account(){
            this.$router.push('/AccountTransactions')
        },
        bidding() {
            this.$router.push('/Bidding')
        },
        index() {
            this.$router.push('/index')
        },
        async AgencyMortgagefun(){
            await AgencyMortgage().then((res)=>{
                this.AgencyMortgage = res.contentText
            })
        }
    },
    mounted(){
        this.AgencyMortgagefun()
    }

}
</script>

<style lang="less">
.indexheat{
    background-color: #fff;
    padding-bottom: 10px;
}
.dingbu{
    padding-bottom: 20px !important;
}
.maxbox{
    .h_midmaxaccount{
        background-color: #fff;
        transform: translateY(-10px);
            border-top: 1px solid #707070;
        .h_mid{
            width: 1200px;
            border-top: none;
            margin: 0 auto;
            .webindex{
                span{
                    width: 110px;
                }
            }
        }
    }
}

.agree {
    background-color: #0076FF;
    color: #fff !important;
    border-radius: 8px;
}
.company{
    width: 100% !important;
    height: auto;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    padding-bottom: 250px;
}
.minbox{
    width: 1200px;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
}
.fill{
    width: 100vw;
    height: 20px;
    background-color: #f6f6f6;
}
.agreeour{
    width: 290px;
    height: 329px;
}
.introduce{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 900px;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 10px;
}
.our{
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
}
.agreew{
    display: flex;
    width: 290px;
    height: 70px;
    background: url('../../assets/有纹理的背景4.svg') no-repeat;
    background-size: 100% 100%;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}
.agreew i{
    width: 21.79px;
    height: 21.81px;
    transform: translateX(-10px);
    // margin-right: 10px;
}
.pingtaidanbao{
    transform: translateX(10px);
}
.agreew i img{
    width: 100%;
    height: 100%;
}
.agreeme{
    font-size: 20px;
    font-weight: bold;
    color: #FBF6F6;
    text-decoration: none;
}
.companylist{
    display: flex;
    flex-direction: column;
}
.companylist div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 185px;
    height: 50px;
    padding-left: 105px;
}
.companylist div i{
    margin-right: 12px;
}
.companylist div:nth-child(1){
    margin-top: 16px;
    color: #333;
}

.companylist div span{
    font-size: 16px;
    color: #333;
}

.logofon{
    display: flex;
    margin: 10px 0 0 20px;
}
.logimgo{
    width: 50px;
    height: 60px;
}
.logimgt{
    width: 348px;
    height: 52px;
}
.logimgo img,.logimgt img{
    width: 100%;
    height: 100%;
}
.AboutUs{
    position: relative;
    margin: 10px 0 0 20px;
}
.agencynav{
    position: absolute;
    top: 0px;
    right: -200px !important;
}
.abs{
    position: absolute;
    top: 39px;
    left: 214px;
}
.abss{
    position: absolute;
    bottom: 380px;
    left: 80px;
}
.webindexagency{
    transform: translateX(15px);
}
</style>
